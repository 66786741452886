import React, { Component } from 'react';
import Footer from '../components/Footer.jsx';
import config from '../config';
import Navigation from '../components/Navigation.jsx';
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {storage} from "../firebase/firebase";
import LoadingOverlay from 'react-loading-overlay';


class Representations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant_name: '',
      age: '',
      pkid: '',
      email: '',
      mobile_number: '',
      gender:'',
      country: '',
      nearest_major_city: '',
      height: '',
      bust: '',
      waist: '',
      hips: '',
      shoes: '',
      hair_colour: '',
      eye_colour: '',
      comments: '',
      attachment: [],
      attachmentfile: [],
      formsubmit:'',
      submitLabel: 'Submit Details',
      isActive:false
    };
    this.handleInput = this.handleInput.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  handleInput = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
  };

  _uploadFiles = async (acceptedFile) => {
    this.setState({
      ...this.state,
      isActive:true
    })
    const storageRef = ref(storage, acceptedFile.name);
    uploadBytes(storageRef, acceptedFile).then((snapshot) => {
      getDownloadURL(storageRef).then((url) => {
        let item = {
          route: url
        };
        this.state.attachmentfile.push(item);
        this.setState({
          ...this.state,
          isActive:false
        })
      });
    });
  };

  onFileChange = async (event) => {
    console.log("yes")
    let files = event.target.files;
    let _accum = 0;

    for (let i = 0; i < files.length; i++) {
      let _t = files[i].size;
      if (_t !== NaN) _accum += _t;
    }

    _accum /= 1000000;

    let tag = document.getElementById('submit_button');
    if (_accum < 21) {

      tag.removeAttribute('style');
      this.setState({
        ...this.state,
        submitLabel: 'Submit Details',
        attachment: event.target.files,
      });
      var attach=[];

      for (const element of files) {

       await this._uploadFiles(element)

      }

    } else {
      tag.setAttribute('style', 'pointer-events: none; background: #B22222;');
      this.setState({
        ...this.state,
        submitLabel: 'Please upload less than 20mb.',
      });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const commaSep = this.state.attachmentfile.map(item => item.route).join(', ');
         let insta=this.state.pkid;
         const testOne=this.state.pkid.substring(0,4);
         const testTwo=this.state.pkid.substring(0,5);
         const test3=this.state.pkid.substring(0,1)
         const test4=this.state.pkid.substring(0,3)
         console.log(testOne,testTwo,"https",test3)
         if(testTwo==="https")
         {
         }
         else if(testOne==="http")
         {
         }
         else if(test4==="www")
         {
             insta="https://"+this.state.pkid;
         }
         else if(test3==="@")
         {
             let temp=this.state.pkid.substring(1,this.state.pkid.length)
             insta="http://www.instagram.com/"+temp;
         }
         else{
          insta="https://www.instagram.com/"+this.state.pkid
         }
    let formdata = {
      applicant_name: this.state.applicant_name,
      age: this.state.age,
      pkid: insta,
      email :this.state.email,
      mobile_number: this.state.mobile_number,
      gender: this.state.gender,
      country: this.state.country,
      nearest_major_city: this.state.nearest_major_city,
      height: this.state.height,
      bust: this.state.bust,
      waist: this.state.waist,
      hips: this.state.hips,
      shoes: this.state.shoes,
      hair_colour: this.state.hair_colour,
      eye_colour: this.state.eye_colour,
      comments: this.state.comments,
      type:'representations',
      attachments:commaSep
    }
    console.log(formdata)
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post(`${config.URL}sendmail`, formdata, {
      headers,
    }).then(function (response) {


      })

      document.getElementById('attachment').value = null;
      this.setState({formsubmit:'Form Submited Successfully.'});

      this.setState({
        applicant_name: '',
        age: '',
        pkid: '',
        email: '',
        mobile_number: '',
        country: '',
        nearest_major_city: '',
        height: '',
        bust: '',
        waist: '',
        hips: '',
        shoes: '',
        hair_colour: '',
        eye_colour: '',
        comments: '',
        attachment: [],
      })

  };

  render() {
    console.log(this.state.isActive)
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
      >
      <div>
        <Navigation />
        <section className="pt-7">
          <div
            className="w-full container mx-auto px-10 sm:px-6 md:px-32"
            style={{ 'min-height': '341px' }}
          >
            <div className="representation_form">
              <div
                className="representation_form_inner aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h1 className="form_heading font-Forum">Submit your Details</h1>
                <form
                  enctype="multipart/form-data"
                  onSubmit={this.submitHandler}
                >
                  <input name="utf8" type="hidden" value="✓" />
                  <input
                    className="representations-input-line"
                    type="hidden"
                    name="authenticity_token"
                    value="MtjUymVHKU1pyGXyMejNYz8YUkG6KxG6lYE7BLEwHE1wRaMA9Gc2ToroozprnaZJWNLtxhE2LFm/gNJn8VNwnw=="
                  />
                  <input
                    type="text"
                    name="applicant_name"
                    id="_new_contactname"
                    placeholder="NAME*"
                    required="required"
                    value={this.state.applicant_name}
                    onChange={this.handleInput}
                  />
                  <input
                    type="number"
                    name="age"
                    id="_new_contactage"
                    placeholder="AGE*"
                    required="required"
                    value={this.state.age}
                    onChange={this.handleInput}
                  />
                  <input
                    type="text"
                    name="pkid"
                    id="_new_contactinstagram_id"
                    placeholder="INSTAGRAM ID*"
                    required="required"
                    value={this.state.pkid}
                    onChange={this.handleInput}
                  />
                  <input
                    type="email"
                    name="email"
                    id="_new_contactemail"
                    placeholder="EMAIL*"
                    required="required"
                    value={this.state.email}
                    onChange={this.handleInput}
                  />
                  <input
                    type="tel"
                    name="mobile_number"
                    id="_new_contactmobile_number"
                    placeholder="MOBILE NUMBER*"
                    required="required"
                    value={this.state.mobile_number}
                    onChange={this.handleInput}
                  />
                  <select name="gender" id="_new_contactgendor"
                    value={this.state.gender}
                    onChange={this.handleInput}>
                    <option>PRONOUN</option>
                    <option value="F">She</option>
                    <option value="M">He</option>
                    <option value="NB">They</option>
                  </select>
                  <input
                    type="text"
                    name="country"
                    id="_new_contactcountry"
                    placeholder="COUNTRY*"
                    required="required"
                    value={this.state.country}
                    onChange={this.handleInput}
                  />
                  <input
                    type="text"
                    name="nearest_major_city"
                    id="_new_contactnearest_major_city"
                    placeholder="NEAREST MAJOR CITY"
                    value={this.state.nearest_major_city}
                    onChange={this.handleInput}
                  />
                  <input
                    type="text"
                    name="height"
                    id="_new_contactheight"
                    placeholder="HEIGHT*"
                    required="required"
                    value={this.state.height}
                    onChange={this.handleInput}
                  />
                  <input
                    type="text"
                    name="bust"
                    id="_new_contactbust"
                    placeholder="BUST / CHEST"
                    value={this.state.bust}
                    onChange={this.handleInput}
                  />
                  <input
                    type="text"
                    name="waist"
                    id="_new_contactwaist"
                    placeholder="WAIST"
                    value={this.state.waist}
                    onChange={this.handleInput}
                  />
                  <input
                    type="text"
                    name="hips"
                    id="_new_contacthips"
                    placeholder="HIPS"
                    value={this.state.hips}
                    onChange={this.handleInput}
                  />
                  <input
                    type="text"
                    name="shoes"
                    id="_new_contactshoes"
                    placeholder="SHOES"
                    value={this.state.shoes}
                    onChange={this.handleInput}
                  />
                  <input
                    type="text"
                    name="hair_colour"
                    id="_new_contacthair_colour"
                    placeholder="HAIR*"
                    required="required"
                    value={this.state.hair_colour}
                    onChange={this.handleInput}
                  />
                  <input
                    type="text"
                    name="eye_colour"
                    id="_new_contacteye_colour"
                    placeholder="EYE COLOUR*"
                    required="required"
                    value={this.state.eye_colour}
                    onChange={this.handleInput}
                  />
                  <input
                    name="comments"
                    id="_new_contactcomments"
                    placeholder="COMMENTS"
                    value={this.state.comments}
                    onChange={this.handleInput}
                  ></input>
                  <div className="file_upload fileupload-css-ak">
                    <font
                      className="attachment-limit-label"
                      style={{ fontSize: '11px' }}
                    >
                      LIMIT 20MB
                    </font>
                    <input
                      type="file"
                      name="attachment"
                      id="attachment"
                      required="required"
                      multiple
                      onChange={this.onFileChange}
                    />
                  </div>
                  <div className="re_submit">
                    <input
                      type="submit"
                      name="commit"
                      value={this.state.submitLabel}
                      id="submit_button"
                      data-disable-with="Submit Details"
                    />
                  </div>
                </form>
                <p>{this.state.formsubmit}</p>
              </div>

            </div>
          </div>
        </section>
        {/* <OfficeButtons /> */}
        <Footer />
      </div>
      </LoadingOverlay>
    );
  }
}

export default Representations;
