import React, { useState } from "react";
import { toast } from "react-toastify";
import { FaPlus, FaTrash } from "react-icons/fa";
import axios from "axios";
import config from "../../config";
import reactImageSize from "react-image-size";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import "../../css/Timeline.css"


export default function Timeline({ work, deleteTalent }) {
  const [files, setFiles] = useState([]);
  const [creditsList, setCreditsList] = useState([{ title: "", text: "" }]);
  const [timelineTypeVid, setTimelineTypeVid] = useState(false);
  const [custom, setCustom] = useState([
    {
      value:false
    }
  ]);
  const [vidCover, setCover] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [filesUrl, setFilesUrl] = useState([]);
  const [talentName, setTalentName] = useState(work?.talentName);
  const [clientName, setClientName] = useState(work?.clientName);
  const [order,setorder]=useState(work?.order)
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [profession,setProfession]=useState(null)

  React.useEffect(() => {
    if (work !== null) {
      setTalentName(work?.talentName);
      setClientName(work?.clientName);
      setCreditsList(work?.credits);
      setorder(work?.order)
      setFilesUrl([]);
      let cust=[]
      work?.credits?.map(cred=>{
         cust.push({
          value:true
         })
      })
      setCustom(cust)
    }
  }, [work]);

  const handleMediaTypeChange = (e) => {
    if (e.target.name === "videos") {
      setTimelineTypeVid(true);
      setFiles([]);
    } else {
      setTimelineTypeVid(false);
      setFiles([]);
    }
  };
  const [test,settest]=useState(0)
  const handleCustom=(e,name,index)=>{
    e.preventDefault()
     if(name==="custom")
     {
      let cus=custom;
      cus[index]["value"]=true
      setCustom(cus)
      settest(test+1)
     }
     else{
      let cus=custom;
      cus[index]["value"]=false
      setCustom(cus)
      settest(test+1)
     }
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    var list = [...creditsList];
    list[index][name] = value;
    setCreditsList(list);
  };


  // handle input Add
  const handleInputAdd = () => {
    if (creditsList.length < 10) {
      setCreditsList([...creditsList, { title: "", text: "" }]);
      setCustom([...custom,{value:false}])
    }
  };

  // handle Input Remove
  const handleInputRemove = (index) => {
    creditsList.splice(index, 1);
    setCreditsList([...creditsList]);
    custom.splice(index,1);
    setCustom([...custom])
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    if (timelineTypeVid) {
      if (file.size > 26214400) {
        toast.error("Max File Size 25MB Allowed!");
      } else {
        let list = [];
        list.push(file);
        setFiles([...list]);
      }
    } else {
      if (file.size > 7340032) {
        toast.error("Max File Size 7MB Allowed!");
      } else {
        setFiles([]);
        setFiles([...files, ...e.target.files]);
      }
    }
  };

  const handleCoverChange = (e) => {
    let file = e.target.files[0];
    if (file.size > 7340032) {
      toast.error("Max File Size 7MB Allowed!");
    } else {
      setCover(file);
    }
  };

  const handleFileRemove = (i) => {
    files.splice(i, 1);
    setFiles([...files]);
  };

  const uploadFiles = async (filesList) => {
    await filesList.map(async (list) => {
      let fileType = list.fileType;

      await list.file.map(async (index) => {
        let file = index;
        let type = file.type;
        let name = file.name;
        let filename =
          name.replace(/\.[^/.]+$/, "") +
          "-" +
          Math.floor(Math.random() * 10000000 + 1);
        let imgheight = "";
        let imgwidth = "";
        try {
          const storageRef = ref(storage, filename);
          let resp = [];
          await uploadBytes(storageRef, file).then((snapshot) => {});
          await getDownloadURL(storageRef).then((url) => {
            resp.push(url);
          });

          let item;
          if (file.type != "video/mp4") {
            const { width, height } = await reactImageSize(resp[0]);
            imgheight = height;
            imgwidth = width;

            item = {
              route: resp[0],
              type: type,
              height: imgheight,
              width: imgwidth,
              fileType: fileType,
            };
          } else {
            item = {
              route: resp[0],
              type: type,
              fileType: fileType,
            };
          }

          filesUrl.push(item);
          setFilesUrl([...filesUrl]);
        } catch (exception) {
          setUploading(false);
          toast.error(exception);
        }
      });
    });
  };

  const uploadTalent = () => {
    let token = localStorage.getItem("token");
    if (filesUrl.length > 0) {
      let data = {
        talent_name: talentName,
        client_name: clientName,
        credits: creditsList,
        resources: filesUrl,
        component: "timeline",
        order:order
      };

      axios
        .post(`${config.URL}work`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success("Uploaded Successfully!");
          window.location.reload(true);
        })
        .catch((error) => {
          setUploading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  // Update talent
  const updateTalent = (e) => {
    e.preventDefault();
    setUploading(true);

    let token = localStorage.getItem("token");
    let data = {
      talent_name: talentName,
      client_name: clientName,
      credits: creditsList,
      id_work: work?.id,
      order:order,
    };
    axios
      .post(`${config.URL}work/update`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast.success("Updated Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        // toast.success(error);
        setUploading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      files.length &&
      vidCover &&
      creditsList.length &&
      talentName &&
      clientName
    ) {
      setUploading(true);
      let filesList = [
        { fileType: "cover", file: [vidCover] },
        { fileType: "gallery", file: [...files] },
      ];
      setTotalFiles(files.length + 1);
      uploadFiles(filesList);
    } else {
      setUploading(false);
      toast.error("All Fields Required!");
    }
  };

  React.useEffect(() => {
    if (files.length) {
      if (filesUrl.length === totalFiles) {
        uploadTalent();
      }
    }
  }, [filesUrl]);
  console.log(custom)
  return (
    <div>
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 50,
        }}
        value={talentName}
        type="text"
        name="talentName"
        placeholder="Talent Name*"
        onChange={(e) => setTalentName(e.target.value)}
      />
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 50,
        }}
        value={clientName}
        type="text"
        name="clientName"
        placeholder="Client Name*"
        onChange={(e) => setClientName(e.target.value)}
      />
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 50,
        }}
        value={order}
        type="text"
        name="order"
        placeholder="Order*"
        onChange={(e) => setorder(e.target.value)}
      />
      <label style={{ marginTop: 10, marginLeft: 5, color: "#a7a7a7" }}>
        CREDITS*
      </label>
       <>
        {creditsList.map((item, index) => (
          <>
        <div style={{display:"flex", justifyContent:"center", alignItems:"centre",columnGap:"20px",padding:"10px"}}>
              <span onClick={(e)=>handleCustom(e,"custom",index)} className={custom[index]["value"]===false?"custoo":"custo"}>Custom</span>
              <span onClick={(e)=>handleCustom(e,"pre",index)} className={custom[index]["value"]===false?"pree":"pre"}>Predefined</span>
      </div>
        <div className="row">
          {custom[index]["value"]===false ? <div className="col-sm-4">
            <select style={{width:"130px", height:"30px", border:"none", marginTop:"15px"}} name="title" onChange={(event) => handleInputChange(event, index)}
             value={item.title}>
              <option value="photographer">Photographer</option>
              <option value="styling">Styling</option>
              <option value="makeup">Makeup</option>
              <option value="hair">Hair</option>
              <option value="castingdir">Casting Dir</option>
              <option value="creativedir">Creative Dir</option>
              <option value="editor">Editor</option>
              <option value="videography">Videography</option>
              <option value="setdesign">Set Design</option>
              <option value="production">Production</option>
              <option value="creativeasst">Creative Asst</option>
              <option value="makeupasst">Makeup Asst</option>
              <option value="hairasst">Hair Asst</option>
              <option value="photoasst">Photo Asst</option>
              <option value="stylingasst">Styling Asst</option>
              <option value="artdir">Art Dir</option>
              <option value="lightingasst">Lighting Asst</option>
              <option value="digitalasst">Digital Asst</option>
              <option value="nails">Nails</option>
              <option value="direction">Direction</option>
              <option value="postprod">Post Production</option>
              <option value="retouch">Retouch</option>
            </select>
          </div>:<div className="col-sm-4">
             <input
              className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
              style={{
                fontSize: 11,
                borderColor: "rgba(167, 167, 167, 0.63)",
                marginTop: 10,
              }}
              type="text"
              name="title"
              placeholder="Photographer"
              onChange={(event) => handleInputChange(event, index)}
              value={item.title}
            />
            </div>}
          <div className="col-sm-6">
            <input
              className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
              style={{
                fontSize: 11,
                borderColor: "rgba(167, 167, 167, 0.63)",
                marginTop: 10,
              }}
              type="text"
              name="text"
              placeholder="Zoë Ghertner"
              onChange={(event) => handleInputChange(event, index)}
              value={item.text}
            />
          </div>
          <div
            className="col-sm-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {creditsList.length !== 1 && (
              <div
                style={{
                  fontSize: 17,
                  marginTop: 12,
                  color: "#a7a7a7",
                  cursor: "pointer",
                  display: "inline-block",
                }}
                onClick={() => handleInputRemove(index)}
              >
                <FaTrash />
              </div>
            )}
            {creditsList.length !== 10 && creditsList.length - 1 === index && (
              <div
                style={{
                  fontSize: 17,
                  marginTop: 12,
                  color: "#a7a7a7",
                  cursor: "pointer",
                  display: "inline-block",
                }}
                onClick={handleInputAdd}
              >
                <FaPlus />
              </div>
            )}
          </div>
        </div>
        </>
      ))}
        </>
      {work === null && (
        <>
          <div>
            <label
              style={{
                marginTop: 10,
                marginLeft: 5,
                marginRight: 5,
                color: "#a7a7a7",
              }}
            >
              SELECT TIMELINE TYPE*
            </label>
            <input
              type="radio"
              name="images"
              id="images"
              value={timelineTypeVid}
              checked={!timelineTypeVid}
              onChange={handleMediaTypeChange}
              style={{
                display: "inline",
                padding: 0,
                margin: 0,
                width: 25,
              }}
            />
            <label className="images">Images</label>

            <input
              type="radio"
              name="videos"
              value={timelineTypeVid}
              checked={timelineTypeVid}
              onChange={handleMediaTypeChange}
              style={{
                display: "inline",
                padding: 0,
                margin: 0,
                width: 25,
              }}
            />
            <label className="videos">Video</label>
          </div>

          <div className="row" style={{ padding: "0 15px" }}>
            <label
              style={{
                marginTop: 10,
                marginLeft: 5,
                color: "#a7a7a7",
              }}
            >
              COVER*
            </label>
            <div
              style={{
                padding: "4px 2px",
                background: "#eaecef",
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleCoverChange}
                className="w-full placeholder-gray-700 helvetica-neue-thin"
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 12,
                  textTransform: "lowercase",
                  width: "100%",
                }}
              />
            </div>
            <div
              style={{
                textAlign: "right",
                color: "#a7a7a7",
                fontSize: 12,
                marginTop: 5,
                fontStyle: "italic",
              }}
            >
              Max File Size Limit 7MB*
            </div>
          </div>
          {timelineTypeVid ? (
            <div className="row" style={{ padding: "0 15px" }}>
              <label
                style={{
                  marginTop: 10,
                  marginLeft: 5,
                  color: "#a7a7a7",
                }}
              >
                VIDEO*
              </label>
              <div
                style={{
                  padding: "4px 2px",
                  background: "#eaecef",
                }}
              >
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleFileChange}
                  className="w-full placeholder-gray-700 helvetica-neue-thin"
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: 12,
                    textTransform: "lowercase",
                    width: "100%",
                  }}
                />
              </div>
              <div
                style={{
                  textAlign: "right",
                  color: "#a7a7a7",
                  fontSize: 12,
                  marginTop: 5,
                  fontStyle: "italic",
                }}
              >
                Max File Size Limit 25MB*
              </div>
            </div>
          ) : (
            <>
              <div className="row" style={{ padding: "0 15px" }}>
                <label
                  style={{
                    marginTop: 10,
                    marginLeft: 5,
                    color: "#a7a7a7",
                  }}
                >
                  IMAGES*
                </label>
                <div
                  style={{
                    padding: "4px 2px",
                    background: "#eaecef",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    className="w-full placeholder-gray-700 helvetica-neue-thin"
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 12,
                      textTransform: "lowercase",
                      width: "100%",
                    }}
                  />
                </div>
                <div
                  style={{
                    textAlign: "right",
                    color: "#a7a7a7",
                    fontSize: 12,
                    marginTop: 5,
                    fontStyle: "italic",
                  }}
                >
                  Max File Size Limit 7MB*
                </div>
              </div>
              <div className="pb-4 border-gray-100 border-opacity-25 bg-transparent">
                <ul className="list-group mt-2">
                  {files.length > 0 &&
                    files.map((acceptedFile, i) => (
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="list-group-item list-group-item-success"
                      >
                        <span>{acceptedFile.name}</span>
                        <span onClick={() => handleFileRemove(i)}>
                          <FaTrash style={{ cursor: "pointer" }} />
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            </>
          )}
        </>
      )}
      <button
        // href='#submit-confirm'
        // data-dismiss='modal'
        // data-toggle='modal'
        disabled={uploading}
        onClick={work === null ? handleSubmit : updateTalent}
        style={{ fontSize: "1em", height: 40 }}
      >
        {uploading
          ? `Work Uploading Please wait.... ${uploadingProgress}%`
          : "Submit"}
      </button>
      {work !== null && (
        <button
          // data-dismiss="modal"
          onClick={(e) => {
            e.preventDefault();
            deleteTalent(work?.id);
          }}
          style={{ fontSize: "1em", height: 40 }}
        >
          Delete
        </button>
      )}
      <button data-dismiss="modal" style={{ fontSize: "1em", height: 40 }}>
        Close
      </button>
    </div>
  );
}
