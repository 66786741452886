
import styled from 'styled-components';
  
// export const Heading = styled.h1`
//    text-align: right;
//    color: green;
// `;
  
// export const Content = styled.div`
//    overflowY: scroll;
//    height: 2500px;
// `;
  
export const Button = styled.div`
   position: fixed; 
   right:20px;
   width: 25px;
   bottom: 10px;
   height: 10px;
   font-size: 1rem;
   z-index: 1;
   cursor: pointer;
`