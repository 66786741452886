import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Timeline from './timeline';
import Config from '../../config';
import Carousel from './carousel';

export default function UploadWork({ item }) {
  const [type, setType] = useState('');
  const [selectedMediaType, setMediaType] = useState('');

  const handleMediaTypeChange = (e) => {
    if (e.target.value === 'timeline') {
      setMediaType('TIMELINE MEDIA');
      setType('timeline');
    } else if (e.target.value === 'carousel') {
      setMediaType('CAROUSEL MEDIA');
      setType('carousel');
    } else {
      setMediaType('');
      setType('');
    }
  };

  // Delete talent
  const deleteTalent = (id) => {
    let token = localStorage.getItem('token');
    let data = {
      id_work: id,
    };
    axios
      .delete(`${Config.URL}work`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      })
      .then(() => {
        toast.success('Deleted Successfully!');
        window.location.reload(true);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div id='add-work' className='modal fade' role='dialog' data-backdrop='static'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-body'>
            <form action='' style={{ textAlign: 'left' }}>
              {item === null && (
                <select
                  name='mediaType'
                  id='mediaType'
                  className='w-full uppercase bg-transparent border-opacity-25'
                  style={{
                    border: '1px solid rgba(167, 167, 167, 0.63)',
                    fontSize: 12,
                    color: '#a7a7a7',
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                  value={selectedMediaType}
                  onChange={handleMediaTypeChange}
                >
                  <option
                    value='...'
                    className='uppercase'
                    style={{ fontSize: 12, color: '#a7a7a7' }}
                  >
                    Select Media Type*
                  </option>
                  <option
                    value='timeline'
                    style={{ fontSize: 12, color: '#a7a7a7' }}
                  >
                    TIMELINE MEDIA
                  </option>
                  <option
                    value='carousel'
                    style={{ fontSize: 12, color: '#a7a7a7' }}
                  >
                    CAROUSEL MEDIA
                  </option>
                </select>
              )}
              <h2 style={{ textTransform: 'uppercase', textAlign: 'center' }}>
                {item ? item?.component : selectedMediaType}
              </h2>
              {(type === 'timeline' || item?.component === 'timeline') && (
                <Timeline work={item} deleteTalent={deleteTalent} />
              )}
              {(type === 'carousel' || item?.component === 'carousel') && (
                <Carousel work={item} deleteTalent={deleteTalent} />
              )}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
