import React, { Component } from 'react';
import SystemAgencyLogo from '../assets/system-agency-logo.png';
import { Link } from 'react-router-dom';
import ScrollButton from './ScrollButton';
// import { Content, Heading } from './Styles';


class Navigation extends Component {
  

  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
  }
  onToggleNav = () => {
    if (this.state.hidden) {
      this.setState({ hidden: false });
    } else {
      this.setState({ hidden: true });
    }
  };
  hideNavClass = () => {
    if (this.state.hidden) {
      return 'hidden';
    } else {
      return '';
    }
  };



  render() {
  
    return (
      <>
      {/* <a href="#root" id='topbutton' className='fixed text-black' style={{ bottom: '10px', right:'10px', zIndex:'9999' }}>TOP</a> */}
      <ScrollButton />
        <nav className="w-full mx-auto fixed header-fixed">
          <div
            className="image  py-4"
            onMouseOver={this.onToggleNav}
            onMouseOut={this.onToggleNav}
          >
            <Link to="/home">
              <div className="bg-white" style={{ marginTop: '-10px' }}>
                <img
                  className="pt-10 pb-6 cursor-pointer mx-auto"
                  src={SystemAgencyLogo}
                  alt=""
                  width="170px"
                />
              </div>
            </Link>

            <div
              style={{
                backgroundColor: 'rgba(255,255,255, 0.5)',
                padding: '0.5px',
              }}
              className={`bg-white nav-custom hover:bg-opacity-50 helvetica-neue-thin`}
            >
              <ul className="flex justify-center">
                <li className="mr-1">
                  <span className="n-glitch-start">
                    <Link
                      to="/about"
                      className="text-black uppercase ml-2 text-lg italic transition duration-500"
                    >
                      About
                    </Link>
                  </span>
                </li>
                <li className="mr-1">
                  <Link
                    to="/work"
                    className="text-black uppercase ml-2 text-lg italic transition duration-500"
                  >
                    Work
                  </Link>
                </li>
                <li className="ml-1">
                  <a
                    href="/talents"
                    className="text-black uppercase ml-2 text-lg italic transition duration-500"
                  >
                    Talent
                  </a>
                </li>
                <li className="ml-1">
                  <span className="n-glitch-end">
                    <Link
                      to="/representations"
                      className="text-black uppercase ml-2 text-lg italic transition duration-500"
                    >
                      Representation
                    </Link>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Navigation;
