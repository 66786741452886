import React, { useState, useEffect,Suspense,useRef } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import Navigation from '../components/Navigation.jsx';
import config from '../config';
import Carousel from '../components/Carousel';
import '../css/Home.css';
import { Waypoint } from 'react-waypoint';
import UploadFeature from '../components/uploadFeatures';
import SuccessPopup from '../components/successPopup';
import Loader from '../components/Loader';
import { MdEdit } from 'react-icons/md';
import { useBottomScrollListener,BottomScrollListener } from 'react-bottom-scroll-listener';


const Images=React.lazy(()=>import("../components/HomepageImages"))

function Home() {
  const [loader, setLoader] = useState(true);
  const [timelineWork, setTimelineMain] = useState([]);
  const [landingVideo, setLandingVideo] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  // const [carouselList, setCarousels] = useState([]);
  const [item, setItem] = useState(null);
  const [addItem, setAddItem] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);
  const [end,setEnd]=useState(10)
  const callback=()=>{
    setEnd(end+20);
  }
  const scrollRef = useBottomScrollListener(callback);
  //video controls
  let [shouldPlay, updatePlayState] = useState(true);
  let [shouldPlay1, updatePlayState1] = useState(true);
  //first video functions
  let handleEnterViewport = function () {
    updatePlayState(true);
  };
 let handleExitViewport = function () {
    updatePlayState(false);
  }
  //second video functions
  let handleEnterViewport1 = function () {
    updatePlayState1(true);
  };
  let handleExitViewport1 = function () {
    updatePlayState1(false);
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAdmin(true);
    }
  }, []);

  const Loading=()=>{
    return(
      <div>
        loading
      </div>
    )
  }

  useEffect(() => {
    axios.get(`${config.URL}home`).then((response) => {
      let images = response.data.item.rows;
      let filterimages = [];
      setLandingVideo(filterLandingVideo(images));
        images.map((image) => {
          if (image['component'] === 'landing_video') {
            setLandingVideo(image['resources']['route']);
          }else{
            filterimages.push(image);
          }
        });

      let filteredTimeLine = filterTimelineMain(filterimages);

      filterimages.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      let countimg = 0;
      filterimages.map((image) => {
        if(image.sub_component==='portait'){
          image.align =  (countimg % 2 === 0) ? "right" : "left";
          countimg++;
        }else{
          image.align="center";
        }
      });
      // console.log(filterimages);
      setTimelineMain(filterimages);
      setLoader(false);
    });
  }, []);

  function filterTimelineMain(rows) {
    const timelineWork = [];
    rows.forEach((row) => {
      if (row['component'] === 'timeline') {
        timelineWork.push({
          resources:
            row['sub_component'] === 'carousel'
              ? [...row['resources']]
              : {
                  route: row['resources']['route'],
                  type: row.resources.type,
                  height: row['resources']['height'],
                  width: row['resources']['width'],
                },
          talent_name: row['talent_name'],
          client_name: row['client_name'],
          caption: row['caption'],
          hyperlink: row['hyperlink'],
          createdAt: row['createdAt'],
          sub_component: row['sub_component'],
          id_home: row['id_home'],
          credits: row['credits'],
          talent_hyperlink: row['talent_hyperlink'],
        });
      }

    });
    return timelineWork;
  }

  function filterLandingVideo(rows) {
    var video = '';
    rows.forEach((row) => {
      if (row['component'] === 'landing_video') {
        video = row['resources']['route'];
      }
    });
    return video;
  }

  const logout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    window.location = '/home?logged_out';
  };
  const _editTimeline = (image) => {
    setItem(image);
    setAddItem(false);
  };


  if (loader) {
    return <Loader />;
  }

  return (
    <div>
      <Navigation />
      <Waypoint  onEnter={handleEnterViewport} onLeave={handleExitViewport}>
        <div className="player-wrapper homeplayer">
          <ReactPlayer
            playing={shouldPlay}
            className="react-player"
            loop={true}
            muted={true}
            controls
            width="100%"
            height="100%"
            url={landingVideo}
            playsinline={true}
          />
        </div>
      </Waypoint>
      <BottomScrollListener onBottom={callback}>
      <div className="container-fuild pb-36" style={{padding:'0 15px'}} ref={scrollRef} >
        {isAdmin && (
          <div style={{ position: 'absolute', left: '2%', zIndex: 1000 }}>
            <div
              className="new-left-panel"
              style={{ position: 'sticky', width: '100%', minWidth: 110 }}
            >
              <button
                style={{ paddingRight: 5 }}
                className="view-package"
                href="#upload-feature"
                data-toggle="modal"
                onClick={() => {
                  setItem(null);
                  setItemIndex(null);
                  setAddItem(false);
                }}
              >
                UPLOAD FEATURE
              </button>
              <button
                style={{ paddingRight: 5 }}
                className="view-package"
                onClick={logout}
              >
                LOG OUT
              </button>
            </div>
          </div>
        )}
        <div className="row mt-20 homerow">
          {timelineWork.slice(0,end).map((image, index) =>
            image.sub_component === 'carousel' ? (
               <Carousel
                  items={image}
                  key={index}
                  parent="home"
                  setItem={setItem}
                  addItem={setAddItem}
                  setIndex={setItemIndex}
                />
            ) : image.resources.type?.split('/')[0] === 'image' ? (
              <Suspense fallback={Loader}>
                    <Images image={image} isAdmin={isAdmin} setItem={setItem}
                    setAddItem={setAddItem}></Images>
              </Suspense>
            ) : (
              <div className="col-sm-8 col-md-8 col-md-offset-2 col-sm-offset-2 mt-5 centercontainer">
                <div className="text-center imgtext">
                  <p className="helvetica-neue font-bold italic mb-0">
                    {isAdmin && (
                      <MdEdit
                        style={{
                          display: 'inline-block',
                          fontSize: 12,
                          marginBottom: 6,
                          marginRight: 5,
                          cursor: 'pointer',
                          outline: 'none',
                        }}
                        href="#upload-feature"
                        data-toggle="modal"
                        onClick={() => _editTimeline(image)}
                      />
                    )}
                    {image.talent_name}
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="helvetica-neue-light italic"
                    href={`https://${image.background_reference}`}
                  >
                    <small className="text-black hover:font-semibold">
                      {image.client_name}
                    </small>
                  </a>
                </div>
                <Waypoint
                  onEnter={handleEnterViewport1}
                  onLeave={handleExitViewport1}
                >
                  <div>
                    <ReactPlayer
                      className="mt-0 pt-0"
                      playing={shouldPlay1}
                      loop={true}
                      muted={true}
                      width="100%"
                      height="auto"
                      controls
                      url={image.resources.route}
                      playsinline={true}
                    />
                  </div>
                </Waypoint>
                <div className="helvetica-neue-light text-center italic text-lg">
                  {image.caption}
                </div>
              </div>
            )
          )}
        </div>
        <div className="h-32"></div>
      </div>
      </BottomScrollListener>
      <UploadFeature
        item={item}
        addItem={addItem}
        setItem={setItem}
        setAddItem={setAddItem}
        itemIndex={itemIndex}
      />
      <SuccessPopup message={'FEATURE ADDED SUCCESSFULLY'} />
    </div>
  );
}

export default Home;
