// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import Navigation from '../components/Navigation.jsx';
// import Footer from '../components/Footer.jsx';
// import axios from 'axios';
// import config from '../config';
// import { ExpansionPanelActions } from '@material-ui/core';
// import Loader from '../components/Loader';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation.jsx';
import Footer from '../components/Footer.jsx';
import axios from 'axios';
import config from '../config';
// import { toast } from 'react-toastify';
import Loader from '../components/Loader';

class ViewPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      hidden: true,
      activeIndex: '',
      selectedImages: [],
      email: '',
      name: '',
      message: '',
      submit: '',
      loading: '',
      open: false,
    };
    this.loadimages = this.loadimages.bind(this);
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };



  componentDidMount() {
    document.title = 'System Agency - View Package';
    const {
      match: { params },
    } = this.props;

    let images = params.selectedImages.split(',');

    images.map((data, i) => {
      this.state.selectedImages.push(data);
    });
    this.loadimages();
  }

  loadimages = () => {
    axios.get(`${config.URL}talent/section`).then((response) => {
      let images = response.data.item.rows;

      let data = images.filter((image, i) => {

        image.is_requested = false;
        return this.state.selectedImages.includes(image['slug']);
      });


      //save images variable in state
      this.setState({
        images: data,
      });
    });
  };

  formsubmit = () => {
    this.setState({
      loading: true,
    });
    const axios = require('axios');
    // const formData = new FormData();
    const vm = this;

    // formData.append('email', this.state.email);


    let data = this.state.images;
    let rowContents = '<h2>Models Selected:</h2>';
    const contents = data.reduce((acc, image, i) => {

      rowContents +='<p>'+image.name+' '+image.last_name+'</p>';
    }, []);
    var sendmesage='<p><b>Name: </b>'+this.state.name+' </p><p><b>Email: </b>'+this.state.email+'</p><p><b>Message: </b>'+this.state.message+'</p>'+rowContents;
    // formData.append('message',sendmesage);
    const formData={
      type:'package',
      message:sendmesage
    };

    const headers = {
      'Content-Type': 'application/json',
    };

    axios.post(`${config.URL}sendmail`, formData, {
      headers,
    }).then(function (response) {
     if(response.data.status=="Success"){


     }
    });
  };


  removeImage = (index) => {
    let data = this.state.images;
    let newData = data.filter((item, i) => {
      return i !== index;
    });
    this.setState({
      images: newData,
    });
  };


  deletselectAll = () => {
    let images = this.state.images;
    images.map((image) => {
      image.is_requested = false;
    });
    this.setState({
      images,
    });
  };

  // https://stackoverflow.com/questions/42391499/react-render-new-row-every-4th-column
  render() {
    if (this.state.loader) {
      return <Loader />;
    }
    const { url } = this.props.match;
    // const Images = this.state.images;
    const items = this.state.images;
    let rowContents = [];
    const contents = items.reduce((acc, image, i) => {
      let cover = image?.resources?.filter((item) => item.section === 'cover');

      rowContents.push(
        <div key={i} className="col-xs-6 col-sm-4 col-lg-3 mb-6">
          <div className='boxwrap'>
            <div className="image_text">
              <div style={{ zIndex: 1 }} className="talents-close">
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.removeImage(i)}
                  className="material-icons md-2"
                >
                  close
                </i>
              </div>
              {image.is_requested ? (
                <div style={{ zIndex: 1 }} className="talents-close">
                  <i
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      items[i].is_requested = false;
                      const selectedImages = this.state.selectedImages;
                      const newSelectedImages = this.removeItemAll(
                        selectedImages,
                        i
                      );
                      this.setState({
                        ...this.state,
                        image: items,
                        selectedImages: newSelectedImages,
                      });
                    }}
                    className="material-icons md-1"
                  >
                    {' '}
                    clear
                  </i>
                </div>
              ) : (
                <></>
              )}

              <div className="img-box">
                <Link to={`/talents/${image.slug}`}  className={image.is_requested ? 'image_text_faded' : ''}>
                  {/* <div className={image.is_requested ? 'image_text_faded' : ''}>
                    <div id="image-mask"> */}
                      <img
                        key={image.slug}
                        src={cover && `${cover[0]?.route}`}
                        alt={image.name}
                      />
                    {/* </div>
                  </div> */}
                </Link>
              </div>

              {/* {this.state.isAdmin ? (
                <div className="top-right top-right-fix">
                  <button className="share">
                    <Link to={`/editTalent/${image.slug}`}>Edit</Link>
                  </button>
                  <button onClick={() => this.deleteTalent(i)}>
                    <Link to="#">Delete</Link>
                  </button>
                </div>
              ) : image.is_requested ? (
                <div className="top-right top-right-fix">
                  <h4 className="">ADDED</h4>
                </div>
              ) : (
                <div className="top-right top-right-fix">
                  <button
                    data-target="#share"
                    data-toggle="modal"
                    className="share"
                  >
                    <Link to="#">Share</Link>
                  </button>
                  <button onClick={() => this.requestImage(i)}>
                    <Link to="#">ADD TO PACKAGE</Link>
                  </button>
                </div>
              )} */}
            </div>
          <p className="style_heading talent-heading">
            <Link
              to={`${url}/${image.slug}`}
              style={{ color: 'black' }}
              className="dodit-medium"
            >
              {' '}
              <strong className="dodit-bold">{image.name}</strong>{' '}
              {image.last_name}
            </Link>
          </p>
        </div>
                </div>
      );
      if (i % 4 === 3) {
        acc.push(<div className="row newgrid">{rowContents}</div>);
        rowContents = [];
      }

      return acc;
    }, []);

    contents.push(rowContents);

    return (
      <div>
        <Navigation />
        <div className="viewpackage">

          {
            //Side bar :: for admin - Logout & Add Talent :: for user - View package & Deselect all
          }
          {this.state.isAdmin ? (
            <div>
              <div className="new-left-panel">
                <button className="view-package" onClick={this.logout}>
                  LOG OUT
                </button>
                <button
                  className="view-package"
                  onClick={() => this.openTab(`/addTalent`)}
                >
                  ADD TALENT
                </button>
              </div>
              <div className="talent-content">
                <div
                  className="container pad"
                  style={{ width: '80%', marginTop: '60px' }}>
                  {contents}
                </div>
              </div>
            </div>
          ) : (
          <div>
            <div className="new-left-panel saveandrequest">
              <button
                data-target="#request"
                data-toggle="modal"
                className="view-packages"
              >
                SAVE AND REQUEST
              </button>
            </div>
            <div className="talent-content">
              <div
                className="container pad"
                style={{ width: '80%', marginTop: '60px' }}
              >
                <div className="row  newgrid">{contents}</div>
              </div>
            </div>
          </div>
          )}
        </div>
        <Footer />
        <div id="request" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <h2 className="helvetica-neue-regular">Request Package</h2>
                <form enctype="multipart/form-data">
                  <input
                    type="text"
                    name="name"
                    required="required"
                    className="input-line  boxfont"
                    placeholder="Name(s)*"
                    value={this.state.name}
                    onChange={this.handleInput}
                    style={{ fontSize: 15 }}
                  />
                  <input
                    type="email"
                    name="email"
                    required="required"
                    value={this.state.email}
                    onChange={this.handleInput}
                    className="input-line  boxfont"
                    placeholder="Email(s)*"
                    style={{ fontSize: 15 }}
                  />
                  <textarea
                    className="input-line textarea-input-line  boxfont"
                    name="message"
                    required="required"
                    value={this.state.message}
                    onChange={this.handleInput}
                    placeholder="Comments*"
                    style={{ fontSize: 15 }}
                  />
                  <button
                    onClick={this.formsubmit}
                    value={this.state.submit}
                    data-target="#request-success"
                    data-dismiss="modal"
                    data-toggle="modal"
                    style={{ fontSize: '1em', height: 40 }}
                  > {this.state.loading ? 'Submited' : 'Request Package'}

                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>

        <div id="request-success" className="modal fade" role="dialog" open={this.state.open} value={this.state.value} onClose={this.onClose}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <h2>PACKAGE REQUESTED</h2>
                {/* <form action="">
                                    <input type="text" placeholder="NAME*" />
                                    <input type="text" placeholder="EMAIL(S)*" />
                                    <textarea placeholder="COMMENTS*"></textarea>
                                    <button>Share</button>
                                </form> */}
                <form>
                  <p className="success-message">
                    Your request has been well-received.
                    <br />
                    One of our agents will be in touch with you directly via the
                    email address provided.
                    <br />
                    <br />
                    Due to the volume of requests received, please allow upto 48
                    hours for correspondence.
                    <br />
                    <br />
                    All the best, from the team at SYSTEM Agency. <br />
                  </p>
                  <button
                    onClick={() => {
                      window.close();
                    }}
                    style={{
                      fontFamily:
                        'helvetica-neue, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;',
                      fontSize: '1em',
                      height: 40,
                    }}
                  >
                    Close
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewPackage;
