import React, { useState, useEffect, memo, useRef } from "react";
import Slider from "react-slick";
// import { useSwipeable } from 'react-swipeable';
// import '../css/slider.css';
import { NextArrow, PrevArrow } from "../components/sliderCustomArrow";
import Loader from "../components/Loader";
import Image from "../components/Image";
import ReactPlayer from "react-player/lazy";

// import useWindowSize from "../components/useWindowSize";

// var final = [];
const ImageSlider = ({ images, open, large, pair, active = 0, event }) => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [activeIndex, setActiveIndex] = useState(active);

  const sliderRef = useRef();
  useEffect(() => {
    setActiveIndex(0);
    setLoader(true);
    _loadSrc();
  }, [images]);

  useEffect(() => {
    if (event === "ArrowRight") {
      sliderRef.current.slickNext();
    } else if (event === "ArrowLeft") {
      sliderRef.current.slickPrev();
    }
  }, [event]);
  const checkOrientation = async (src) => {
    var orientation;

    if (src.width > src.height) {
      orientation = "landscape";
    } else if (src.width < src.height) {
      orientation = "portrait";
    } else {
      orientation = "landscape";
    }

    return orientation;
  };
  const _loadSrc = async () => {
    let newData = [];
    for (let img of images) {
      let res = null;
      if (!pair) {
        let orientation = await checkOrientation(img);
        res = {
          url: img.route,
          orientation: orientation,
          section: img.section,
        };
      } else {
        res = {
          url: img.route,
          orientation: "portrait",
          section: img.section,
        };
      }
      newData.push(res);
    }
    setData(newData);

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  const updateIndex = (newIndex) => {
    console.log(activeIndex + 1);
    setActiveIndex(newIndex + 1);
  };
  const _renderSlider = () => {
    let tamp = [];
    let j = 0;
    for (let i = 0; i < data.length; i++) {
      let ext = data[i].url;
      ext = ext.split(".").pop();

      let d = "";
      if (ext === "mp4") {
        d = (
          <div className="img-container">
            <div className="slider-item" key={i}>
              <ReactPlayer controls url={data[i].url} />
            </div>
          </div>
        );
      } else if (!pair && i === 0 && data[i].section === "portfolio") {
        // } else if(!pair && i === 0) {
          if(window?.innerWidth<768)
          {
            d = (
              <div className="img-container">
                <div className="slider-item first-items single2" key={i}>
                  <Image
                    url={data[i].url}
                    index={j}
                    alt={data[i].name}
                    onClick={open}
                    landscape={data[i].orientation}
                    small={true}
                  />
                </div>
              </div>
            );
          }
          else{
            d = (
              <div className="img-container">
                <div className="slider-item first-items single2 another" key={i}>
                  <Image
                    url={data[i].url}
                    index={j}
                    alt={data[i].name}
                    onClick={open}
                    landscape={data[i].orientation}
                    small={true}
                  />
                </div>
              </div>
            );
          }

      } else if (
        data[i].orientation !== "landscape" &&
        data[i + 1]?.orientation !== undefined &&
        data[i + 1]?.orientation !== "landscape"
      ) {
        if(window.innerWidth<768)
        {
          d = (
            <div className="img-container">
              <div className="slider-item single2" key={i}>
                <Image
                  url={data[i].url}
                  index={j}
                  alt={data[i].name}
                  onClick={open}
                  landscape={data[i].orientation}
                  small={true}
                />
              </div>
            </div>
          );
        }
        else{
          d = (
            <div className="img-container">
              <div
                className="slider-item pair"
                style={{ display: "flex" }}
                key={i}
              >
                <Image
                  url={data[i].url}
                  index={j}
                  alt={data[i].name}
                  onClick={open}
                  landscape={data[i].orientation}
                />
                <Image
                  url={data[i + 1].url}
                  index={j}
                  alt={data[i + 1].name}
                  onClick={open}
                  landscape={data[i].orientation}
                />
              </div>
            </div>
          );
          i += 1;
        }
      } else {
        if (window.innerWidth<768)
        {
          d = (
            <div className="img-container">
              <div className="slider-item single2" key={i}>
                <Image
                  url={data[i].url}
                  index={j}
                  alt={data[i].name}
                  onClick={open}
                  landscape={data[i].orientation}
                  small={true}
                />
              </div>
            </div>
          );
        }
        else{
          d = (
            <div className="img-container">
              <div className="slider-item single2" key={i}>
                <Image
                  url={data[i].url}
                  index={j}
                  alt={data[i].name}
                  onClick={open}
                  landscape={data[i].orientation}
                  small={true}
                />
              </div>
            </div>
          );
        }
      }
      j += 1;
      tamp.push(d);
    }
    return tamp.map((item) => item);
  };
  console.log("yes");
  var counter = 0;
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    className: "slides",
  };

  return (
    <div id="slider-container">
      {loader ? (
        <div
          style={{
            margin: "0px auto",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {data.length > 0 && (
            <>
              <Slider {...settings} ref={sliderRef}>
                {_renderSlider()}
              </Slider>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default memo(ImageSlider);
