// let config = { URL: 'https://systemsagency-api.herokuapp.com/' };
let config = {
  URL: "https://app1-wdkhklvgeq-uc.a.run.app/",
  FormUrl: "https://mailer.artisticbird.com/",
  siteurl: "https://mailer.artisticbird.com/",
};
export default config;

// "https://systemsagency-api.herokuapp.com/"
// https://app-wdkhklvgeq-uc.a.run.app/
