import React from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
export function PrevArrow({ onClick }) {
  return (
    <button onClick={onClick} className="slider-btn nextStyle" id="nextStyle">
      <BsChevronRight color="#eee" size={40} />
    </button>
  );
}

export function NextArrow({ onClick }) {
  // console.log(onClick);
  return (
    <button onClick={onClick} className="slider-btn preStyle" id="preStyle">
      <BsChevronLeft color="#eee" size={40} />
    </button>
  );
}
