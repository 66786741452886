import React, { Component } from "react";
import { useState } from "react";
import { useEffect } from "react";
import "../css/office.scss"
import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';

function CardOffice() {
    const [isAdmin,setIsAdmin]=useState(false)
    const [offices,setOffices]=useState();
    const [isActive, setIsActive] = useState(false);
    useEffect(()=>{
      if (localStorage.getItem('token')) {
        setIsAdmin(true);
      }
    },[])
    const token = localStorage.getItem('token');

    const getoffice=()=>{
      const endPoint="office"
      axios.get(`${config.URL}${endPoint}`).then(res=>{
              setOffices(res?.data?.item?.rows)
              setIsActive(false)
      }).catch(err=>{
       if (err?.response?.data?.message) {
         toast.error(err?.response?.data?.message);
         return;
       }
       toast.error(err.response);
      })
    }
    useEffect(()=>{
      getoffice()
    },[])

    const handleRemove=(id)=>{
       setIsActive(true)
          const endPoint="office/delete"
          const data={
            id:id
          }
          axios.post(`${config.URL}${endPoint}`,data).then(res=>{
                        getoffice()
          }).catch(err=>{
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
              return;
            }
            toast.error(err.response);
          })
    }
    return (
      <LoadingOverlay
        active={isActive}
        spinner
      >
      <div className="card-office">
        <div className="mx-auto grid sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 sm:gap-6 md:gap-4">
         {offices?.map((office,index)=>{
           return(
            <div className="pb-10 leading-snug card-office-box" style={{display:"flex"}}>
            {isAdmin && <div className="adminSectionOffice" style={{marginLeft:"-73px",marginRight:"20px",color:"gray",fontSize:"12px"}}>
                 <div style={{display:"flex",flexDirection:"column"}}>
                 <a href="/addoffice">
                 <span style={{cursor:"pointer"}}>Add New</span>
                 </a>
                 <a href={`/addoffice/${office?.id_office}`}>
                 <span style={{cursor:"pointer"}}>Edit</span>
                 </a>
                 <span style={{cursor:"pointer"}} onClick={()=>handleRemove(office?.id_office)}>Remove</span>
                 </div>
              </div>}
            <div className="otherSectionOffice">
            <div
              className="city uppercase helvetica-neue-bold font-bold"
              style={{ "font-size": "11.5px", "font-weight": "500" }}
            >
              {office?.title}
            </div>

            <div
              className="e-mail helvetica-neue-medium"
              style={{ "font-size": "11.5px" }}
            >
              <a href="">{office?.description}</a>
            </div>
            </div>
          </div>
           )
         })}
        </div>
      </div>
      </LoadingOverlay>
    );
}

export default CardOffice;
