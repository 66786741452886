import React, { useState, useEffect, useRef  } from 'react';
import Slider from 'react-slick';
import '../css/slider.css';
import { CarouselItem } from '../components/my-carousel';
import ReactPlayer from 'react-player/lazy';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';


const LargeSlider  = ({ images, large, pair, active,event,close }) => {
  const [data, setData] = useState([]);
  const [ditem, setDitem] = useState(0);
  // const [state, setState] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);


  const sliderRef = useRef();

  useEffect(() => {
    if (event === "ArrowRight") {
      sliderRef.current.slickNext();
    } else if (event === "ArrowLeft") {
      sliderRef.current.slickPrev();
    }
    else if(event==="Escape")
    {
      close()
    }
  }, [event]);
  console.log(event)

  const next = (e) => {
    sliderRef.current.slickNext();
    document.getElementById("nextStyle").click();
  };
   const previous = (e) => {
    sliderRef.current.slickPrev();
    document.getElementById("preStyle").click();
  };
  useEffect(() => {
    _loadSrc();
      sliderRef.current.slickGoTo(active);
  }, [images]);

  const checkOrientation = async (src) => {
    var orientation;
    if (src.width > src.height) {
      orientation = 'landscape';
    } else if (src.width < src.height) {
      orientation = 'portrait';
    } else {
      orientation = 'landscape';
    }

    return orientation;
  };
  const _loadSrc = async () => {
    let newData = [];

    for (let img of images) {
      let res = null;
      if (!pair) {
        let orientation = await checkOrientation(img);
        res = {
          url: img.route,
          orientation: orientation,
        };
      } else {
        res = {
          url: img.route,
          orientation: 'portrait',
        };
      }
      newData.push(res);
    }
    setData(newData);
  };

  const _renderSlider = () => {

    let tamp = [];
    for (let i = 0; i < data.length; i++) {
      let ext = data[i].url;
      ext = ext.split('.').pop();


      let d = '';
      d = (
            <CarouselItem >
              <img
                src={data[i].url}
                className="image slider-img-large landscape"
                alt="large-slider"
              />
            </CarouselItem>
          );
          if (ext === 'mp4') {
            d = (
              <CarouselItem>
                <div className="vediowrap" key={i}>
                    <ReactPlayer controls url={data[i].url} />
                </div>
              </CarouselItem>
            );
          } else if(!pair && i === 0) {
            d = (
              <CarouselItem className="singleimage">
                <img
                  src={data[i].url}
                  className="image slider-img-large landscape"
                  alt="large-slider"
                />
              </CarouselItem>
            );
          } else if( data[i].orientation !== 'landscape' && data[i + 1]?.orientation !== undefined && data[i + 1]?.orientation !== 'landscape'){
           if(window?.innerWidth<768)
           {
            d = (
              <CarouselItem className="singleimage">
                <img
                  src={data[i].url}
                  className="image slider-img-large landscape"
                  alt="large-slider"
                />
              </CarouselItem>
            );
           }
           else{
            d = (
              <CarouselItem>
                <img
                  src={data[i].url}
                  className="image slider-img-large pair"
                  alt="large-slider"
                />
                  <img
                  src={data[i + 1].url}
                  className="image slider-img-large pair"
                  alt="large-slider"
                />
              </CarouselItem>
            );
            i += 1;
           }
          } else {
            d = (
              <CarouselItem className="singleimage">
                <img
                  src={data[i].url}
                  className="image slider-img-large landscape"
                  alt="large-slider"
                />
              </CarouselItem>
            );
          }
      tamp.push(d);
    }
    localStorage.setItem('items', tamp.length);
    return tamp.map((item) => item);
  };
  const settings = {

    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'slides',
  };
  return (
    <div >
    <button onClick={function(e){
      previous()
      e.stopPropagation()
    }} className="slider-btn preStyle" id="preStyles" >
      <BsChevronLeft color="#eee" size={40}  />
    </button>
     <button onClick={function(e){
      next()
      e.stopPropagation()
    }} className="slider-btn nextStyle" id="nextStyles">
      <BsChevronRight color="#eee" size={40} />
    </button>
   <div >
   <Slider {...settings} ref={sliderRef}>{_renderSlider()}</Slider>
   </div>
     </div>
  );
};

export default LargeSlider;
