import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation.jsx';
import Footer from '../components/Footer.jsx';
import SliderImages from './Slider';
import TalentSlider from './talentSlider';
import LargeSlider from './largeTalentSlider.jsx';
import axios from 'axios';
import config from '../config';

import Loader from '../components/Loader';

class CountryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      telentdata: [],
      renderSlider: false,
      sliderItems: [],
      type: null,
      largeSlider: false,
      isMobile: false,
      loader: true,
      pair: false,
      active: 0,
      event:0,
    };
  }

  onClick = (e) => {
    e.preventDefault()

  }


  componentDidMount() {
    const slug = this.props.match.params.slug;

    document.addEventListener("keydown",(e)=>{
      this.setState({
        event:e.code
      })
      this.setState({
        event:0
      })
    })
    axios.get(`${config.URL}talent?slug=${slug}`).then((response) => {
      this.setState({
        telentdata: response.data.item.rows,
      });
      this.silderShow('portfolio', false);

    });

    // window.addEventListener('resize', this.windowSize);
  }

  componentWillUnmount(){
     this.setState({
      event:0
     })
  }

  silderShow = (picName, pair) => {
    this.setState({
      type: picName,
      pair: pair,
    });

    let images = this.state.telentdata;
    let res = images[0]?.resources;

    let slider = [];
    res.forEach((resource, i) => {
      if (resource.section === picName) {
        slider.push(resource);
      }
    });
    if(slider.length==0){
      res.forEach((resource, i) => {
        if (resource.section === 'polaroid') {
          slider.push(resource);
        }
      });

    }
    slider.sort(function (a, b) {
      return a?.name?.split('-')[0] - b?.name?.split('-')[0];
    });

    if (slider.length > 0) {

      this.setState({
        sliderItems: slider,
        loader: false,
      });
    }
  };

  checksilder = (picName) => {
    let images = this.state.telentdata;
    let res = images[0]?.resources;

    for (let i = 0; i < res?.length; i++) {
      if (res[i].section === picName) {
        return true;
      }
    }
    return false;
  };

  _showLarge = (index) => {
    this.setState({
      largeSlider: true,
      active: index,
    });
  };




  render() {
    console.log(this.state.sliderItems)
    if (this.state.loader) {
      return <Loader />;
    }
    console.log(this.state.event)
    const data = this.state.telentdata;
    console.log(data)

    return (
      <>
        <Navigation />
        <div className="container" >
          <section className="mt-6 pt-32 pr-0" >
            {data.map((talent, i) => (
              <div className="pt-32" key={i}>
                <div className="row newrow">
                  {this.state.type === 'videos' ? (
                    ' '
                  ) : (
                    <div className="col-sm-12 col-md-6 leftbar">
                      <div className="pleft" style={{ marginTop: 32 }}>
                        <div className='title'>
                        <h3 className="detailTalentLastname text-center uppercase">
                          <strong>{`${talent.name}`}</strong> {talent.last_name}
                        </h3>
                        {this.state.type === 'creator' && talent.profile && (
                          <>{talent.profile}</>
                            )}
                        </div>
                        <div className="flex justify-center">
                        {this.state.type === 'creator' ? (
                          <>
                          {talent.bio && (
                          <><div className='biowrap'>{talent.bio}</div></>
                            )}
                          </>
                        ):(
                          <>
                          <table
                            className="tableTalentDetails helvetica-neue-light"
                            style={{
                              width: '100%',
                              margin: '14px 0px 40px auto',
                            }}
                          >
                            <tbody>
                            {talent.height && (
                              <tr
                                className="fles justify-center"
                                style={{ width: '100%' }}
                              >
                                <th> HEIGHT </th>
                                <td className="helvetica-neue-medium italic">
                                  {talent.height}
                                </td>
                              </tr>
                            )}
                            {talent.bust && (
                              <tr>
                                <th> {talent?.gender==="men"?"CHEST":"BUST"}</th>
                                <td className="helvetica-neue-medium italic">
                                  {talent.bust}
                                </td>
                              </tr>
                            )}
                            {talent.waist && (
                              <tr>
                                <th> WAIST</th>
                                <td className="helvetica-neue-medium italic">
                                  {talent.waist}
                                </td>
                              </tr>
                            )}
                            {talent.hips && (
                              <tr>
                                <th> HIPS</th>
                                <td className="helvetica-neue-medium italic">
                                  {talent.hips}
                                </td>
                              </tr>
                            )}
                            {talent.shoes && (
                              <tr>
                                <th> SHOES</th>

                                <td className="helvetica-neue-medium italic">
                                  {talent.shoes}
                                </td>
                              </tr>
                            )}
                            {talent.eyes && (
                              <tr>
                                <th> EYES</th>

                                <td className="helvetica-neue-medium italic uppercase">
                                  {talent.eyes}
                                </td>
                              </tr>
                            )}
                            <tr>
                              {talent.hair && (
                                <>
                                  <th> HAIR</th>
                                  <td className="helvetica-neue-medium italic uppercase">
                                    {talent.hair}
                                  </td>
                                </>
                              )}
                            </tr></tbody>
                          </table>
                          </>
                        )}

                        </div>

                        <div className="flex justify-center insta">
                          <div className="pdf_btn">
                            {talent.instagram !== '@' &&
                              talent.instagram !== '' && (
                                <>
                                  <a  href={
                                       'https://www.instagram.com/' +
                                     talent.instagram.substring(1,talent.instagram.length)
                                     }
                                     target="_blank"
                                  >
                                    <i
                                      className="fa fa-instagram"
                                      style={{
                                        fontSize: '24px',
                                        display: 'block',
                                        marginBottom: 10,
                                      }}
                                    ></i>
                                  </a>
                                  <a
                                    className="helvetica-neue-italic"
                                    href onClick={this.onClick}
                                  >
                                  </a>
                                </>
                              )}
                            <div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      this.state.type === 'videos'
                        ? 'col-sm-12 col-md-12'
                        : 'col-sm-12 col-md-7 rightbar'
                    }
                  >

                    {this.state.sliderItems.length > 0 ? (

                      <div
                      className={
                        this.state.type !== ''
                          ? this.state.type + ' pright'
                          : this.state.type + ' pright'
                      }>


                        {this.state.type === 'videos' || this.state.isMobile ? (
                          <SliderImages
                            type={this.state.type}
                            sliderItems={this.state.sliderItems}
                            open={() => this.setState({ largeSlider: true })}
                            large={this.state.largeSlider}
                            event={this.state.event}
                          />
                        ) : (
                          <TalentSlider
                            images={this.state.sliderItems}
                            large={this.state.largeSlider}
                            open={this._showLarge}
                            pair={this.state.pair}
                            event={this.state.event}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div  className="detail_btn text-center my-4"   style={{ fontStyle: 'italic' }} >
                  {this.checksilder('portfolio') && (
                    <label
                      className={
                        this.state.type === 'portfolio'
                          ? 'active px-15 font-bold'
                          : 'px-15 hover:font-bold'
                      }
                      onClick={() => this.silderShow('portfolio', false)}
                    >
                      PORTFOLIO
                    </label>
                  )}
                  {this.checksilder('polaroid') && (
                    <label
                      className={
                        this.state.type === 'polaroid'
                          ? 'active px-15 font-bold'
                          : 'px-15 hover:font-bold'
                      }
                      onClick={() => this.silderShow('polaroid', true)}
                    >
                      POLAROIDS
                    </label>
                  )}
                  {this.checksilder('videos') && (
                    <label
                      className={
                        this.state.type === 'videos'
                          ? 'active px-15 font-bold'
                          : 'px-15 hover:font-bold'
                      }
                      onClick={() => this.silderShow('videos', false)}
                    >
                      {' '}
                      VIDEOS
                    </label>
                  )}
                  {this.checksilder('creator') && (
                    <label
                      className={
                        this.state.type === 'creator'
                          ? 'active px-15 font-bold'
                          : 'px-15 hover:font-bold'
                      }
                      onClick={() => this.silderShow('creator', false)}
                    >
                      {' '}
                      CREATOR
                    </label>
                  )}
                </div>
              </div>
            ))}
          </section>
          <Footer />

        {this.state.largeSlider && (
          <div className='largeSliderContainer'
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
              position: 'fixed',
              zIndex: 1000,
            }}
            onClick={() => this.setState({ largeSlider: false })}
          >
            <i
              style={{
                position: 'absolute',
                cursor: 'pointer',
                float: 'right',
                right: 10,
                top: 10,
                zIndex: 1200,
              }}
              onClick={() => this.setState({ largeSlider: false })}
              className="material-icons md-6"
            >
              clear
            </i>
            {this.state.sliderItems.length > 0 ? (
              <div  className="innerslider">
                <LargeSlider
                  images={this.state.sliderItems}
                  large={this.state.largeSlider}
                  pair={this.state.pair}
                  active={this.state.active}
                  event={this.state.event}
                  close={()=>{this.setState({largeSlider:false})}}
                />
              </div>
            ) : null}
          </div>
        )}
        </div>
      </>
    );
  }
}

export default CountryDetail;
