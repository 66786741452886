import React from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import Config from "../../config";

import ReactS3Client from "react-aws-s3-typescript";
import reactImageSize from "react-image-size";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";

export default function Carousel({
  home,
  addItem,
  itemIndex,
  closeModal,
  deleteHome,
}) {
  const [creditsList, setCreditsList] = React.useState([
    { title: "", text: "" },
  ]);
  const [uploading, setUploading] = React.useState(false);
  const [uploadingProgress, setUploadingProgress] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const [filesUrl, setFilesUrl] = React.useState([]);
  const [talentName, setTalentName] = React.useState("");
  const [clientName, setClientName] = React.useState("");

  React.useEffect(() => {
    if (home !== null && itemIndex !== null) {
      setTalentName(home?.resources[itemIndex]?.meta?.talent_name);
      setClientName(home?.resources[itemIndex]?.meta?.client_name);
      setCreditsList(home?.resources[itemIndex]?.meta?.credits);
    }
  }, [home]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    var list = [...creditsList];
    list[index][name] = value;
    setCreditsList(list);
  };

  // handle input Add
  const handleInputAdd = () => {
    if (creditsList.length < 10) {
      setCreditsList([...creditsList, { title: "", text: "" }]);
    }
  };

  // handle Input Remove
  const handleInputRemove = (index) => {
    creditsList.splice(index, 1);
    setCreditsList([...creditsList]);
  };

  const handleFileChange = (e) => {
    let filesList = [...e.target.files];

    let list = [];
    filesList.map((file) => {
      if (file?.size < 7340032) {
        list.push(file);
      }
    });
    setFiles([...files, ...list]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (talentName === "" || clientName === "") {
      toast.error("Fill the fields!");
      return;
    }

    if (!addItem && itemIndex === null) {
      if (files.length < 4) {
        toast.error("Select Minimum 4 files!");
      } else {
        setUploading(true);
        for (let file of files) {
          uploadFiles(file);
        }
      }
    } else if (itemIndex !== null && !addItem) {
      if (files.length === 1) {
        setUploading(true);
        for (let file of files) {
          uploadFiles(file);
        }
      } else if (files.length > 1) {
        toast.error("Only 1 file allow!");
      } else {
        updateCarsousel();
      }
    } else {
      if (files.length < 5) {
        setUploading(true);
        for (let file of files) {
          uploadFiles(file);
        }
      } else {
        toast.error("Select Maximum 4 file!");
      }
    }
  };

  const handleFileRemove = (i) => {
    files.splice(i, 1);
    setFiles([...files]);
  };

  const uploadFiles = async (acceptedFile) => {
    let file = acceptedFile;
    let type = file.type;
    let name = file.name;
    let filename =
      name.replace(/\.[^/.]+$/, "") +
      "-" +
      Math.floor(Math.random() * 10000000 + 1);

    let imgheight = "";
    let imgwidth = "";
    try {
      const storageRef = ref(storage, filename);
      let resp = [];
      await uploadBytes(storageRef, file).then((snapshot) => {});
      await getDownloadURL(storageRef).then((url) => {
        resp.push(url);
      });
      const { width, height } = await reactImageSize(resp[0]);
      imgheight = height;
      imgwidth = width;
      let item = {
        route: resp[0],
        type: type,
        height: imgheight,
        width: imgwidth,
        meta: {
          talent_name: talentName,
          client_name: clientName,
          credits: creditsList,
        },
      };
      filesUrl.push(item);
      setFilesUrl([...filesUrl]);
    } catch (exception) {
      console.log(exception);
      toast.error(exception);
    }
  };

  const uploadFeature = () => {
    let token = localStorage.getItem("token");

    let data = {
      talent_name: talentName,
      client_name: clientName,
      credits: creditsList,
      resources: filesUrl,
      component: "timeline",
      sub_component: "carousel",
    };

    axios
      .post(`${Config.URL}home`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setUploading(false);
        toast.success("Uploaded Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        // toast.success(error);
        setUploading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  React.useEffect(() => {
    if (!addItem && itemIndex === null) {
      if (files.length > 6 && filesUrl.length === files.length) {
        uploadFeature();
      }
    } else if (!addItem && itemIndex !== null) {
      if (files.length === 1 && filesUrl.length === files.length) {
        updateCarsousel();
      }
    } else {
      if (files.length > 0 && filesUrl.length === files.length) {
        updateCarsousel();
      }
    }
  }, [filesUrl]);

  // Update Carsousel
  const updateCarsousel = () => {
    let token = localStorage.getItem("token");
    let data = {};
    if (addItem) {
      data = {
        resources: [...home?.resources, ...filesUrl],
        component: "timeline",
        sub_component: "carousel",
        id_home: home?.id_home,
      };
    } else {
      if (filesUrl.length === 0) {
        let arr = [...home?.resources];
        let newdata = [];
        for (let i = 0; i < arr.length; i++) {
          if (i === itemIndex) {
            let d = {
              ...arr[itemIndex],
              meta: {
                talent_name: talentName,
                client_name: clientName,
                credits: creditsList,
              },
            };
            newdata.push(d);
          } else {
            newdata.push(arr[i]);
          }
        }
        data = {
          resources: newdata,
          component: "timeline",
          sub_component: "carousel",
          id_home: home?.id_home,
        };
      } else {
        let arr = [...home?.resources];
        let newdata = [];
        for (let i = 0; i < arr.length; i++) {
          if (i === itemIndex) {
            newdata.push(filesUrl[0]);
          } else {
            newdata.push(arr[i]);
          }
        }
        data = {
          resources: newdata,
          component: "timeline",
          sub_component: "carousel",
          id_home: home?.id_home,
        };
      }
    }
    setUploading(true);

    axios
      .post(`${Config.URL}home/update`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast.success("Updated Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        // toast.success(error);
        setUploading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div>
      <div className="row" style={{ padding: "0 15px", marginTop: 50 }}>
        <>
          <input
            className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
            style={{
              fontSize: 11,
              borderColor: "rgba(167, 167, 167, 0.63)",
              marginTop: 20,
            }}
            value={talentName}
            type="text"
            name="talentName"
            placeholder="Talent Name*"
            onChange={(e) => setTalentName(e.target.value)}
          />
          <input
            className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
            style={{
              fontSize: 11,
              borderColor: "rgba(167, 167, 167, 0.63)",
              marginTop: 10,
            }}
            value={clientName}
            type="text"
            name="clientName"
            placeholder="Client Name*"
            onChange={(e) => setClientName(e.target.value)}
          />
          <label style={{ marginTop: 10, marginLeft: 5, color: "#a7a7a7" }}>
            CREDITS*
          </label>
          {creditsList.map((item, index) => (
            <div className="row" key={index}>
              <div className="col-sm-4">
                <input
                  className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
                  style={{
                    fontSize: 11,
                    borderColor: "rgba(167, 167, 167, 0.63)",
                    marginTop: 10,
                  }}
                  type="text"
                  name="title"
                  placeholder="Photographer"
                  onChange={(event) => handleInputChange(event, index)}
                  value={item.title}
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
                  style={{
                    fontSize: 11,
                    borderColor: "rgba(167, 167, 167, 0.63)",
                    marginTop: 10,
                  }}
                  type="text"
                  name="text"
                  placeholder="Zoë Ghertner"
                  onChange={(event) => handleInputChange(event, index)}
                  value={item.text}
                />
              </div>
              <div
                className="col-sm-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {creditsList.length !== 1 && (
                  <div
                    style={{
                      fontSize: 17,
                      marginTop: 12,
                      color: "#a7a7a7",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={() => handleInputRemove(index)}
                  >
                    <FaTrash />
                  </div>
                )}
                {creditsList.length !== 10 &&
                  creditsList.length - 1 === index && (
                    <div
                      style={{
                        fontSize: 17,
                        marginTop: 12,
                        color: "#a7a7a7",
                        cursor: "pointer",
                        display: "inline-block",
                      }}
                      onClick={handleInputAdd}
                    >
                      <FaPlus />
                    </div>
                  )}
              </div>
            </div>
          ))}
        </>
        <>
          <div
            style={{
              padding: "4px 2px",
              background: "#eaecef",
            }}
          >
            <input
              type="file"
              multiple
              accept="image/*"
              className="w-full placeholder-gray-700 helvetica-neue-thin custom-file-input"
              style={{
                margin: 0,
                padding: 0,
                fontSize: 12,
                textTransform: "lowercase",
                width: "100%",
              }}
              onChange={handleFileChange}
            />
          </div>
          <div
            style={{
              textAlign: "right",
              color: "#a7a7a7",
              fontSize: 12,
              marginTop: 5,
              fontStyle: "italic",
            }}
          >
            Max File Size Limit 7MB*
          </div>
        </>
      </div>

      <div className="pb-4 border-gray-100 border-opacity-25 bg-transparent">
        <ul className="list-group mt-2">
          <>
            {files.length > 0 &&
              files.map((acceptedFile, i) => (
                <li
                  key={i}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="list-group-item list-group-item-success"
                >
                  <span>{acceptedFile.name}</span>
                  <span onClick={() => handleFileRemove(i)}>
                    <FaTrash style={{ cursor: "pointer" }} />
                  </span>
                </li>
              ))}
          </>
        </ul>
      </div>

      <button
        // href="#submit-confirm"
        // data-dismiss="modal"
        // data-toggle="modal"
        disabled={uploading}
        onClick={handleSubmit}
        style={{ fontSize: "1em", height: 40 }}
      >
        {uploading
          ? `Feature Uploading Please wait.... ${uploadingProgress}%`
          : "Submit"}
      </button>
      {home !== null && (
        <button
          // data-dismiss="modal"
          onClick={(e) => {
            e.preventDefault();
            deleteHome(home?.id_home);
          }}
          style={{ fontSize: "1em", height: 40 }}
        >
          Delete Carousel
        </button>
      )}
      <button
        data-dismiss="modal"
        onClick={closeModal}
        style={{ fontSize: "1em", height: 40 }}
      >
        Close
      </button>
    </div>
  );
}
