import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Config from "../../config";

import ReactS3Client from "react-aws-s3-typescript";
import reactImageSize from "react-image-size";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";

export default function LandingVideo() {
  const [acceptedFile, setAcceptedFile] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [uploadingProgress, setUploadingProgress] = React.useState(0);

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    if (file?.size > 50214400) {
      toast.error("Max File Size is 50 MB!");
    } else {
      setAcceptedFile(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (acceptedFile) {
      setUploading(true);
      uploadFiles();
    } else {
      toast.error("Please Select Video!");
    }
  };

  const uploadFiles = async () => {
    let file = acceptedFile;

    let type = file.type;
    let name = file.name;
    let filename =
      name.replace(/\.[^/.]+$/, "") +
      "-" +
      Math.floor(Math.random() * 10000000 + 1);
    try {
      const storageRef = ref(storage, filename);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(storageRef).then((url) => {
          let item = { route: url, type: type };
          uploadFeature(item);
        });
      });
    } catch (exception) {
      setUploading(false);
      toast.error(exception);
    }
  };

  const uploadFeature = (item) => {
    let token = localStorage.getItem("token");

    let data = {
      resources: item,
      component: "landing_video",
    };

    axios
      .post(`${Config.URL}home`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setUploading(false);
        toast.success("Uploaded Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        // toast.success(error);
        setUploading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <>
      <div>
        <div className="row" style={{ padding: "0 15px", marginTop: 100 }}>
          <div
            style={{
              padding: "4px 2px",
              background: "#eaecef",
            }}
          >
            <input
              type="file"
              accept="video/*"
              className="w-full placeholder-gray-700 helvetica-neue-thin"
              style={{
                margin: 0,
                padding: 0,
                fontSize: 12,
                textTransform: "lowercase",
                width: "100%",
              }}
              onChange={handleFileChange}
            />
          </div>
          <div
            style={{
              textAlign: "right",
              color: "#a7a7a7",
              fontSize: 12,
              marginTop: 5,
              fontStyle: "italic",
            }}
          >
            Max File Size Limit 50MB*
          </div>
        </div>
        <button
          //   href='#submit-confirm'
          //   data-dismiss='modal'
          //   data-toggle='modal'
          disabled={uploading}
          onClick={handleSubmit}
          style={{ fontSize: "1em", height: 40 }}
        >
          {uploading
            ? `Video Uploading Please wait.... ${uploadingProgress}%`
            : "Submit"}
        </button>
      </div>
    </>
  );
}
