import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import Navigation from '../components/Navigation.jsx';
import Footer from '../components/Footer.jsx';
import axios from 'axios';
import config from '../config';
import { MdCancel, MdExitToApp } from 'react-icons/md';
import Loader from '../components/Loader';
import slugify from 'react-slugify';
import '../css/TalentPrivate.css';
import * as mime from 'react-native-mime-types';
import reactImageSize from 'react-image-size';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebase";


function CountryTalentPrivate(props) {
  const [state, setState] = useState({
    data: [],
    name: '',
    slugs: '',
    surname: '',
    gender: '',
    category: '',
    height: '',
    bust: '',
    waist: '',
    hips: '',
    shoes: '',
    eyes: '',
    hair: '',
    creator: 'false',
    profile: '',
    bio:'',
    slugs: '',
    instagram: '',
    available: true,
    coverFile: [],
    coverUrl: null,
    coverPreview: '',
    portfolioFiles: [],
    portfolioUrl: [],
    portfolioPreview: [],
    creatorPreview: [],
    creatorFile: [],
    polasFiles: [],
    polasUrl: [],
    polasPreview: [],
    videoFiles: [],
    videoUrl: [],
    videoPreview: [],
    uploadingProgress: 0,
    uploading: false,
    resources: [],
  });
  const [urlLength, setUrlLength] = useState([]);
  const [filesLength, setFilesLength] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [editVideo, setEditVideo] = useState([]);
  const [editPortfolio, setEditPortfolio] = useState([]);
  const [editCreatorwork, setEditCreatorwork] = useState([]);
  const [editPolaroid, setEditPolaroid] = useState([]);
  const [loader, setLoader] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);



  useEffect(() => {
    if (props.match.path === '/AddTalent/:country') {
         console.log(props?.match?.params?.country)
      setLoader(false);
    } else if (props?.match?.path === '/EditTalent/:slug/:country') {
      const slug = props?.match?.params?.slug;
      console.log(props?.match?.params?.country)

      axios.get(`${config.URL}talent?slug=${slug}`).then((response) => {
        let row = response.data.item?.rows[0];

        let cover = null;
        let pol = [];
        let port = [];
        let video = [];
        let creator = [];
        row.resources.forEach((item) => {
          delete item['type'];
          if (item.section === 'cover') {
            cover = item;
          } else if (item.section === 'polaroid') {
            pol.push(item);
          } else if (item.section === 'portfolio') {
            port.push(item);
          } else if (item.section === 'videos') {
            video.push(item);
          }
          else if (item.section === 'creator') {
            creator.push(item);
          }
        });
        setEditPolaroid(pol);
        setEditPortfolio(port);
        setEditVideo(video);
        setEditCreatorwork(creator);

        setState({
          ...state,
          data: row,
          name: row.name,
          bio: row.bio,
          surname: row.last_name,
          gender: row.gender,
          category: row.development === true ? 'development' : row.category,
          height: row.height,
          bust: row.bust,
          waist: row.waist,
          hips: row.hips,
          shoes: row.shoes,
          eyes: row.eyes,
          hair: row.hair,
          creator: row.creator,
          profile: row.profile,
          instagram: row.instagram,
          coverPreview: cover.route,
          coverFile: [cover],
        });
        setLoader(false);
      });
    }
  }, []);

  const _handleSubmit = async (e) => {
    e.preventDefault();

    let { name, category, coverFile, portfolioFiles, polasFiles, videoFiles,creatorFile } =
      state;
    if (name && category && coverFile.length) {
      setIsActive(true);
      let fileList = [];
      if (coverFile[0].file) {
        fileList = [
          ...coverFile,
          ...portfolioFiles,
          ...polasFiles,
          ...videoFiles,
          ...creatorFile,
        ];
      } else {
        fileList = [...portfolioFiles, ...polasFiles, ...videoFiles,...creatorFile];
      }
      setFilesLength(fileList.length);

      let size = fileList.filter(
        (i) => i.section !== 'videos' && i.file.type !=='video/mp4' && i.file.size > 10000000
      );

      if (size.length > 0) {
        size.forEach((i) => {
          toast.error(`${i.section} ${i.file.name} size large than 10mb`);
        });
        setIsActive(false);
        return;
      }

      let vsize = fileList.filter(
        (i) => i.file.type ==='video/mp4' && i.file.size > 25000000
      );

      if (vsize.length > 0) {
        vsize.forEach((i) => {
          toast.error(`${i.section} ${i.file.name} size large than 25mb`);
        });
        setIsActive(false);
        return;
      }

      if (fileList.length > 0 && vsize.length === 0 && size.length === 0) {
        for (const element of fileList) {
          await _uploadFiles(element);
        }
      } else {
        addTalent();
      }
    } else {
      toast.error('Name, Category, Cover  are Required!');
      setIsActive(false);
    }
  };

  const _uploadFiles = async (acceptedFile) => {
    let file = acceptedFile.file;
    let section = acceptedFile.section;
    let position = acceptedFile.position? acceptedFile.position:0;
    let type = acceptedFile.file.type;
    let name = acceptedFile.file.name;
    let filename = name.replace(/\.[^/.]+$/, "") +'-'+ Math.floor((Math.random() * 10000000) + 1);
    let imgheight='';
    let imgwidth='';
    try {
      const storageRef = ref(storage, filename);
      let resp = [];
      await uploadBytes(storageRef, file).then((snapshot) => {});
      await getDownloadURL(storageRef).then((url) => {
        resp.push(url);
      });
        let item;
        if(file.type != 'video/mp4') {
          const { width, height } = await reactImageSize(resp[0]);
          imgheight = height;
          imgwidth = width;

           item = {

            route: resp[0],
            type: type,
            height: imgheight,
            width: imgwidth,
            section: section,
            position: position,
            name: name,
          };
        }else{


           item = {
            route: resp[0],
            type: type,
            section: section,
            position: position,
            name: name,
          };
        }


      toast.success(name + 'updated');
      urlLength.push(item);
      setUrlLength([...urlLength]);
    } catch (exception) {
      console.log(exception);
      toast.error(exception);
    }


  };

  useEffect(() => {
    if (filesLength > 0) {
      if (urlLength.length === filesLength) {
        addTalent();
      }
    }
  }, [urlLength]);

  const addTalent = async () => {
    const token = localStorage.getItem('token');
    // const formData = new FormData();
    var endPoint = '';
    var url = null;
    var data = null;

    if (props.match.path === '/AddTalent/:country') {
      endPoint = 'talent';
      url = urlLength;
      let counts=window.location.pathname.split("/")[2];
      if(window.location.pathname.split("/")[2]==="baltics")
      {
        counts="latvia";
      }
      data = {
        name: state.name,
        last_name: state.surname,
        slug:state.name+"-"+state.surname,
        instagram: `@${state.instagram.replace(/@/g, '')}`,
        gender: state.gender,
        available: state.available,
        category: state.category,
        hair: state.hair,
        eyes: state.eyes,
        height: state.height,
        bust: state.bust,
        waist: state.waist,
        hips: state.hips,
        shoes: state.shoes,
        creator: state.creator,
        profile: state.profile,
        country:counts,
        resources: [...url],
      };
    } else {
      endPoint = 'talent/update';
      if (urlLength.length === 0) {
        url = state.coverFile;
      } else {
        let check = urlLength.filter((item) => item.section === 'cover');
        if (check.length === 0) {
          url = [...urlLength, ...state.coverFile];
        } else {
          url = [...urlLength];
        }
      }

      if(state.instagram==''){
        state.instagram='';
      } else {
        state.instagram=`@${state.instagram.replace(/@/g, '')}`;
      }

      state.data.slug=slugify(state.name+' '+state.surname);
      let counts=window.location.pathname.split("/")[3];
      if(window.location.pathname.split("/")[3]==="baltics")
      {
        counts="latvia";
      }
      data = {
        name: state.name,
        last_name: state.surname,
        instagram: state.instagram,
        gender: state.gender,
        available: state.available,
        category: state.category,
        bio: state.bio,
        hair: state.hair,
        eyes: state.eyes,
        height: state.height,
        bust: state.bust,
        waist: state.waist,
        hips: state.hips,
        shoes: state.shoes,
        creator: state.creator,
        profile: state.profile,
        id_talent: state.data.id_talent,
        slug: state.data.slug,
        country:counts,
        resources: [...url, ...editPolaroid, ...editPortfolio, ...editVideo, ...editCreatorwork],
      };
    }


    const headers = {
      Authorization: `Bearer ${token}`,
    };
    data['token'] = token;

    axios
      .post(`${config.URL}${endPoint}`, data, {
        headers,
      })
      .then((res) => {

        setIsActive(false);
        toast.success(res.message);
        window.location = `/${props?.match?.params?.country}`;

      })
      .catch((err) => {
        setIsActive(false);
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
          return;
        }
        toast.error(err.response);
      });
  };

  const handleInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const _handleCoverImgChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let list = [];
    list.push({
      section: 'cover',
      file: file,
    });
    reader.onloadend = () => {
      setState({
        ...state,
        coverPreview: reader.result,
        coverFile: [...list],
      });
    };
    reader.readAsDataURL(file);
  };

  const _handleFileChange = (e, type) => {
    e.preventDefault();
    let files = e.target.files;

    if (type === 'portfolio') {
      let tempPreview = [];
      let tempFiles = [];
      [...files].forEach((file, i) => {
        tempPreview.push(URL.createObjectURL(file));
        tempFiles.push({ section: 'portfolio', position: i, file });
      });

      setState({
        ...state,
        portfolioPreview: [...state.portfolioPreview, ...tempPreview],
        portfolioFiles: [...state.portfolioFiles, ...tempFiles],
      });
    } else if (type === 'videos') {
      let tempPreview = [];
      let tempFiles = [];
      [...files].forEach((file, i) => {
        tempPreview.push(URL.createObjectURL(file));
        tempFiles.push({ section: 'videos', position: i, file });
      });

      setState({
        ...state,
        videoPreview: [...state.videoPreview, ...tempPreview],
        videoFiles: [...state.videoFiles, ...tempFiles],
      });
    }

    else if (type === 'creator') {

      let tempPreview = [];
      let tempFiles = [];
      [...files].forEach((file, i) => {
        tempPreview.push(URL.createObjectURL(file));
        tempFiles.push({ section: 'creator', position: i, file });
      });

      // console.log(tempPreview,tempFiles);
      setState({
        ...state,
        creatorPreview: [...state.creatorPreview, ...tempPreview],
        creatorFile: [...state.creatorFile, ...tempFiles],
      });


    }


    else {
      let tempPreview = [];
      let tempFiles = [];
      [...files].forEach((file, i) => {
        tempPreview.push(URL.createObjectURL(file));
        tempFiles.push({ section: 'polaroid', position: i, file });
      });
      setState({
        ...state,
        polasPreview: [...state.polasPreview, ...tempPreview],
        polasFiles: [...state.polasFiles, ...tempFiles],
      });
    }
  };

  /* handle remove when edit talent   */
  const _handleEditRemove = (type, i) => {
    if (type === 'portfolio') {
      editPortfolio.splice(i, 1);

      setEditPortfolio([...editPortfolio]);
    } else if (type === 'videos') {
      editVideo.splice(i, 1);
      setEditVideo([...editVideo]);
    } else if (type === 'creator') {
      editCreatorwork.splice(i, 1);
      setEditCreatorwork([...editCreatorwork]);
    } else {
      editPolaroid.splice(i, 1);
      setEditPolaroid([...editPolaroid]);
    }
  };
  /* handle remove when add talent assets */
  const _handleRemove = (type, i) => {
    let {
      videoFiles,
      videoPreview,
      portfolioFiles,
      portfolioPreview,
      polasFiles,
      polasPreview,
      creatorFile,
      creatorPreview,
    } = state;
    if (type === 'portfolio') {
      portfolioFiles.splice(i, 1);
      portfolioPreview.splice(i, 1);
      setState({
        ...state,

        portfolioFiles,
        portfolioPreview,
      });
    } else if (type === 'videos') {
      videoFiles.splice(i, 1);
      videoPreview.splice(i, 1);
      setState({
        ...state,

        videoFiles,
        videoPreview,
      });
    } else if (type === 'creator') {
      creatorFile.splice(i, 1);
      creatorPreview.splice(i, 1);
      setState({
        ...state,

        creatorFile,
        creatorPreview,
      });
    } else {
      polasFiles.splice(i, 1);
      polasPreview.splice(i, 1);
      setState({
        ...state,

        polasFiles,
        polasPreview,
      });
    }
  };




  const [dragId, setDragId] = useState();


  const handleDrag = (ev) => {

    setDragId(ev.currentTarget.id);
  };

  const handleDropImage = (ev) => {
    // const dragBox = editPortfolio.find((port) => port.position === parseInt(dragId));
    // const dropBox = editPortfolio.find((port) => port.position === parseInt(ev.currentTarget.id));
    // const dragBoxOrder = dragBox.position;


    if(ev.currentTarget.lang=='portfolio') {
      var dropBoxOrder = ev.currentTarget.id;

    editPortfolio.splice(dropBoxOrder, 0, editPortfolio.splice(dragId, 1)[0]);
        // editPortfolio.splice(dropBoxOrder, 1);
    setEditPortfolio([...editPortfolio]);

    }

    if(ev.currentTarget.lang=='polas') {
      var dropBoxOrder = ev.currentTarget.id;
      editPolaroid.splice(dropBoxOrder, 0, editPolaroid.splice(dragId, 1)[0]);
          // editPortfolio.splice(dropBoxOrder, 1);
      setEditPolaroid([...editPolaroid]);

      }

      if(ev.currentTarget.lang=='creator') {
        var dropBoxOrder = ev.currentTarget.id;
        editCreatorwork.splice(dropBoxOrder, 0, editCreatorwork.splice(dragId, 1)[0]);
            // editPortfolio.splice(dropBoxOrder, 1);
        setEditCreatorwork([...editCreatorwork]);

        }

        if(ev.currentTarget.lang=='videos') {

          var dropBoxOrder = ev.currentTarget.id;
          editVideo.splice(dropBoxOrder, 0, editVideo.splice(dragId, 1)[0]);
              // editPortfolio.splice(dropBoxOrder, 1);
          setEditVideo([...editVideo]);

          }

  };












  if (loader) {
    return <Loader />;
  }
  return (
    <>
    <LoadingOverlay
      active={isActive}
      spinner
      text={`Uploading your content...   ${state.uploadingProgress}%`}
    >
      <div className="talent-private">
        <Navigation />
        <section className="container mt-6 pt-32">
          <div className="row mt-16">
            <div>
                  <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => setTabIndex(index)}
                  >
              <div className="row">
                <div className="col-sm-4">
                  <div className="text-right w-3/4 ml-auto helvetica-neue-light">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="input-wrapper pb-4">
                          <input
                            type="text"
                            placeholder="FIRST NAME"
                            className="w-full text-center font-semibold text-3xl"
                            style={{ marginLeft: '0px' }}
                            name="name"
                            value={state.name}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-wrapper pb-4">
                          <input
                            type="text"
                            placeholder="SURNAME"
                            className="w-full text-center font-semibold text-3xl"
                            style={{ marginLeft: '0px' }}
                            name="surname"
                            value={state.surname}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pb-4 border-gray-100 border-opacity-25 bg-transparent">
                      <select
                        name="gender"
                        id="gender"
                        className="w-full uppercase bg-transparent border-opacity-25"
                        value={state.gender}
                        onChange={handleInput}
                      >
                        <option value="" className="uppercase">
                          Select Gender*
                        </option>
                        <option value="men">MALE</option>
                        <option value="women">FEMALE</option>
                        <option value="nonbinary">NONBINARY</option>
                      </select>
                    </div>
                    <div className="pb-4 border-gray-100 border-opacity-25 bg-transparent">
                      <select
                        name="category"
                        id="category"
                        className="w-full uppercase input-wrapper bg-transparent"
                        value={state.category}
                        onChange={handleInput}
                      >
                        <option value="" className="uppercase">
                          Select Category*
                        </option>
                        <option value="image">Image</option>
                        <option value="development">Development</option>
                      </select>
                    </div>

                    <Input
                      label="Height"
                      name="height"
                      value={state.height}
                      handleInput={handleInput}
                    />
                    <Input
                      label="Bust"
                      name="bust"
                      value={state.bust}
                      handleInput={handleInput}
                    />
                    <Input
                      label="Waist"
                      name="waist"
                      value={state.waist}
                      handleInput={handleInput}
                    />
                    <Input
                      label="Hips"
                      name="hips"
                      value={state.hips}
                      handleInput={handleInput}
                    />
                    <Input
                      label="Shoes"
                      name="shoes"
                      value={state.shoes}
                      handleInput={handleInput}
                    />
                    <Input
                      label="Eyes"
                      name="eyes"
                      value={state.eyes}
                      handleInput={handleInput}
                    />
                    <Input
                      label="Hair"
                      name="hair"
                      value={state.hair}
                      handleInput={handleInput}
                    />

<div className="input-wrapper">
<label for="creator">Creator</label>
<select
                        name="creator"
                        id="creator"
                        className="w-full uppercase input-wrapper bg-transparent"
                        value={state.creator}
                        onChange={handleInput}
                        style={{ width: '145px', margin: '15px 0px 5px 10px' }}
                      >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                      </select></div>
                      {/* <div className="pb-4 pt-5 border-gray-100 border-opacity-25 bg-transparent" >
                      <select
                        name="creator"
                        id="creator"
                        className="w-full uppercase input-wrapper bg-transparent"
                        value={state.category}
                        onChange={handleInput}
                      >
                        <option value="" className="uppercase">
                          Select Category*
                        </option>
                        <option value="creator">Creator</option>
                      </select>
                    </div> */}

                    <Input
                      label="Profile"
                      name="profile"
                      value={state.profile}
                      handleInput={handleInput}
                    />
                    <div className="my-12 text-right">
                      <div className="flex justify-center my-4 ">
                        <i
                          className="fa fa-instagram fa-2x"
                          style={{ fontSize: '40px' }}
                        ></i>
                      </div>
                      <div className="flex justify-center">
                        <input
                          type="text"
                          placeholder="@"
                          className="text-center"
                          style={{ marginLeft: '0px' }}
                          value={state.instagram}
                          name="instagram"
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-8 uppercase italic"
                  style={{ paddingLeft: '50px' }}
                >
                    <TabPanel>
                      <div className="screen">
                        {state.coverPreview ? (
                          <img
                            src={state.coverPreview}
                            alt=""
                            style={{
                              maxHeight: 250,
                              objectFit: 'contain',
                              padding: 10,
                            }}
                          />
                        ) : (
                          <p style={{ padding: '5px 5px' }}>Cover</p>
                        )}
                      </div>
                      <div className="mt-4 upload-wrapper">
                        <label
                          htmlFor="file"
                          className="uppercase italic px-3 py-1 addFile"
                        >
                          Add File(s) *
                        </label>
                        <input
                          id="file"
                          type="file"
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={_handleCoverImgChange}
                        />
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div
                        className="screen"
                        style={{
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          padding: 5,
                        }}
                      >

                        {state.portfolioPreview.length > 0 ? (
                          <div className="custom-row">
                            {state.portfolioPreview.map((res, i) => (
                              <>
                              <div className="item-img" key="res-${i}">
                                <img
                                  src={res}
                                  alt=""
                                  className="talent-img"
                                />
                                <div
                                  className="telent-img-delete"
                                  onClick={() =>
                                    _handleRemove('portfolio', i)
                                  }
                                >
                                  <MdCancel />
                                </div>
                              </div>
                              </>
                            ))}
                          </div>
                        ) : editPortfolio.length === 0 ? (
                          'Portfolio'
                        ) : (
                          ''
                        )}

                        {editPortfolio.length > 0 && (
                          <div className="custom-row">


                          {editPortfolio

                                  .map((port, i) => (
                                    <>
                                    {/* <Box
                                      key={port.position}
                                      imagesrc= {port.route}
                                      boxNumber={port.position}
                                      handleDrag={handleDrag}
                                      handleDrop={handleDropImage}
                                    /> */}

                                      <div className="item-img"
                                        draggable={true}
                                        id={i}
                                        lang='portfolio'
                                        onDragOver={(ev) => ev.preventDefault()}
                                        onDragStart={handleDrag}
                                        onDrop={handleDropImage}
                                      >
                                        <img
                                  src={port.route}
                                  alt=""
                                  className="talent-img"
                                />
                                    <div
                                      className="telent-img-delete"
                                      onClick={() =>
                                        _handleEditRemove('portfolio', i)
                                      }
                                    >
                                      <MdCancel />
                                    </div>
                                    </div>
                                    </>
                                  ))}


                            {/* {editPortfolio
                                  .sort((a, b) => a.position - b.position)
                                  .map((res, i) => (
                              <>

                              <div className="item-img ss"  >
                                <img
                                  src={res.route}
                                  alt=""
                                  className="talent-img"
                                />
                                <div
                                  className="telent-img-delete"
                                  onClick={() =>
                                    _handleEditRemove('portfolio', i)
                                  }
                                >
                                  <MdCancel />
                                </div>
                              </div>
                              </>
                            ))} */}
                          </div>
                        )}
                      </div>
                      <div className="mt-4 upload-wrapper">
                        <label
                          htmlFor="file"
                          className="uppercase italic px-3 py-1 addFile"
                        >
                          Add File(s) *
                        </label>
                        <input
                          id="file"
                          type="file"
                          accept="image/*"
                          multiple={true}
                          style={{ display: 'none' }}
                          onChange={(event) =>
                            _handleFileChange(event, 'portfolio')
                          }
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div
                        className="screen"
                        style={{
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          padding: 5,
                        }}
                      >
                        {state.polasPreview.length > 0 ? (
                          <div className="custom-row">
                            {state.polasPreview.map((res, i) => (
                              <div className="item-img" key={i}>
                                <img
                                  src={res}
                                  alt=""
                                  className="talent-img"
                                />
                                <div
                                  className="telent-img-delete"
                                  onClick={() => _handleRemove('polas', i)}
                                >
                                  <MdCancel />
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : editPolaroid.length === 0 ? (
                          'POLAROIDS'
                        ) : (
                          ''
                        )}
                        {editPolaroid.length > 0 && (
                          <div className="custom-row">
                            {editPolaroid.map((res, i) => (
                              <div className="item-img" key={i}
                              id={res.position}
                                        lang='polas'
                                        onDragOver={(ev) => ev.preventDefault()}
                                        onDragStart={handleDrag}
                                        onDrop={handleDropImage}
                              >
                                <img
                                  src={res.route}
                                  alt=""
                                  className="talent-img"
                                />
                                <div
                                  className="telent-img-delete"
                                  onClick={() =>
                                    _handleEditRemove('polas', i)
                                  }
                                >
                                  <MdCancel />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="mt-4 upload-wrapper">
                        <label
                          htmlFor="file"
                          className="uppercase italic px-3 py-1 addFile"
                        >
                          Add File(s) *
                        </label>
                        <input
                          id="file"
                          type="file"
                          accept="image/*"
                          multiple={true}
                          style={{ display: 'none' }}
                          onChange={(event) =>
                            _handleFileChange(event, 'polas')
                          }
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="screen">
                        <div
                          className="screen"
                          style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            padding: 5,
                          }}
                        >
                          {state.videoPreview.length > 0 ? (
                            <div className="row">
                              {state.videoPreview.map((res, i) => (
                                <div className="col-md-4" key={i}>
                                  <div className="item-img">
                                    <video
                                      className="talent-img"
                                      controls
                                    >
                                      <source src={res} />
                                    </video>
                                    <div
                                      className="telent-img-delete"
                                      onClick={() =>
                                        _handleRemove('videos', i)
                                      }
                                    >
                                      <MdCancel />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : editVideo.length === 0 ? (
                            'Videos'
                          ) : (
                            ''
                          )}
                          {editVideo.length > 0 && (
                            <div className="row">
                              {editVideo.map((res, i) => (
                                <div className="col-md-4" key={i}>
                                  <div className="item-img"
                                  draggable={true}
                                        id={i}
                                        lang='videos'
                                        onDragOver={(ev) => ev.preventDefault()}
                                        onDragStart={handleDrag}
                                        onDrop={handleDropImage}
                                        >
                                    <video className="talent-img"
                                      controls
                                    >
                                      <source src={res.route} />
                                    </video>
                                    <div
                                      className="telent-img-delete"
                                      onClick={() =>
                                        _handleEditRemove('videos', i)
                                      }
                                    >
                                      <MdCancel />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-4 upload-wrapper">
                        <label
                          htmlFor="file"
                          className="uppercase italic px-3 py-1 addFile"
                        >
                          Add File(s) *
                        </label>
                        <input
                          id="file"
                          type="file"
                          accept="video/*"
                          multiple={true}
                          style={{ display: 'none' }}
                          onChange={(event) =>
                            _handleFileChange(event, 'videos')
                          }
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>

                <textarea
                  className="screen"
                  name="bio"
                  required="required"
                  value={state.bio}
                  onChange={handleInput}
                  placeholder="CREATOR BIO"
                  style={{ fontSize: 15, width:'100%' }}
                />

                    </TabPanel>
                    <TabPanel>
                      <div className="screen">
                      {state.creatorPreview.length > 0 ? (
                          <div className="custom-row">
                            {state.creatorPreview.map((res, i) => (
                              <div className="item-img" key={i}>

                                {mime.lookup(state.creatorFile[i]['file']['name'])==='image/jpeg' || mime.lookup(state.creatorFile[i]['file']['name'])==='image/jpg' || mime.lookup(state.creatorFile[i]['file']['name'])==='image/png' ? (

                                <img src={res} alt="" className="talent-img" />
                              ) : ( <video className="talent-img"
                                controls
                              >
                                <source src={res} />
                              </video>  )}
                                <div
                                  className="telent-img-delete"
                                  onClick={() => _handleRemove('creator', i)}
                                >
                                  <MdCancel />
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : editCreatorwork.length === 0 ? (
                          'creator work'
                        ) : (
                          ''
                        )}

                        {editCreatorwork.length > 0 && (

                          <div className="custom-row">
                            {editCreatorwork.map((res, i) => (

                              <div className="item-img" key={i}

                              draggable={true}
                                        id={i}
                                        lang='creator'
                                        onDragOver={(ev) => ev.preventDefault()}
                                        onDragStart={handleDrag}
                                        onDrop={handleDropImage}
                              >

                               {mime.lookup(res.route)==='image/jpeg' || mime.lookup(res.route)==='image/jpg' || mime.lookup(res.route)==='image/png' ? (

                                <img src={res.route} alt="" className="talent-img" />
                              ) : ( <video className="talent-img"
                                controls
                              >
                                <source src={res.route} />
                              </video>  )}



                                <div
                                  className="telent-img-delete"
                                  onClick={() =>
                                    _handleEditRemove('creator', i)
                                  }
                                >
                                  <MdCancel />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="mt-4 upload-wrapper">
                        <label
                          htmlFor="file"
                          className="uppercase italic px-3 py-1 addFile"
                        >
                          Add File(s) *
                        </label>
                        <input
                          id="file"
                          type="file"
                          accept="image/*,video/*"
                          multiple={true}
                          style={{ display: 'none' }}
                          onChange={(event) =>
                            _handleFileChange(event, 'creator')
                          }
                        />
                      </div>
                    </TabPanel>
                  {/* <div className="flex justify-center mt-10">
                  <Link
                    to="/talents"
                    className="hover:no-underline"
                    style={{ color: "inherit" }}
                  >
                    Back
                  </Link>
                  <p className="ml-10" onClick={addTalent}>
                    Save
                  </p>
                </div> */}
                </div>
              </div>
                    <TabList className="flex justify-between w-3/4 mt-10 mx-auto container bottomtabs uppercase italic">
                      <Tab
                        className={`focus:outline-none  cursor-pointer custom-tab ${
                          tabIndex === 0 && 'tab-active'
                        }`}
                      >
                        Cover
                      </Tab>
                      <Tab
                        className={`focus:outline-none  cursor-pointer custom-tab ${
                          tabIndex === 1 && 'tab-active'
                        }`}
                      >
                        Portfolio
                      </Tab>
                      <Tab
                        className={`focus:outline-none  cursor-pointer custom-tab ${
                          tabIndex === 2 && 'tab-active'
                        }`}
                      >
                        POLAROIDS
                      </Tab>
                      <Tab
                        className={`focus:outline-none  cursor-pointer custom-tab ${
                          tabIndex === 3 && 'tab-active'
                        }`}
                      >
                        Videos
                      </Tab>
                      <Tab
                        className={`focus:outline-none  cursor-pointer custom-tab ${
                          tabIndex === 4 && 'tab-active'
                        }`}
                      >
                        Creator Bio
                      </Tab>
                      <Tab
                        className={`focus:outline-none  cursor-pointer custom-tab ${
                          tabIndex === 5 && 'tab-active'
                        }`}
                      >
                        Creator Work
                      </Tab>
                    </TabList>
                  </Tabs>
              <div
                className="flex justify-center mt-10"
                style={{ fontStyle: 'italic', textTransform: 'uppercase' }}
              >
                <Link
                  to="/talents"
                  className="hover:no-underline"
                  style={{ color: 'inherit' }}
                >
                  Back
                </Link>
                <p
                  className="ml-10" id="submit"
                  style={{ cursor: 'pointer' }}
                  onClick={_handleSubmit}
                >
                  Save
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </LoadingOverlay>
    <ToastContainer />
  </>
  )
}

export default CountryTalentPrivate

function Input(props) {
  return (
    <div className="input-wrapper">
      <label htmlFor="">{props.label}</label>
      <input
        type="text"
        name={props.name}
        value={props.value}
        onChange={props.handleInput}
      />
    </div>
  );
}