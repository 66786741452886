import React from 'react';
import '../css/slider.css';
export default function Image({ url, onClick, alt, index, landscape = false,small }) {
  return (
    small?<img
    src={url}
    className={landscape==='landscape' ? 'image w-auto2' : 'image slider-img2'}
    onClick={() => onClick(index)}
    // id={landscape ? 'limg' : ''}
    alt={alt}
  />:<img
  src={url}
  className={landscape==='landscape' ? 'image w-auto' : 'image slider-img'}
  onClick={() => onClick(index)}
  // id={landscape ? 'limg' : ''}
  alt={alt}
/>
  );
}
