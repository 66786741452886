import React from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import OfficeButtons from "../components/OfficeButtons";
import styles from "../css/privacy.scss";

function Terms() {
  return (
    <>
      <Navigation />
      <div className="privay_main">
        <div style={{ marginLeft: "40px", marginRight: "40px" }}>
          <h1 className="title">TERMS OF SERVICE</h1>
          <div
            style={{
              width: window?.innerWidth < 768 ? "65%" : "100%",
              marginLeft: window?.innerWidth < 768 ? "90px" : "0px",
            }}
          >
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                Welcome to SystemAgency’s website. This Terms of Service (“
                <strong>Terms</strong>”, “<strong>Terms of Service</strong>”)
                govern your use of&nbsp;our web pages located at
                <span style={{ background: "white" }}>&nbsp;www.</span>
              </span>
              <span style={{ fontSize: "15px" }}>
                systemagency.com{" "}
                <span style={{ color: "black" }}>
                  (“<strong>Service</strong>”, “<strong>we</strong>”, “
                  <strong>our</strong>”, “<strong>us</strong>”)! owned and
                  operated by&nbsp;STAR SYSTEM SA, CH-660.1.148.009-6 (“
                  <strong>Company</strong>”, “<strong>SystemAgency</strong>”, “
                  <strong>we</strong>”, “<strong>our</strong>”, “
                  <strong>us</strong>”).
                </span>
              </span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                Our Privacy Policy also governs your use of our Service and
                explains how we collect, safeguard and disclose information that
                results from your use of our web pages. Please read it here{" "}
                <span style={{ background: "white" }}>www.</span>
              </span>
              <span style={{ fontSize: "15px", color: "black" }}>
                systemagency.com/privacy-policy.
              </span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                We provide the Services through our Website. By accessing the
                Services through the Website, immediately and from your very
                first access of the Services, you unconditionally and
                irrevocably agree to be bound by these Terms and Conditions, all
                applicable laws and/or regulations and you agree that you are
                responsible for this compliance.&nbsp;
              </span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                Your agreement with us includes these Terms&nbsp;and our Privacy
                Policy&nbsp;(“<strong>Agreements</strong>”). You acknowledge
                that you have read and understood Agreements, and agree to be
                bound by them.&nbsp;
              </span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  If you do not agree with (or cannot comply with) Agreements,
                  then you may not use the Service. These Terms apply to all
                  visitors, users and others who wish to access or use Service.
                </span>
              </strong>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
              </strong>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                We reserve the right to change these Terms and Conditions. These
                changes are effective immediately upon posting. When changes
                come into effect, the revised Terms and Conditions shall
                supersede the previous version of the Terms and Conditions. You
                are responsible for regularly reviewing these Terms and
                Conditions.
              </span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                Thank you for being responsible.&nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;
              </span>
            </p>
            <ol style={{ listStyleType: "decimal", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    USE OF WEBSITE &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You may not use the Website to transmit, distribute, store
                      or destroy any material or information (i) in violation of
                      any applicable law or regulation; (ii) in a manner that
                      will infringe the copyright, trademark, trade secret or
                      other intellectual property rights of any third party or
                      violate the privacy or other personal rights of third
                      parties; or (iii) that is defamatory, obscene,
                      threatening, abusive or hateful.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      When accessing the Website, you shall be prohibited from:
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          using any robot, spider, other automatic device or
                          manual process to monitor or copy any part of the
                          Website;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          using any device, software or the like to interfere or
                          attempt to interfere with the proper functioning of
                          the Website;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          taking any action that imposes an unreasonable or
                          disproportionately large data load on the Website
                          infrastructure;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          copying, reproducing, altering, modifying, creating
                          derivative works, or publicly displaying any content
                          from the Website without our prior written consent;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          reverse assembling, reverse engineering or otherwise
                          attempting to discover any source code relating to the
                          Website or any tool therein, except to the extent that
                          such activity is expressly permitted by applicable law
                          notwithstanding this limitation; and
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          attempting to access any area of the Website to which
                          access is not authorised.
                        </span>
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",
                marginLeft: ".25in",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    ELIGIBILITY&nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You are at least 13 years of age.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You use the Website and Services according to these Terms
                      and all applicable laws and regulations determined by the
                      state and country of residence.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You agree and understand that we may, at any time, and
                      without prior notice, revoke and/or cancel your access if
                      you fail to meet these criteria or violate any portion of
                      these Terms.
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    GENERAL UNDERTAKING&nbsp; &nbsp; &nbsp;&nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      When you use our Service, you accept that you will not:
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                marginRight: "0in",
                marginLeft: ".5in",
                fontSize: "16px",
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                background: "white",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
              </strong>
            </p>
            <ol style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  breach any applicable laws or regulations that apply;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  breach any of the Terms;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  post or take any threatening, abusive, defamatory, obscene,
                  inappropriate action or post any indecent material, or
                  material or action that is invasive of another’s privacy
                  (including but not limited to other User and our employees);
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  share or otherwise upload or communicate any misleading or
                  false information or messages of any kind;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  use our Service to intentionally deceive other Users;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  use our Service to stalk or otherwise harass another User;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  use our Service if we have suspended or banned you from using
                  it.&nbsp;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  infringe the Intellectual Property Rights of any third-party;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  distribute any viruses or any other technologies that may harm
                  our Service or its Users
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  interfere with the property working of our Service
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  copy modify, or distribute any other person’s Content without
                  their consent use any robot, spider, scraper or other
                  automated means to access our Service and/or collect Content
                  or data for any purpose;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  collect information about other Users, including email
                  addresses, postal addresses, phone numbers, credit card or
                  banking information or similar information without their
                  explicit consent;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  copy, modify or distribute rights or Content or collect data
                  from our Service, applications or tools or otherwise infringe
                  our or User’s Intellectual Property Rights;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  bypass, disable or attempt to disable any security measures
                  used in connection with our Service;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  collect any data (including personal data) from our Service
                  other than in accordance with these Terms and applicable laws;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  sell any counterfeit Items or otherwise infringe the
                  copyright, trademark or other rights of third parties;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  write and post reviews that are anything other than true and
                  accurate to the best of your knowledge; or&nbsp;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  impersonate any person or misrepresent your affiliation with a
                  person or entity.
                </span>
              </li>
            </ol>
            <p
              style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "16px",
                margin: "0in",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    COMMUNICATIONS
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You agree to subscribe to newsletters, marketing or
                      promotional materials and other information we may
                      send.&nbsp;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You may opt out of receiving any, or all, of these
                      communications from us by emailing at{" "}
                      <span style={{ background: "white" }}>
                        alejandro@systemagency.com.
                      </span>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    ACCESS AND USE&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "", color: "black" }}>
                      You must only use the&nbsp;
                    </span>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Service&nbsp;in accordance with these Terms and any
                      applicable law.&nbsp;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "", color: "black" }}>
                      You must not (or attempt to):&nbsp;
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          &nbsp;Interfere with or disrupt the&nbsp;
                        </span>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Service&nbsp;or the server or networks that host the
                          Site;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          &nbsp;Use data mining, robots, screen scraping or
                          similar data gathering and extraction tools on the
                          Site; or&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          &nbsp;Interfere with security-related or other
                          features of the&nbsp;
                        </span>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Service.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span style={{ fontSize: "", color: "black" }}>
                      We do not warrant that the&nbsp;
                    </span>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Service&nbsp;will be available at all times and without
                      disruption and we provide no warranties in relation to the
                      content of any other website linked to or from
                      our&nbsp;Service.&nbsp;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "", color: "black" }}>
                      You must not link to our&nbsp;
                    </span>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Service&nbsp;or any part of our&nbsp;Service&nbsp;in a way
                      that damages or takes advantage of our reputation,
                      including but not limited to:&nbsp;
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          &nbsp;In a way to suggest or imply that you have any
                          kind of association and affiliation with us, or
                          approval and endorsement from us when there is none;
                          or&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          In a way that is illegal or unfair. &nbsp;&nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp;
                        </span>
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    PROHIBITED USES &nbsp; &nbsp; &nbsp; &nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You may use Service only for lawful purposes and in
                      accordance with Terms.&nbsp;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You agree not to use Service:
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          In any way that violates any applicable national or
                          international law or regulation.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          For the purpose of exploiting, harming, or attempting
                          to exploit or harm minors in any way by exposing them
                          to inappropriate content or otherwise.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          To transmit, or procure the sending of, any
                          advertising or promotional material, including any
                          “junk mail”, “chain letter,” “spam,” or any other
                          similar solicitation.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          To impersonate or attempt to impersonate us, our
                          employee, another user, or any other person or entity.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          In any way that infringes upon the rights of others,
                          or in any way is illegal, threatening, fraudulent, or
                          harmful, or in connection with any unlawful, illegal,
                          fraudulent, or harmful purpose or activity.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          To engage in any other conduct that restricts or
                          inhibits anyone’s use or enjoyment of Service, or
                          which, as determined by us, may harm or offend the us
                          or users of Service or expose them to liability.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Additionally, you agree not to:
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Use Service in any manner that could disable,
                          overburden, damage, or impair Service or interfere
                          with any other party’s use of Service, including their
                          ability to engage in real time activities through
                          Service.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Use any robot, spider, or other automatic device,
                          process, or means to access Service for any purpose,
                          including monitoring or copying any of the material on
                          Service.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Use any manual process to monitor or copy any of the
                          material on Service or for any other unauthorized
                          purpose without our prior written consent.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Use any device, software, or routine that interferes
                          with the proper working of Service.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Introduce any viruses, trojan horses, worms, logic
                          bombs, or other material which is malicious or
                          technologically harmful.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Attempt to gain unauthorized access to, interfere
                          with, damage, or disrupt any parts of Service, the
                          server on which Service is stored, or any server,
                          computer, or database connected to Service.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Attack Service via a denial-of-service attack or a
                          distributed denial-of-service attack.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Take any action that may damage or falsify our rating.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Otherwise attempt to interfere with the proper working
                          of Service.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        </span>
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    SAFETY
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      User will not (nor assist others to) violate any
                      applicable law, contract, intellectual property, or other
                      third-party right, and User is solely responsible for its
                      conduct while using our Services.&nbsp;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      User must not directly, indirectly, or through automated
                      or other means:&nbsp;
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          engage in any harassing, threatening, intimidating,
                          predatory, or stalking conduct; &nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          impersonate or register on behalf of any person or
                          entity or otherwise misrepresent your affiliation with
                          a person or entity, perpetrate fraud, or publish
                          falsehoods or misleading statements;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          collect information of or about other users in any
                          impermissible or unauthorized manner;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          use our Services other than for their intended purpose
                          or interfere with, disrupt, negatively affect, or
                          inhibit other users;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          damage, disable, overburden, or impair our
                          Services;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          send, distribute, or post spam, unsolicited electronic
                          communications, chain letters, pyramid schemes, or
                          illegal or impermissible communications;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          post, upload, or share any content which is unlawful,
                          libelous, defamatory, obscene, pornographic, indecent,
                          lewd, suggestive, harassing, threatening, invasive of
                          privacy or publicity rights, abusive, inflammatory,
                          fraudulent, or is in our sole judgment objectionable;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          encourage or provide instructions for a criminal
                          offense;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          distribute any viruses, corrupted data, or other
                          harmful, disruptive, or destructive files or
                          content;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          bypass, ignore, or circumvent instructions in our
                          robots.txt file or any measures we employ to prevent
                          or limit access to any part of our Services, including
                          content-filtering techniques; or&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          expose us or others to any type of harm or liability.
                        </span>
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ color: "black" }}>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp;&nbsp;
              </span>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    INTELLECTUAL PROPERTY &nbsp;&nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Service and its original content (excluding Content
                      provided by users), features and functionality are and
                      will remain the exclusive property of SystemAgency and its
                      licensors.&nbsp;
                    </span>
                    <ul style={{ listStyleType: "disc" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Service is protected by copyright, trademark, and
                          other laws of{" "}
                          <span style={{ background: "white" }}>
                            Switzerland.
                          </span>
                          &nbsp;
                        </span>
                        <ul style={{ listStyleType: "disc" }}>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              Our trademarks and trade dress may not be used in
                              connection with any product or service without the
                              prior written consent of&nbsp;SystemAgency.
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              All code, text, software, scripts, graphics,
                              files, photos, images, logos, and materials
                              contained on this website, our applications, or
                              within the services, are the sole property
                              of&nbsp;SystemAgency.
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              Unauthorized use of any materials contained on
                              this Website, our applications or within the
                              Service may violate copyright laws, trademark
                              laws, the laws of privacy and publicity, and/or
                              other regulations and statutes. If you believe
                              that any of the materials infringe on any third
                              party's rights, please contact SystemAgency
                              immediately at:{" "}
                              <span style={{ background: "white" }}>
                                alejandro@systemagency.com.
                              </span>
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              All content, trademarks, data, information or
                              information contained in any materials, or
                              documents used in relation to the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              &nbsp;and the Services, including but not limited
                              to any and all copyrighted works, software,
                              databases, text, graphics, icons, designs, logos,
                              graphics, hyperlinks, domain names, information
                              and agreements (“<strong>Content</strong>”), are
                              the exclusive property of or are licensed to
                              the&nbsp;
                            </span>
                            <span style={{ fontSize: "", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              .&nbsp;
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              You may not reproduce, distribute or copy the
                              Content by any means, whether electronically or
                              not, without the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "", color: "black" }}>
                              ’s prior written permission.
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              Any and all intellectual property rights in the
                              Content, Services and the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              &nbsp;or otherwise developed by or on behalf of
                              the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              , including all patents, rights in inventions,
                              rights in designs, trademarks, trade and business
                              names and all associated goodwill, rights to sue
                              for passing off or for unlawful competition,
                              copyright, moral rights and related rights, rights
                              in databases, topography rights, domain names,
                              rights in information (including know how and
                              trade secrets) and all other similar or equivalent
                              rights existing in the Content, Services and
                              the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              &nbsp;or otherwise developed by or on behalf of
                              the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              , now or in the future in any part of the world,
                              in each case whether registered or unregistered
                              and including all applications for, and renewals
                              or extensions of, such rights for their full term
                              (“<strong>Intellectual Property</strong>”), vests
                              in the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              , its licensors or suppliers, as the case may be,
                              and all rights not expressly granted by the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              &nbsp;to you are reserved by the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              .
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              Save as expressly set out herein and in this
                              Agreement, you shall not acquire any right, title
                              or interest in the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              ’s Intellectual Property.&nbsp;
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              You may not use the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              ’s Intellectual Property in a manner which may (i)
                              place the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              ’s Intellectual Property at risk of losing value,
                              and (ii) cause reputational damage to the&nbsp;
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              SystemAgency
                            </span>
                            <span style={{ fontSize: "", color: "black" }}>
                              .
                            </span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    COPYRIGHT POLICY&nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      We respect the intellectual property rights of others. It
                      is our policy to respond to any claim that Content posted
                      on Service infringes on the copyright or other
                      intellectual property rights (“Infringement”) of any
                      person or entity.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      If you are a copyright owner, or authorized on behalf of
                      one, and you believe that the copyrighted work has been
                      copied in a way that constitutes copyright infringement,
                      please submit your claim via email to{" "}
                      <span style={{ background: "white" }}>
                        alejandro@systemagency.com
                      </span>{" "}
                      with the subject line: “Copyright Infringement” and
                      include in your claim a detailed description of the
                      alleged Infringement as detailed below, under “DMCA Notice
                      and Procedure for Copyright Infringement Claims”
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You may be held accountable for damages (including costs
                      and attorneys’ fees) for misrepresentation or bad-faith
                      claims on the infringement of any Content found on and/or
                      through Service on your copyright.&nbsp; &nbsp; &nbsp;
                      <br />
                      &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    &nbsp;DMCA NOTICE AND PROCEDURE FOR COPYRIGHT INFRINGEMENT
                    CLAIMS&nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You may submit a notification pursuant to the Digital
                      Millennium Copyright Act (DMCA) by providing our support
                      team with the following information in writing (see 17
                      U.S.C 512(c)(3) for further detail):
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          an electronic or physical signature of the person
                          authorized to act on behalf of the owner of the
                          copyright’s interest;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          a description of the copyrighted work that you claim
                          has been infringed, including the URL (i.e., web page
                          address) of the location where the copyrighted work
                          exists or a copy of the copyrighted work;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          identification of the URL or other specific location
                          on Service where the material that you claim is
                          infringing is located;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          your address, telephone number, and email address;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          a statement by you that you have a good faith belief
                          that the disputed use is not authorized by the
                          copyright owner, its agent, or the law;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          a statement by you, made under penalty of perjury,
                          that the above information in your notice is accurate
                          and that you are the copyright owner or authorized to
                          act on the copyright owner’s behalf.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          You can contact our support team via email at{" "}
                          <span style={{ background: "white" }}>
                            alejandro@systemagency.com
                          </span>
                          &nbsp; &nbsp; &nbsp;&nbsp;
                        </span>
                        <span
                          style={{
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          <br />
                          &nbsp;
                          <br />
                          &nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          You may provide us either directly at{" "}
                          <span style={{ background: "white" }}>
                            alejandro@systemagency.com
                          </span>{" "}
                          or via third party sites and tools with information
                          and feedback concerning errors, suggestions for
                          improvements, ideas, problems, complaints, and other
                          matters related to our Service (“Feedback”).&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          You acknowledge and agree that: (i) you shall not
                          retain, acquire or assert any intellectual property
                          right or other right, title or interest in or to the
                          Feedback; (ii) the SystemAgency may have development
                          ideas similar to the Feedback; (iii) Feedback does not
                          contain confidential information or proprietary
                          information from you or any third party; and (iv) the
                          SystemAgency is not under any obligation of
                          confidentiality with respect to the Feedback.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          In the event the transfer of the ownership to the
                          Feedback is not possible due to applicable mandatory
                          laws, you grant the SystemAgency and its affiliates an
                          exclusive, transferable, irrevocable, free-of-charge,
                          sub-licensable, unlimited and perpetual right to use
                          (including copy, modify, create derivative works,
                          publish, distribute and commercialize) Feedback in any
                          manner and for any purpose.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          We shall not be liable for any typo or any information
                          being incorrect in this Agreement, in case you have
                          discovered such type or mistake in wording of this
                          Agreement you may inform us about such mistake
                          directly at{" "}
                          <span style={{ background: "white" }}>
                            alejandro@systemagency.com.&nbsp;
                          </span>
                        </span>
                      </li>
                    </ol>
                    <ul style={{ listStyleType: "disc" }} />
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;
              </span>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    LINKS TO OTHER WEB SITES &nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Our Service may contain links to third party web sites or
                      services that are not owned or controlled
                      by&nbsp;SystemAgency.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      SystemAgency has no control over, and assumes no
                      responsibility for the content, privacy policies, or
                      practices of any third-party web sites or services. We do
                      not warrant the offerings of any of these
                      entities/individuals or their websites.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      YOU ACKNOWLEDGE AND AGREE THAT WE&nbsp;SHALL NOT BE
                      RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
                      DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN
                      CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
                      GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH
                      THIRD-PARTY WEB SITES OR SERVICES.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
                      PRIVACY POLICIES OF ANY THIRD-PARTY WEB SITES OR SERVICES
                      THAT YOU VISIT.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    ANALYTICS
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      We may use third-party Service Providers to monitor and
                      analyze the use of our Service.
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <strong>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "black",
                              marginLeft: "20px",
                            }}
                          >
                            Google Analytics.&nbsp;
                          </span>
                        </strong>
                        <ol style={{ listStyleType: "circle" }}>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              Google Analytics is a web analytics service
                              offered by Google that tracks and reports website
                              traffic. Google uses the data collected to track
                              and monitor the use of our Service. This data is
                              shared with other Google services. Google may use
                              the collected data to contextualize and
                              personalize the ads of its own advertising
                              network.
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              For more information on the privacy practices of
                              Google, please visit the Google Privacy Terms web
                              page: https://policies.google.com/privacy?hl=en
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              We also encourage you to review the Google's
                              policy for safeguarding your data:&nbsp;
                            </span>
                            <span style={{ color: "black" }}>
                              <a href="https://support.google.com/analytics/answer/6004245">
                                <span style={{ fontSize: "15px" }}>
                                  https://support.google.com/analytics/answer/6004245
                                </span>
                              </a>
                            </span>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              .
                            </span>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",
                marginLeft: ".25in",
                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    DISCLAIMER OF WARRANTY &nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      THESE SERVICES ARE PROVIDED BY THE COMPANY ON AN “AS IS”
                      AND “AS AVAILABLE” BASIS. THE COMPANY MAKES NO
                      REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
                      IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
                      INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
                      EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR
                      CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT
                      YOUR SOLE RISK.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE
                      COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
                      TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
                      ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
                      LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
                      ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT
                      THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS
                      OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE,
                      ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                      CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT
                      AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
                      OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED
                      THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
                      EXPECTATIONS.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                      WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
                      INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                      MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
                      PARTICULAR PURPOSE.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT
                      BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.&nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    LIMITATION OF LIABILITY &nbsp; &nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                      OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR
                      ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR
                      CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING
                      ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF
                      LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF
                      ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS
                      INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE,
                      OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
                      CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT
                      LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY
                      DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY
                      YOU OF ANY LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF
                      THE COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY
                      OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
                      LIABILITY FOUND ON THE PART OF THE COMPANY, IT WILL BE
                      LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
                      SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
                      CONSEQUENTIAL OR PUNITIVE DAMAGES.&nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    GOVERNING LAW&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      These Terms shall be governed and construed in accordance
                      with the laws of{" "}
                      <span style={{ background: "white" }}>
                        Switzerland&nbsp;
                      </span>
                      without regard to its conflict of law provisions.
                    </span>
                    <ul style={{ listStyleType: "disc" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Our failure to enforce any right or provision of these
                          Terms will not be considered a waiver of those
                          rights.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          If any provision of these Terms is held to be invalid
                          or unenforceable by a court, the remaining provisions
                          of these Terms will remain in effect.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          These Terms constitute the entire agreement between us
                          regarding our Service and supersede and replace any
                          prior agreements we might have had between us
                          regarding Service.
                        </span>
                        <ul style={{ listStyleType: "disc" }}>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              Each party irrevocably agrees that the courts of{" "}
                              <span style={{ background: "white" }}>
                                Switzerland
                              </span>{" "}
                              shall have exclusive jurisdiction to settle any
                              dispute or claim arising out of or in connection
                              with this agreement or its subject matter or
                              formation (including non-contractual disputes or
                              claims).
                            </span>
                            <ul style={{ listStyleType: "disc" }}>
                              <li>
                                <span
                                  style={{ fontSize: "15px", color: "black" }}
                                >
                                  Notwithstanding the specified agreement on
                                  jurisdiction, you and the SystemAgency shall,
                                  if any dispute arises, attempt to settle it by
                                  mutual negotiations.&nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp;&nbsp;
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              We reserve the right to withdraw or amend our
                              Service, and any service or material we provide
                              via Service, in our sole discretion without
                              notice.&nbsp;
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              We will not be liable if for any reason all or any
                              part of Service is unavailable at any time or for
                              any period.&nbsp;
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              From time to time, we may restrict access to some
                              parts of Service, or the entire Service, to users,
                              including registered users.&nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp;&nbsp;
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              We may amend Terms at any time by posting the
                              amended terms on this site. It is your
                              responsibility to review these Terms periodically.
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              Your continued use of the Platform following the
                              posting of revised Terms means that you accept and
                              agree to the changes.&nbsp;
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              You are expected to check this page frequently so
                              you are aware of any changes, as they are binding
                              on you.
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              By continuing to access or use our Service after
                              any revisions become effective, you agree to be
                              bound by the revised terms.&nbsp;
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              If you do not agree to the new terms, you are no
                              longer authorized to use Service.&nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp;&nbsp;
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              You agree to indemnify, defend and hold harmless
                              us and our related entities, affiliates, and our
                              and their respective officers, agents, and
                              employees, harmless from and against any and all
                              claims demands (actual, special and consequential)
                              of every kind and nature, known and unknown,
                              including reasonable legal fees, made by any third
                              party due to or arising out of your breach of
                              these terms or your breach of any law or the right
                              of third party.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp;
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              No waiver by the SystemAgency of any term or
                              condition set forth in Terms shall be deemed a
                              further or continuing waiver of such term or
                              condition or a waiver of any other term or
                              condition, and any failure of the SystemAgency to
                              assert a right or provision under Terms shall not
                              constitute a waiver of such right or provision.
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "15px", color: "black" }}>
                              If any provision of Terms is held by a court or
                              other tribunal of competent jurisdiction to be
                              invalid, illegal or unenforceable for any reason,
                              such provision shall be eliminated or limited to
                              the minimum extent such that the remaining
                              provisions of Terms will continue in full force
                              and effect.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp;&nbsp;
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                          ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE
                          AND AGREE TO BE BOUND BY THEM.&nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      For more information about our Term and Conditions, if you
                      have questions, or if you would like to make a complaint,
                      please send your feedback, comments, requests for
                      technical support: by email:{" "}
                      <span style={{ background: "white" }}>
                        alejandro@systemagency.com.
                      </span>
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
          </div>
          <div className="footer-zone" style={{}}>
            <OfficeButtons />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;
