import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
  useHistory,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import About from "../src/pages/About.jsx";
import Office from "../src/pages/Office.jsx";
import LoginPage from "./pages/LoginPage.jsx";
import Talents from "./pages/Talents.jsx";
import Flash from "./components/Flash";
import Representations from "../src/pages/Representations.jsx";
import Detail from "../src/pages/Detail";
import Pdfview from "../src/pages/pdfview";
import ViewPackage from "./pages/ViewPackage.jsx";
import Work from "./pages/Work";
import Home from "./pages/Home.jsx";
import TalentPrivate from "./pages/TalentPrivate.jsx";
import "react-circular-progressbar/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import AddOffice from "./pages/AddOffice";
import CountryTalent from "./pages/CountryTalent";
import CountryTalentPrivate from "./pages/CountryTalentPrivate";
import CountryDetail from "./pages/CountryDetail";
import Privacy from "../src/pages/Privacy";
import Terms from "../src/pages/Terms";

const Index = () => {
  const [loading, setLoading] = useState(true);
  const loadToken = () => {
    let accessToken = sessionStorage.getItem("token");

    if (accessToken !== null) {
      localStorage.setItem("token", accessToken);
    } else {
      localStorage.removeItem("token");
    }
    setLoading(false);
  };
  useEffect(() => {
    loadToken();
  });

  let history = useHistory();

  const goToPreviousPath = () => {
    history.goBack();
  };
  if (loading) {
    return null;
  } else
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/About" component={About} />
            <Route path="/privacyPolicy" component={Privacy} />
            <Route path="/termsofService" component={Terms} />
            <Route path="/Office" component={Office} />
            <Route path="/login" component={LoginPage} />
            <Route path="/home" component={Home} />
            <Route exact path="/talents" component={Talents} />
            <Route path="/flash" component={Flash} />
            <Route path="/representations" component={Representations} />
            <Route exact path="/talents/:slug" component={Detail} />
            <Route
              exact
              path="/country/:country/:slug"
              component={CountryDetail}
            />
            <Route exact path="/pdf/:slug" component={Pdfview} />
            <Route exact path="/work" component={Work} />
            <Route
              exact
              path="/viewpackage/:selectedImages"
              component={ViewPackage}
            />
            <Route exact path="/home" component={Home} />
            <Route exact path="/addTalent" component={TalentPrivate} />
            <Route exact path="/editTalent/:slug" component={TalentPrivate} />
            <Route
              exact
              path="/AddTalent/:country"
              component={CountryTalentPrivate}
            />
            <Route
              exact
              path="/EditTalent/:slug/:country"
              component={CountryTalentPrivate}
            />
            <Route exact path="/addoffice" component={AddOffice}></Route>
            <Route exact path="/addoffice/:slug" component={AddOffice}></Route>
            <Route exact path="/:country" component={CountryTalent}></Route>
          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </>
    );
};

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
