import React, { Component } from "react";
import Slider from "react-slick";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Config from "../config";
import "../css/Carousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  EffectCoverflow,
  Autoplay,
} from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Img } from "react-image-loading";
import Marquee from "react-fast-marquee";

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

export default class SimpleSlider extends Component {
  state = {
    isAdmin: false,
    isEdit: false,
  };

  deleteTalent = (id) => {
    let token = localStorage.getItem("token");
    let data = {
      id_work: id,
    };
    axios
      .delete(`${Config.URL}work`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data,
      })
      .then(() => {
        toast.success("Deleted Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  deleteTalent = (id) => {
    let token = localStorage.getItem("token");
    let data = {
      id_work: id,
    };
    axios
      .delete(`${Config.URL}work`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data,
      })
      .then(() => {
        toast.success("Deleted Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  singalDelete = (id, index) => {
    let token = localStorage.getItem("token");
    var r = window.confirm("Delete image from carousel!");
    if (r === true) {
      let arr = [...this.props.items?.resources];
      arr.splice(index, 1);
      let data = {
        resources: arr,
        component: "timeline",
        sub_component: "carousel",
        id_home: id,
      };
      axios
        .post(`${Config.URL}home/update`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          toast.success("Deleted Successfully!");
          window.location.reload(true);
        })
        .catch((error) => {
          // toast.success(error);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.setState({
        isAdmin: true,
      });
    }
  }
  render() {
    let { items, parent, setItem, addItem, setIndex, end } = this.props;
    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      variableWidth: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",

      className: "slides",
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    if (parent == "home") {
      items?.resources.sort((a, b) => (a.route > b.route ? 1 : -1));
    }

    return (
      <>
        <div
          className={`carousel d-flex`}
          style={{ maxHeight: `${parent === "home" ? 400 : "100%"}` }}
        >
          {parent === "home" ? (
            <div>
              <div style={{ marginTop: "50px" }}>
              <Swiper
                spaceBetween={0}
                  breakpoints={{
                    200: {
                      // width: 576,
                      slidesPerView: 2.39,
                    },
                    768: {
                      // width: 768,
                      slidesPerView: 5,
                    },
                    1024: {
                      slidesPerView: 7,
                    },
                    1366: {
                      slidesPerView:8.09,
                    },
                  }}
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                    pauseOnMouseEnter:true,
                     pauseOnClick:true
                  }}
                  draggable={true}
                  speed={2000}
                  loop={true}
                >
                  {items?.resources.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <div>
                        <div className="img-wrapper">
                          <div className="info">
                            {this.state.isEdit ? (
                              <div
                                className="new-left-panel"
                                style={{
                                  position: "initial",
                                  width: "100%",
                                  marginTop: 10,
                                }}
                              >
                                <button
                                  className="view-package"
                                  style={{
                                    width: 60,
                                    cursor: "pointer",
                                    padding: "0 !important",
                                    paddingRight: 5,
                                  }}
                                  href="#upload-feature"
                                  data-toggle="modal"
                                  onClick={() => {
                                    setItem(items);
                                    addItem(false);
                                    setIndex(index);
                                  }}
                                >
                                  EDIT
                                </button>
                                <button
                                  className="view-package"
                                  style={{
                                    width: 60,
                                    cursor: "pointer",
                                    padding: "0 !important",
                                    paddingRight: 5,
                                  }}
                                  // href='#add-work'
                                  // data-toggle='modal'
                                  onClick={() =>
                                    this.singalDelete(items.id_home, index)
                                  }
                                >
                                  DELETE
                                </button>
                              </div>
                            ) : (
                              <>
                                <h5 className="name">
                                  <span className="uppercase">
                                    {item.meta.talent_name}
                                  </span>
                                </h5>
                                <p
                                  className="capitalize"
                                  style={{ marginBottom: 0 }}
                                >
                                  {item?.meta?.client_name}
                                </p>

                                <p>
                                  {item?.meta?.credits &&
                                    item?.meta?.credits.map((list) => (
                                      <>
                                        {list.title}: {list.text}
                                        <br />
                                      </>
                                    ))}
                                </p>
                              </>
                            )}
                          </div>
                          <Img src={item.route} alt="alter" />
                        </div>
                      </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
              {this.state.isAdmin && (
                <div>
                  <div
                    className="new-left-panel"
                    style={{ position: "initial", paddingTop: 8 }}
                  >
                    <button
                      className="view-package"
                      // href='#add-work'
                      // data-toggle='modal'
                      // style={{ paddingRight: 64 }}
                      onClick={() =>
                        this.setState({
                          isEdit: !this.state.isEdit,
                        })
                      }
                    >
                      {this.state.isEdit ? "BACK" : "EDIT CAROUSEL"}
                    </button>
                    <button
                      className="view-package"
                      href="#upload-feature"
                      data-toggle="modal"
                      // style={{ paddingRight: 64 }}
                      onClick={() => {
                        addItem(true);
                        setItem(items);
                        setIndex(null);
                      }}
                    >
                      ADD TO CAROUSEL
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="rows">
              <div style={{ marginTop: "20px" }}>
                <Swiper
                spaceBetween={0}
                  breakpoints={{
                    200: {
                      // width: 576,
                      slidesPerView: 2,
                    },
                    768: {
                      // width: 768,
                      slidesPerView: 5,
                    },
                    1024: {
                      slidesPerView: 7,
                    },
                    1366: {
                      slidesPerView:7,
                    },
                  }}
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                    pauseOnMouseEnter:true,
                     pauseOnClick:true
                  }}
                  draggable={true}
                  speed={2000}
                  loop={true}
                >
                  {items.slice(0, end).map((item, index) =>
                    item.type === "image/jpeg" ? (
                      <SwiperSlide>
                        <div className="img-wrapper" key={index}>
                          <div className="info">
                            {this.state.isEdit ? (
                              <div
                                className="new-left-panel"
                                style={{
                                  position: "initial",
                                  width: "100%",
                                  marginTop: 10,
                                }}
                              >
                                <button
                                  className="view-package"
                                  style={{
                                    width: 50,
                                    cursor: "pointer",
                                    padding: "0 !important",
                                    paddingRight: 5,
                                  }}
                                  href="#add-work"
                                  data-toggle="modal"
                                  onClick={() => setItem(item)}
                                >
                                  EDIT
                                </button>
                                <button
                                  className="view-package"
                                  style={{
                                    width: 50,
                                    cursor: "pointer",
                                    padding: "0 !important",
                                    paddingRight: 5,
                                  }}
                                  // href='#add-work'
                                  // data-toggle='modal'
                                  onClick={() => this.deleteTalent(item.id)}
                                >
                                  DELETE
                                </button>
                              </div>
                            ) : (
                              <>
                                <h5 className="name">
                                  <span className="uppercase">
                                    {item.talentName}
                                  </span>
                                </h5>
                                <p
                                  className="capitalize"
                                  style={{ marginBottom: 0 }}
                                >
                                  {item.clientName}
                                </p>

                                <p>
                                  {item.credits.map((list) => (
                                    <>
                                      {list.title}: {list.text}
                                      <br />
                                    </>
                                  ))}
                                </p>
                              </>
                            )}
                          </div>
                          <Img src={item.img} alt="alter" />
                        </div>
                      </SwiperSlide>
                    ) : (
                      ""
                    )
                  )}
                </Swiper>
              </div>
              {items.length > 0 && this.state.isAdmin && (
                <>
                  <div
                    className="new-left-panel editcarousel"
                    style={{ position: "initial", paddingTop: 8 }}
                  >
                    <button
                      className="view-package"
                      // href='#add-work'
                      // data-toggle='modal'
                      style={{ paddingRight: 64 }}
                      onClick={() =>
                        this.setState({
                          isEdit: !this.state.isEdit,
                        })
                      }
                    >
                      {this.state.isEdit ? "BACK" : "EDIT CAROUSEL"}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <ToastContainer />
      </>
    );
  }
}
