import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation.jsx";
import Footer from "../components/Footer.jsx";
import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

class CountryTalent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      hidden: true,
      activeIndex: "",
      selectedImages: [],
      isActiveWomen: false,
      isActiveMen: false,
      isNonbinary: false,
      iscreator: false,
      currentActive: "",
      isAdmin: false,
      category: "",
      gender: "",
      sharetalent: "",
      talentname: "",
      loader: true,
      email: "",
      message: "",
      from_name: "",
      from_email: "",
      submit: "",
      counter: 0,
    };
    this.loadimages = this.loadimages.bind(this);
    this.genderImages = this.genderImages.bind(this);
  }

  componentDidMount() {
    this.loadimages();
    let selection = localStorage.getItem("selectedImages");
    let filtered = selection?.split(",");
    console.log(selection, filtered, "this");
    this.setState({
      selectedImages: filtered ? filtered : [],
    });
    // this.deletselectAll();
    if (localStorage.getItem("token")) {
      //this.props.history.push('/');
      this.setState({ isAdmin: true });
    }
  }

  componentWillUnmount() {
    if (localStorage.getItem("token")) {
      //this.props.history.push('/');
      this.setState({ isAdmin: true });
    } else {
      this.setState({ isAdmin: false });
    }
  }

  // 	if (window.location.href.includes('logged_out')) {
  // 		this.setState({ loggedOut: true });
  // 	}
  // }

  loadimages = () => {
    let selection = localStorage.getItem("selectedImages");
    let url;
    if (window.location.pathname.split("/")[1] === "latvia") {
      url = `${config.URL}talent/country?country=sosa`;
    } else if (window.location.pathname.split("/")[1] === "baltics") {
      url = `${config.URL}talent/country?country=latvia`;
      console.log("yes");
    } else {
      url = `${config.URL}talent/country?country=${
        window.location.pathname.split("/")[1]
      }`;
      console.log("no");
    }
    axios.get(url).then((response) => {
      let images = response.data.item.rows;
      images.map((image) => {
        if (selection?.includes(image.slug)) {
          image.is_requested = true;
        } else {
          image.is_requested = false;
        }
      });
      console.log(images);
      images.map((iage) => {
        if (iage.gender === "nonbinary") {
          this.setState({
            counter: 1,
          });
        }
      });

      //save images variable in state
      this.setState({
        images,
        gender: "all",
        loader: false,
      });
    });
  };

  genderImages = (gender) => {
    let url;
    if (gender == "") {
      if (window.location.pathname.split("/")[1] === "baltics") {
        url = `${config.URL}talent/country?country=latvia`;
      } else if (window.location.pathname.split("/")[1] === "latvia") {
        url = `${config.URL}talent/country?country=sosa`;
      } else {
        url = `${config.URL}talent/country?country=${
          window.location.pathname.split("/")[1]
        }`;
      }
    } else {
      if (window.location.pathname.split("/")[1] === "baltics") {
        url = `${config.URL}talent/country/section?gender=${gender}&country=latvia`;
      } else if (window.location.pathname.split("/")[1] === "latvia") {
        url = `${config.URL}talent/country/section?gender=${gender}&country=sosa`;
      } else {
        url = `${config.URL}talent/country/section?gender=${gender}&country=${
          window.location.pathname.split("/")[1]
        }`;
      }
    }

    axios.get(url).then((response) => {
      if (gender == "") {
        let items = [];
        response.data.item.rows.map((res, i) => {
          if (res.creator == true) {
            items[i] = res;
          }
        });
        items.map((it) => {
          if (it.gender === "nonbinary") {
            this.setState({
              counter: 1,
            });
          }
        });

        this.setState({
          images: items,
          iscreator: true,
        });
      } else {
        this.setState({
          images: response.data.item.rows,
          isActiveWomen: gender === "women" ? true : false,
          isActiveMen: gender === "men" ? true : false,
          isNonbinary: gender === "nonbinary" ? true : false,
          iscreator: false,
        });
      }
    });
  };

  creatorImages = (gender, category) => {
    let url = `${config.URL}talent/section?creator=yes&country=${
      window.location.pathname.split("/")[1]
    }`;
    if (window.location.pathname.split("/")[1] === "baltics") {
      url = `${config.URL}talent/section?creator=yes&country=latvia`;
    } else if (window.location.pathname.split("/")[1] === "latvia") {
      url = `${config.URL}talent/section?creator=yes&country=sosa`;
    }
    axios.get(url).then((response) => {
      this.setState({
        images: response.data.item.rows,
        isActiveWomen: gender === "women" ? true : false,
        isActiveMen: gender === "men" ? true : false,
        isNonbinary: gender === "nonbinary" ? true : false,
        currentActive: gender + " " + category,
        iscreator: true,
      });
    });
  };

  categoryImages = (gender, category) => {
    let url = `${
      config.URL
    }talent/section?gender=${gender}&category=${category}&country=${
      window.location.pathname.split("/")[1]
    }`;
    if (window.location.pathname.split("/")[1] === "baltics") {
      url = `${config.URL}talent/section?gender=${gender}&category=${category}&country=latvia`;
    }
    else if(window.location.pathname.split("/")[1] === "latvia") {
      
    }
    axios.get(url).then((response) => {
      this.setState({
        images: response.data.item.rows,
        isActiveWomen: gender === "women" ? true : false,
        isActiveMen: gender === "men" ? true : false,
        isNonbinary: gender === "nonbinary" ? true : false,
        currentActive: gender + " " + category,
        iscreator: false,
      });
    });
  };

  requestImage = (index, slugs) => {
    let images = this.state.images;
    let image = images[index];
    image.is_requested = true;
    images[index] = image;
    console.log(images, this.state.selectedImages, "this");
    this.setState({ ...this.state, images: images });
    this.state.selectedImages.push(slugs);
    localStorage.setItem("selectedImages", this.state.selectedImages);
    // localStorage.setItem('selectedImages', JSON.stringify(image));
  };

  sharetalentimg = (index, talentslug, fname, lnam) => {
    this.setState({
      sharetalent: talentslug,
      talentname: fname + " " + lnam,
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  formsubmit = () => {
    var rowContents =
      "<p><b>Talent Name: </b><a href='https://system-agency.web.app" +
      this.state.sharetalent +
      "'>" +
      this.state.talentname +
      "</a></p><p><b>Message: </b>" +
      this.state.message +
      "</p>";

    // formData.append('message',rowContents);

    const axios = require("axios");
    const formData = {
      toemail: this.state.email,
      from_name: this.state.from_name,
      type: "talent_share",
      message: rowContents,
    };
    const vm = this;

    // formData.append('toemail', this.state.email);
    // formData.append('fromemail', 'info@artisticbird.com');
    // formData.append('from_name', this.state.from_name);
    // formData.append('subject', 'System agecny Talent');
    // formData.append('from_email', this.state.from_email);

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${config.URL}sendmail`, formData, {
        headers,
      })
      .then(function (response) {
        if (response.data.status == "Success") {
        }
      });
    this.setState({ email: "", message: "", from_name: "" }, () => {});
  };

  deleteTalent = (index) => {
    let talents = this.state.images;
    let talentId = talents[index].slug;
    const formdata = new FormData();
    formdata.append("slug", talentId);
    const token = localStorage.getItem("token");

    axios
      .delete(`${config.URL}talent`, {
        data: formdata,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast.success("Deleted Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        // toast.success(error);
        toast.error(error?.response?.data?.message);
      });
  };

  selectAll = () => {
    let images = this.state.images;
    images.map((image) => {
      image.is_requested = true;
    });
    this.setState({
      images: images,
    });
  };

  deletselectAll = () => {
    let images = this.state.images;
    images.map((image) => {
      image.is_requested = false;
    });
    this.setState({
      images: images,
      selectedImages: [],
    });
    localStorage.removeItem("selectedImages");
  };

  removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  openTab = (url) => {
    window.open(url, "_blank");
    // this.props.history.push(url);
  };

  logout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    window.location = "/home?logged_out";
  };

  // https://stackoverflow.com/questions/42391499/react-render-new-row-every-4th-column
  render() {
    let selection = localStorage.getItem("selectedImages");
    selection = selection !== null ? selection?.split(/[ ,]+/) : [];

    if (this.state.loader) {
      return <Loader />;
    }
    const { url } = this.props.match;
    // const Images = this.state.images;
    const items = this.state.images;
    // const gridwidth = items.length/4;
    let rowContents = [];
    const contents = items.reduce((acc, image, i) => {
      let cover = image?.resources?.filter((item) => item.section === "cover");

      rowContents.push(
        <div key={i} className="col-xs-6 col-sm-4 col-lg-3 mb-6">
          <div className="boxwrap">
            <div className="image_text">
              <div>
                {image.is_requested ||
                selection.find((element) => element === image.slug) ? (
                  <div style={{ zIndex: 1 }} className="talents-close">
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        items[i].is_requested = false;
                        const selectedImages = this.state.selectedImages;
                        const newSelectedImages = this.removeItemAll(
                          selectedImages,
                          i
                        );

                        localStorage.setItem(
                          "selectedImages",
                          selection.filter((val) => val !== image.slug)
                        );

                        this.setState({
                          ...this.state,
                          image: items,
                          selectedImages: newSelectedImages,
                        });
                      }}
                      className="material-icons md-1"
                    >
                      {" "}
                      clear
                    </i>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="img-box">
                  <Link to={`/country${url}/${image.slug}`}>
                    <div
                      className={
                        image.is_requested ||
                        selection.find((element) => element === image.slug)
                          ? "image_text_faded"
                          : ""
                      }
                    >
                      {/* ///////update id to class by Anurag Saxena////// */}
                      <div className="image-mask">
                        <img
                          key={image.slug}
                          src={cover && `${cover[0]?.route}`}
                          alt={image.name}
                        />
                      </div>
                    </div>
                  </Link>
                </div>

                {this.state.isAdmin ? (
                  <div className="top-right top-right-fix">
                    <button className="share">
                      <Link
                        to={`/EditTalent/${image.slug}/${
                          window.location.pathname.split("/")[1]
                        }`}
                      >
                        Edit
                      </Link>
                    </button>
                    <button onClick={() => this.deleteTalent(i)}>
                      <Link to="#">Delete</Link>
                    </button>
                  </div>
                ) : image.is_requested ||
                  selection.find((element) => element === image.slug) ? (
                  <div className="top-right top-right-fix">
                    <h4 className="">ADDED</h4>
                  </div>
                ) : (
                  <div className="top-right top-right-fix">
                    <button
                      data-target="#share"
                      data-toggle="modal"
                      className="share"
                      onClick={() =>
                        this.sharetalentimg(
                          i,
                          `${url}/${image.slug}`,
                          image.name,
                          image.last_name
                        )
                      }
                    >
                      <Link to="#">Share</Link>
                    </button>
                    <button onClick={() => this.requestImage(i, image["slug"])}>
                      <Link to="#">ADD TO PACKAGE</Link>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <p className="style_heading talent-heading">
              <Link
                to={`/country${url}/${image.slug}`}
                style={{ color: "black" }}
                className="dodit-medium"
              >
                {" "}
                <strong className="dodit-bold">{image.name}</strong>{" "}
                <span className="text-caplitalized">{image.last_name}</span>
              </Link>
            </p>
            {this.state.iscreator ? (
              <p className="profilename">{image.profile}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      );
      // if (i % 4 === 3) {
      //   acc.push(<div className="row">{rowContents}</div>);
      //   rowContents = [];
      // }

      return acc;
    }, []);

    contents.push(<div className="row newgrid">{rowContents}</div>);
    console.log(this.state.counter);

    return (
      <>
        <Navigation />
        <div className="Talents mt-6 pt-16">
          <section className="helvetica-neue-italic mx-auto talentnav">
            <div className="flex justify-center mb-2 btn-gender">
              <div className="dropdown">
                <div className="men-button">
                  <button
                    className={
                      this.state.isActiveMen
                        ? "active focus:outline-none uppercase hover:text-gray-500 transition duration-700 font-bold"
                        : "focus:outline-none uppercase hover:text-gray-500 transition duration-700 font-bold"
                    }
                    id="about-button"
                  >
                    <Link
                      onClick={() => this.genderImages("men")}
                      className="btn_link"
                      to="#"
                    >
                      Men
                    </Link>
                  </button>
                </div>

                <div className="men-dropdown">
                  <div
                    className="dropdown-content text-center"
                    style={{ marginLeft: -25 }}
                  >
                    {/* <div className="flex justify-center"> */}
                    <div className="">
                      <button
                        onClick={() => this.categoryImages("men", "image")}
                        className={
                          this.state.currentActive === "men image"
                            ? "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700 active"
                            : "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700"
                        }
                        id="about-button"
                      >
                        <Link to="#" activeClassName="active">
                          MAIN
                        </Link>
                      </button>
                      <button
                        onClick={() =>
                          this.categoryImages("men", "development")
                        }
                        className={
                          this.state.currentActive === "men development"
                            ? "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700 active"
                            : "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700"
                        }
                        id="office-button"
                      >
                        <Link to="#">Development</Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <div className="women-button">
                  <button
                    className={
                      this.state.isActiveWomen
                        ? "active focus:outline-none uppercase hover:text-gray-500 transition duration-700 font-bold"
                        : "focus:outline-none uppercase hover:text-gray-500 transition duration-700 font-bold"
                    }
                    id="office-button"
                  >
                    <Link
                      onClick={() => this.genderImages("women")}
                      className="btn_link"
                      to="#"
                    >
                      Women
                    </Link>
                  </button>
                </div>

                <div className="women-dropdown">
                  <div
                    className="dropdown-content text-center"
                    style={{ marginLeft: -12 }}
                  >
                    <div className="">
                      {/* <div className="flex justify-center"> */}
                      <button
                        onClick={() => this.categoryImages("women", "image")}
                        className={
                          this.state.currentActive === "women image"
                            ? "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700 active"
                            : "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700"
                        }
                        id="about-button"
                      >
                        <Link to="#">MAIN</Link>
                      </button>
                      <button
                        onClick={() =>
                          this.categoryImages("women", "development")
                        }
                        className={
                          this.state.currentActive === "women development"
                            ? "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700 active"
                            : "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700"
                        }
                        id="office-button"
                      >
                        <Link to="#">Development</Link>
                      </button>
                      {/* <button
                        onClick={() => this.categoryImages('women', 'curve')}
                        className={
                          this.state.currentActive === 'women curve'
                            ? 'focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700 active'
                            : 'focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700'
                        }
                        id="office-button"
                      >
                        <Link to="#">Curve</Link>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.counter > 0 && (
                <div className="dropdown">
                  <div className="men-button">
                    <button
                      className={
                        this.state.isNonbinary
                          ? "active focus:outline-none uppercase hover:text-gray-500 transition duration-700 font-bold"
                          : "focus:outline-none uppercase hover:text-gray-500 transition duration-700 font-bold"
                      }
                      id="about-button"
                    >
                      <Link
                        onClick={() => this.genderImages("nonbinary")}
                        className="btn_link"
                        to="#"
                      >
                        NONBINARY
                      </Link>
                    </button>
                  </div>
                  <div className="men-dropdown">
                    <div
                      className="dropdown-content text-center"
                      style={{ marginLeft: -12 }}
                    >
                      <div className="">
                        <button
                          onClick={() =>
                            this.categoryImages("nonbinary", "image")
                          }
                          className={
                            this.state.currentActive === "men image"
                              ? "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700 active"
                              : "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700"
                          }
                          id="about-button"
                        >
                          <Link to="#" activeClassName="active">
                            MAIN
                          </Link>
                        </button>
                        <button
                          onClick={() =>
                            this.categoryImages("nonbinary", "development")
                          }
                          className={
                            this.state.currentActive === "men development"
                              ? "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700 active"
                              : "focus:outline-none uppercase text-sm hover:text-gray-500 transition duration-700"
                          }
                          id="office-button"
                        >
                          <Link to="#">Development</Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="dropdown">
                <div className="men-button">
                  <button
                    className={
                      this.state.iscreator
                        ? "active focus:outline-none uppercase hover:text-gray-500 transition duration-700 font-bold"
                        : "focus:outline-none uppercase hover:text-gray-500 transition duration-700 font-bold"
                    }
                    id="creator-button"
                  >
                    <Link
                      onClick={() => this.creatorImages()}
                      className="btn_link"
                      to="#"
                    >
                      CREATORS
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </section>

          {
            //Side bar :: for admin - Logout & Add Talent :: for user - View package & Deselect all
          }
          {this.state.isAdmin ? (
            <div>
              <div className="new-left-panel">
                <button className="view-package" onClick={this.logout}>
                  LOG OUT
                </button>
                <button
                  className="view-package"
                  onClick={() =>
                    this.openTab(`/AddTalent${window.location.pathname}`)
                  }
                >
                  ADD TALENT
                </button>
              </div>
              <div className="talent-content">
                <div
                  className="container pad"
                  style={{ width: "80%", marginTop: "60px" }}
                >
                  {contents}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="new-left-panel">
                <button
                  className="view-package"
                  onClick={() => {
                    this.openTab(
                      `/viewpackage/${selection.join(",")}`
                      // `/viewpackage/${this.state.selectedImages.join(',')}`
                    );
                    //page reloads and selected images disappears
                    // this.selectAll();
                    // this.deletselectAll();
                    // window.location.reload();
                  }}
                >
                  VIEW PACKAGE
                </button>
                <button
                  className="deselect-all view-package"
                  onClick={() => this.deletselectAll()}
                >
                  DESELECT ALL
                </button>
              </div>
              <div className="talent-content">
                <div
                  className="container pad"
                  style={{ width: "80%", marginTop: "60px" }}
                >
                  {contents}
                </div>
              </div>
            </div>
          )}
        </div>

        <Footer />
        {/* <div className="middle"> */}
        <div id="share" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <h2>Share</h2>
                <form action="">
                  <textarea
                    className="input-line boxfont"
                    type="text"
                    name="from_name"
                    required="required"
                    value={this.state.from_name}
                    onChange={this.handleInput}
                    placeholder="From Name*"
                    style={{ fontSize: 15 }}
                  />
                  <textarea
                    className="input-line boxfont"
                    type="email"
                    name="email"
                    required="required"
                    value={this.state.email}
                    onChange={this.handleInput}
                    placeholder="To Email(s)*"
                    style={{ fontSize: 15 }}
                  />
                  <textarea
                    className="input-line textarea-input-line boxfont"
                    required="required"
                    name="message"
                    value={this.state.message}
                    onChange={this.handleInput}
                    placeholder="Comments*"
                    style={{ fontSize: 15 }}
                  />
                  <input
                    type="hidden"
                    name="talent"
                    value={this.state.sharetalent}
                  />
                  <input
                    type="hidden"
                    name="talentname"
                    value={this.state.talentname}
                  />
                  <button
                    href="#share-confirm"
                    data-dismiss="modal"
                    data-toggle="modal"
                    onClick={this.formsubmit}
                    value={this.state.submit}
                    style={{ fontSize: "1em", height: 40 }}
                  >
                    Share
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* </div> */}

        <div id="share-confirm" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <h2>Share</h2>
                <form action="">
                  <p className="success-message-big" style={{ color: "black" }}>
                    SHARED
                  </p>
                  <button
                    data-dismiss="modal"
                    style={{ fontSize: "1em", height: 40 }}
                  >
                    CLOSE
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div id="request" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <h2 className="helvetica-neue-regular">Request Package</h2>
                <form action="">
                  <textarea
                    type="text"
                    className=" boxfont"
                    placeholder="Name*"
                    style={{ fontSize: 15 }}
                  />
                  <textarea
                    className=" boxfont"
                    type="text"
                    placeholder="Emails*"
                    style={{ fontSize: 15 }}
                  />
                  <textarea
                    className="textarea-input-line boxfont"
                    placeholder="Comments*"
                    style={{ fontSize: 15 }}
                  />
                  <button style={{ fontSize: "1em", height: 40 }}>
                    Request Package
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CountryTalent;
