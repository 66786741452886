import React from 'react';

export default function SuccessPopup({ message }) {
  return (
    <div id='submit-confirm' className='modal fade' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-body'>
            <h2>Work</h2>
            <form action=''>
              <p className='success-message-big' style={{ color: 'black' }}>
                {message}
              </p>
              <button
                data-dismiss='modal'
                style={{ fontSize: '1em', height: 40 }}
              >
                CLOSE
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
