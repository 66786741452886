import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import Navigation from '../components/Navigation.jsx';
import Carousel from '../components/Carousel';
import Timeline from '../components/Timeline';
import SuccessPopup from '../components/successPopup';
import config from '../config';
import UploadWork from '../components/uploadWork';
import Loader from '../components/Loader';
import { useBottomScrollListener,BottomScrollListener } from 'react-bottom-scroll-listener';



// const url = 'https://api.systemagency.com/';
const url = config.URL;

function Work() {
  const [item, setItem] = useState(null);
  const [loader, setLoader] = useState(true);
  const [carouselItems, setCarouselItems] = useState([]);
  const [timelineWork, setTimelineWork] = useState([]);
  const [pictureCount, setPictureCount] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [end,setEnd]=useState(20);
  const callback=()=>{
    setEnd(end+20);
  }
  const scrollRef = useBottomScrollListener(callback);

  function filterCarouselItems(rows) {
    const pictures = [];
    rows.forEach((row) => {
      if (row['component'] === 'carousel') {
        pictures.push({
          id: row['id_work'],
          img: row['resources']['route'],
          talentName: row['talent_name'],
          clientName: row['client_name'],
          credits: row['credits'],
          resources: row['resources'],
          Slug: row['slug'],
          popup: row['resources'],
          type: row.resources.type,
          component: row['component'],
        });
      }
    });
    return pictures;
  }

  function filterTimelineWork(rows) {
    const timelineWork = [];
    rows.forEach((row) => {
      if (row['component'] === 'timeline') {
        timelineWork.push({
          id: row['id_work'],
          img: filterCoverImage(row['resources']),
          talentName: row['talent_name'],
          clientName: row['client_name'],
          credits: row['credits'],
          resources: filterGallery(row['resources']),
          Slug: row['slug'],
          popup: row['resources'],
          createdAt: row['createdAt'],
          component: row['component'],
          order:row['order']
        });
        console.log(row["order"])
      }
    });
    let sorted=timelineWork.sort((a,b)=>{
       return a.order-b.order;
    })
    console.log(timelineWork)
    console.log(sorted)
    return sorted;
  }
  function filterCoverImage(rows) {
    const cover = [];
    rows.forEach((row) => {
      if (row['fileType'] === 'cover') {
        cover.push({
          route: row['route'],
        });
      }
    });
    return cover[0]?.route;
  }

  function filterGallery(rows) {
    const gallery = [];
    rows.forEach((row) => {
      if (row['fileType'] === 'gallery') {
        gallery.push(row);
      }
    });
    return gallery;
  }

  const fetchModelWorkDeatils = async (endpoint) => {
    const response = await axios.get(url + endpoint);
    const rows = response.data.item.rows;
    setCarouselItems(filterCarouselItems(rows));
    let timeLineWork = filterTimelineWork(rows);
    setTimelineWork(
      timeLineWork.sort(function (a, b) {
        return b.order-a.order
      })
    );
    setPictureCount(response.data.item.count);
    setLoader(false);
  };

  useEffect(() => {
    fetchModelWorkDeatils('work');
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAdmin(true);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    window.location = '/home?logged_out';
  };
  if (loader) {
    return <Loader />;
  }

  return (
    <div style={{ position: 'relative' }} >
      <Navigation />
      <BottomScrollListener onBottom={callback}>
      <div className="main work" ref={scrollRef}>
        {pictureCount > 0 ? (
          <>
            <Carousel
              items={carouselItems}
              parent="work"
              setItem={setItem}
              isEdit={isEdit}
              end={end}
            />
            <div className="workwrap"><Timeline items={timelineWork} setItem={setItem} end={end}/></div>
          </>
        ) : (
          <></>
        )}
      </div>
      </BottomScrollListener>
      {isAdmin && (
        <div style={{ position: 'fixed', top: '92%', left: '2%' }}>
          <div
            className="new-left-panel"
            style={{ position: 'sticky', width: '100%', minWidth: 110 }}
          >
            <button
              className="view-package"
              href="#add-work"
              data-toggle="modal"
              onClick={() => setItem(null)}
            >
              ADD WORK
            </button>
            <button className="view-package" onClick={logout}>
              LOG OUT
            </button>
          </div>
        </div>
      )}
      <UploadWork item={item} />
      {/* <AddWorkPopup item={item} /> */}
      <SuccessPopup message={'WORK ADDED SUCCESSFULLY'} />
    </div>
  );
}

export default Work;
