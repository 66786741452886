import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import './style.css';

import useWindowSize from "../../components/useWindowSize";

export const CarouselItem = ({ children, width }) => {
  return (
    <div className="carousel-item" style={{ width: width }}>
      {children}
    </div>
  );
};
var counter = 0;
var final = [];
const Carousel = ({ children, large, active }) => {
  const [activeIndex, setActiveIndex] = useState(active);

  const { width } = useWindowSize();
  const updateIndex = (newIndex) => {
    // console.log(activeIndex)
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };


  const _prevSlide = () => {
    updateIndex(activeIndex - 1);
    const carousel = document.querySelector('.img-container');
    carousel.style.transition = 'transform 0.5s ease-in-out';

    let slidet ='';
    if(width>800){
       slidet = (activeIndex==0)? 704 * (React.Children.count(children)-1) : 704 * (activeIndex - 1) ;
    }else if (width>540){
      slidet = (activeIndex==0)? 540 * (React.Children.count(children)-1) : 540 * (activeIndex - 1) ;
    }else {
      slidet = (activeIndex==0)? 320 * (React.Children.count(children)-1) : 320 * (activeIndex - 1) ;
    }

    carousel.style.transform = `translateX(-${slidet}px)`;
  };

  const _nextSlide = () => {
    updateIndex(activeIndex + 1);

    // const slidep = (activeIndex==0)? 704  : 704 * (activeIndex + 1);


    let slidep ='';
    if(width>800){
      slidep = (activeIndex==0)? 704  : 704 * (activeIndex + 1);
    }else if (width>540){
      slidep = (activeIndex==0)? 540  : 540 * (activeIndex + 1);
    }else {
      slidep =(activeIndex==0)? 320  : 320 * (activeIndex + 1);
    }

    const carousel = document.querySelector('.img-container');
    carousel.style.transition = 'transform 0.5s ease-in-out';
    carousel.style.transform = `translateX(-${slidep}px)`;
  };
  useEffect(() => {
    document.addEventListener('keydown', keyChange);

    return function cleanup() {
      document.removeEventListener('keydown', keyChange);
    };
  });

  const keyChange = (e) => {
    if (large) {
      switch (e.keyCode) {
        case 37:
          _prevSlide();
          break;

        case 39:
          _nextSlide();
          break;
        default:
      }
    }
  };


    const handlers = useSwipeable({
      onSwipedLeft: () => updateIndex(activeIndex + 1),
      onSwipedRight: () => updateIndex(activeIndex - 1),
    });

  return (

    <div className="carousel-container" style={{border:"1px solid red"}}>
      <div
        className="carousel"
        // onMouseEnter={() => setPaused(true)}
        // onMouseLeave={() => setPaused(false)}
      >
        <div
          className="inner"
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { width: '100%' });
          })}
        </div>
      </div>
      {children.length !== 1 && (
        <>
          <button
            onClick={_prevSlide}
            className="carousel-btn"
            id="carousel-prev"
          >
            <BsChevronLeft color="#eee" size={40} />
          </button>
          <button
            onClick={_nextSlide}
            className="carousel-btn"
            id="carousel-next"
          >
            <BsChevronRight color="#eee" size={40} />
          </button>
        </>
      )}
    </div>
  );
};

export default Carousel;
