import React, { useState, useRef } from "react";
import "../css/Timeline.css";
import ReactPlayer from "react-player";
import { MdEdit } from "react-icons/md";
//const url = "https://api.systemagency.com/talent/";
import { Img } from "react-image-loading";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "./sliderCustomArrow";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  EffectCoverflow,
  Autoplay,
} from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import {AiOutlinePlus} from "react-icons/ai";
import {BsFillPlayFill} from "react-icons/bs"

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

const imgTypes = ["image/jpeg", "image/png", "image/jpg"];

function Timeline({ items, setItem, end }) {
  const [popUpData, setPopupData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const left = "item-container left-content";
  const right = "item-container right-content";
  // let leftItemCount = Math.ceil(items.length / 2);

  let showPopup = (data) => {
    let { resources } = data;
    console.log(data)
    // resources.shift();
    // let popupImg = data.popup.filter(
    //   (item) => item.Components[0].name === "work_photoset"
    // );

    setPopupData(
      resources.sort(function (a, b) {
        return a.position - b.position;
      })
    );
  };

  let closePopup = () => {
    setPopupData({});
  };

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsAdmin(true);
    }
  }, []);

  const checkIsLeft = (index) => {
    if (index % 2 === 0) {
      return true;
    }

    return false;
  };
  return (
    <div className="container mt-32">
      <div className="grid grid-cols-4 gap-x-8 gap-y-8 w-4/4 mx-auto">
        {items.slice(0, end).map((item, index) => (
          <div className="col-span-2">
            <div className={checkIsLeft(index) ? right : left} key={index}>
              <div className="img-wrapper" onClick={() => showPopup(item)}>
                 <div className="button_plus" onClick={() => showPopup(item)} style={{cursor:"pointer"}}>
                 <i>{imgTypes.includes(item.resources[0]?.type)?<AiOutlinePlus/>:<BsFillPlayFill/>}</i>
                 </div>
                 <img

                  src={item.img}
                  alt="placeholder alternative"
                  style={{ maxHeight: 420, objectFit: "contain" }}
                />
              </div>
              <div className="content">
                <strong>
                  {isAdmin && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>{item?.order}</p>
                      <MdEdit
                        style={{
                          display: "inline-block",
                          fontSize: 12,
                          marginBottom: 6,
                          marginRight: 5,
                          cursor: "pointer",
                          outline: "none",
                        }}
                        href="#add-work"
                        data-toggle="modal"
                        onClick={() => setItem(item)}
                      />
                    </div>
                  )}
                  <b
                    className="name capitalize"
                    style={{ display: "inline-block" }}
                  >
                    {item.talentName}
                  </b>
                </strong>
                <div> {item.clientName} </div>
                {item.credits.map((credit) => (
                  <div>
                    {credit.title && <strong>{credit.title}:</strong>}{" "}
                    {credit.text}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {popUpData.length > 0 ? (
        <Popup data={popUpData} closePopup={closePopup} />
      ) : (
        ""
      )}
    </div>
  );
}

export default Timeline;

function Popup({ data, closePopup }) {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    className: "slides",
    loop: true,
  };
  const settings2 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    className: "slides",
    loop: true,
  };

  data.sort((a, b) => (a.route > b.route ? 1 : -1));
  // console.log(data);

  return (
    <div className={imgTypes.includes(data[0].type) ? "popup" : "popup"}>
      <div style={{ zIndex: 2 }} className="close">
        <i
          style={{ cursor: "pointer" }}
          onClick={closePopup}
          className="material-icons md-1"
        >
          {" "}
          clear
        </i>
      </div>
      <div className="blocker" onClick={closePopup}></div>
      <div className="oh__yes">
        <div style={{}}>
          {window?.innerWidth > 768 ? (
           data?.length>4? <Slider {...settings}>
           {data.map((item, index) =>
             imgTypes.includes(item.type) ? (
               <div className="popup_wrapper">
                 <img
                   className="popoImg"
                   src={item.route}
                   alt="placeholder alternative"
                 />
               </div>
             ) : (
               <div style={{ width: "100%" }}>
                 <ReactPlayer
                   playing={true}
                   volume="0"
                   muted={true}
                   width="auto"
                   height="auto"
                   controls
                   //src={`https://api.systemagency.com${item.route}`}
                   url={item.route}

                 />
               </div>
             )
           )}
         </Slider>: <div className="oh">
              {data.map((item, index) =>
                imgTypes.includes(item.type) ? (
                  <div className="popup_wrapper">
                    <div style={{display:"flex",justifyContent:"center"}}>
                      <img
                        className="popoImg"
                        src={item.route}
                        alt="placeholder alternative"
                      />
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "100%",display:'flex',justifyContent:"center",height:"30%" }}>
                    <ReactPlayer
                      playing={true}
                      volume="0"
                      muted={true}
                      width="auto"
                      height="450px"
                      controls
                      //src={`https://api.systemagency.com${item.route}`}
                      url={item.route}
                      className="react-player-timeline"
                    />
                  </div>
                )
              )}
            </div>
          ) : (
           <div>
             <Slider {...settings2}>
              {data.map((item, index) =>
                imgTypes.includes(item.type) ? (
                  <div className="popup_wrapper">
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                     {item?.width>item?.height? <img
                        className="popoImg"
                        src={item.route}
                        alt="placeholder alternative"
                      />: <img
                      className="popoImg"
                      src={item.route}
                      alt="placeholder alternative"
                      style={{height:"400px"}}
                    />}
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "100%" }}>
                    <ReactPlayer
                      playing={true}
                      volume="0"
                      muted={true}
                      width="auto"
                      height="auto"
                      controls
                      //src={`https://api.systemagency.com${item.route}`}
                      url={item.route}
                      className="react-player-timeline"
                    />
                  </div>
                )
              )}
            </Slider>
           </div>
          )}
        </div>
      </div>
    </div>
  );
}

// <div className="row">
// 	<div className="col-sm">
// 		<img
// 			src="https://res.cloudinary.com/shakilahmmeed/image/upload/v1608670639/2_pfytib.jpg"
// 			alt="alernate text"
// 		/>
// 	</div>
// 	<div className="col-sm">
// 		<img
// 			src="https://res.cloudinary.com/shakilahmmeed/image/upload/v1608670639/2_pfytib.jpg"
// 			alt="alernate text"
// 		/>
// 	</div>
// </div>

// src = {`https://api.systemagency.com${image.Resources[0].route}`}
// alt = { image.name }
// <div className="flex gallery">
// 	<div className="img-wrapper">
// 		<img
// 			src={`https://api.systemagency.com${props.data.img}`}
// 			alt="alernate text"
// 		/>
// 	</div>
// 	<div className="img-wrapper">
// 		<img
// 			src="https://res.cloudinary.com/shakilahmmeed/image/upload/v1608670639/1_fafdgi.jpg"
// 			alt="alernate text"
// 		/>
// 	</div>
// 	<div className="img-wrapper">
// 		<img
// 			src="https://res.cloudinary.com/shakilahmmeed/image/upload/v1608670639/2_pfytib.jpg"
// 			alt="alernate text"
// 		/>
// 	</div>
// 	<div className="img-wrapper">
// 		<img
// 			src="https://res.cloudinary.com/shakilahmmeed/image/upload/v1608670639/3_hso4ra.jpg"
// 			alt="alernate text"
// 		/>
// 	</div>
// </div>
