import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

import Loader from '../components/Loader';

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      telentdata: [],
      renderSlider: false,
      sliderItems: [],
      type: null,
      largeSlider: false,
      isMobile: false,
      loader: true,
      pair: false,
      active: 0,
      file:''
    };
  }

  onClick = (e) => {
    e.preventDefault()
    
  }

  
  componentDidMount() {
    const slug = this.props.match.params.slug;

    // if (window.innerWidth < 630) {
    //   this.setState({ isMobile: true });
    // } else {
    //   this.setState({
    //     isMobile: false,
    //   });
    // }
    axios.get(`${config.URL}pdf?slug=${slug}`).then((response) => {
      this.setState({file:response.data.msg});
      this.setState({
          loader: false,
      });
     
    });
    // window.addEventListener('resize', this.windowSize);
  }

  

  

  render() {
    if (this.state.loader) {
      return <Loader />;
    }
    
    const data = this.state.telentdata;


    return (
      <>
        <iframe
            style={{ width: "100%", height: "700px" }}
            src={this.state.file}
            type='application/pdf'
            title='title'
          />
      </>
    );
  }
}

export default Detail;
