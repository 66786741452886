import React, { Component, useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "../components/sliderCustomArrow";
import ReactPlayer from "react-player/lazy";

function SliderImages(props) {
  document.addEventListener("keydown", (event) => {
    console.log(event.code);
  });
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    className: "slides",
    draggable: true,
    swipeToSlide: true,
    arrows: false,
    focusOnSelect: true,
  };
  const images = props.sliderItems;
  return (
    <div style={{ width: "100%", height: "100%" }} className="sa-slider">
      <Slider {...settings}>
        {images.map((image, i) => (
          <div
            className={
              props.type !== "videos"
                ? "slider-image-wrapper"
                : "slider-vedio-wrapper"
            }
            key={i}
          >
            {props.type !== "videos" && (
              <img
                style={{ width: "100%", width: "260px" }}
                className="image slider-image h-full w-auto"
                src={image.route}
                alt=""
                onClick={props.open}
              />
            )}
            {props.type === "videos" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  outline: "none",
                }}
              >
                <ReactPlayer controls url={image.route} playing={false} />
              </div>
            )}
          </div>
        ))}
      </Slider>
      {images.length !== 1 && (
        <>
        </>
      )}
    </div>
  );
}

export default SliderImages;
