import React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Config from "../../config";
import ReactS3Client from "react-aws-s3-typescript";
import reactImageSize from "react-image-size";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";

export default function TimeLine({ type, home, deleteHome, selectedMedia }) {
  const [mediaType, setMediaType] = React.useState("");
  const [state, setState] = React.useState({
    talent_name: "",
    client_name: "",
    caption: "",
    hyperlink: "",
    talent_hyperlink: "",
  });
  const [uploading, setUploading] = React.useState(false);
  const [uploadingProgress, setUploadingProgress] = React.useState(0);
  const [acceptedFile, setAcceptedFile] = React.useState(null);

  React.useEffect(() => {
    if (home !== null) {
      setState({
        ...state,
        talent_name: home?.talent_name,
        client_name: home?.client_name,
        caption: home?.caption,
        hyperlink: home?.hyperlink,
        talent_hyperlink: home?.talent_hyperlink,
      });
    }
  }, [home]);

  React.useEffect(() => {
    if (type === "landscape") {
      setMediaType("image/*,video/*");
    } else {
      setMediaType("image/*");
    }
  }, [type]);

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    if (file?.size > 26214400) {
      toast.error("Max File Size is 25 MB!");
    } else {
      setAcceptedFile(file);
    }
  };

  const handleSubmit = (e) => {
    let { talent_name, client_name, caption, talent_hyperlink } = state;
    e.preventDefault();
    if (acceptedFile && talent_name && client_name && caption) {
      setUploading(true);
      uploadFiles();
    } else {
      toast.error("All Fields are Required!");
    }
  };

  const uploadFiles = async () => {
    let file = acceptedFile;

    let type = file.type;
    let name = file.name;
    let filename =
      name.replace(/\.[^/.]+$/, "") +
      "-" +
      Math.floor(Math.random() * 10000000 + 1);

    let imgheight = "";
    let imgwidth = "";
    try {
      const storageRef = ref(storage, filename);
      let resp = [];
      await uploadBytes(storageRef, file).then((snapshot) => {});
      await getDownloadURL(storageRef).then((url) => {
        resp.push(url);
      });
      let item;
      if (file.type != "video/mp4") {
        const { width, height } = await reactImageSize(resp[0]);
        imgheight = height;
        imgwidth = width;

        item = {
          route: resp[0],
          type: type,
          height: imgheight,
          width: imgwidth,
        };
      } else {
        item = {
          route: resp[0],
          type: type,
        };
      }

      uploadFeature(item);
    } catch (exception) {
      // console.log(exception);
      toast.error(exception);
    }
  };

  const uploadFeature = (item) => {
    let token = localStorage.getItem("token");
    let { talent_name, client_name, caption, hyperlink, talent_hyperlink } =
      state;

    let data = {
      resources: item,
      component: "timeline",
      sub_component: type,
      talent_name,
      client_name,
      caption,
      hyperlink,
      talent_hyperlink,
    };

    axios
      .post(`${Config.URL}home`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setUploading(false);
        toast.success("Uploaded Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        // toast.success(error);
        setUploading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  // Update timeline
  const updateTimeline = (e) => {
    e.preventDefault();
    setUploading(true);

    let token = localStorage.getItem("token");
    let { talent_name, client_name, caption, hyperlink, talent_hyperlink } =
      state;

    let data = {
      component: "timeline",
      talent_name,
      client_name,
      caption,
      hyperlink,
      talent_hyperlink,
      id_home: home?.id_home,
    };

    axios
      .post(`${Config.URL}home/update`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setUploading(false);
        toast.success("Updated Successfully!");
        // console.log('test');
        window.location.reload(true);
      })
      .catch((error) => {
        // toast.success(error);
        setUploading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div>
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 50,
        }}
        type="text"
        name="talent_name"
        placeholder="Talent Name*"
        onChange={handleInputChange}
        value={state.talent_name}
      />
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 25,
        }}
        type="text"
        name="talent_hyperlink"
        placeholder="Talent Hyperlink"
        onChange={handleInputChange}
        value={state.talent_hyperlink}
      />
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 25,
        }}
        type="text"
        name="client_name"
        placeholder="Client Name*"
        onChange={handleInputChange}
        value={state.client_name}
      />
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 25,
        }}
        type="text"
        name="hyperlink"
        placeholder="Hyperlink"
        onChange={handleInputChange}
        value={state.hyperlink}
      />
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 25,
        }}
        type="text"
        name="caption"
        placeholder="Caption*"
        onChange={handleInputChange}
        value={state.caption}
      />
      {home === null && (
        <div className="row" style={{ padding: "0 15px", marginTop: 25 }}>
          <div
            style={{
              padding: "4px 2px",
              background: "#eaecef",
            }}
          >
            <input
              type="file"
              accept={mediaType}
              onChange={handleFileChange}
              className="w-full placeholder-gray-700 helvetica-neue-thin"
              style={{
                margin: 0,
                padding: 0,
                fontSize: 12,
                textTransform: "lowercase",
                width: "100%",
              }}
            />
          </div>
          <div
            style={{
              textAlign: "right",
              color: "#a7a7a7",
              fontSize: 12,
              marginTop: 5,
              fontStyle: "italic",
            }}
          >
            Max File Size Limit 25MB*
          </div>
        </div>
      )}
      <button
        // href='#submit-confirm'
        // data-dismiss='modal'
        // data-toggle='modal'
        style={{ fontSize: "1em", height: 40 }}
        onClick={home === null ? handleSubmit : updateTimeline}
      >
        {uploading
          ? `Feature Uploading Please wait.... ${uploadingProgress}%`
          : "Submit"}
      </button>
      {home !== null && (
        <button
          // data-dismiss="modal"
          onClick={(e) => {
            e.preventDefault();
            deleteHome(home?.id_home);
          }}
          style={{ fontSize: "1em", height: 40 }}
        >
          Delete
        </button>
      )}
      <button
        data-dismiss="modal"
        onClick={() => selectedMedia("")}
        style={{ fontSize: "1em", height: 40 }}
      >
        Close
      </button>
    </div>
  );
}
