import React from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import OfficeButtons from "../components/OfficeButtons";
import styles from "../css/privacy.scss";

function Privacy() {
  return (
    <>
      <Navigation />
      <div className="privay_main">
        <div style={{ marginLeft: "40px", marginRight: "40px" }}>
          <h1 className="title">PRIVACY POLICY</h1>
          <div
            style={{
              width: window?.innerWidth < 768 ? "65%" : "100%",
              marginLeft: window?.innerWidth < 768 ? "90px" : "0px",
            }}
          >
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "-0.25in",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                Welcome to SystemAgency’s website (“us”, “we”, or “our”)
                operates <span style={{ background: "white" }}>www.</span>
              </span>
              <span style={{ fontSize: "15px" }}>
                systemagency.com{" "}
                <span style={{ color: "black" }}>
                  (hereinafter referred to as “<strong>Service</strong>”).
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "-0.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "-0.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                Our Privacy Policy governs your visit to{" "}
                <span style={{ background: "white" }}>www.</span>
              </span>
              <span style={{ fontSize: "15px" }}>
                systemagency.com
                <span style={{ color: "black" }}>
                  , and explains how we collect, safeguard and disclose
                  information that results from your use of our Service.&nbsp;
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "-0.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginLeft: "-0.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                The Company’s Privacy Policy governs your visit to{" "}
                <span style={{ background: "white" }}>www.</span>
              </span>
              <span style={{ fontSize: "15px" }}>
                systemagency.com{" "}
                <span style={{ color: "black" }}>
                  and explains how we collect, manage, use, safeguard and
                  disclose Personal Information that results from your use of
                  our Service, which is subject to the privacy principles set
                  out in <span style={{ background: "white" }}>the</span>
                </span>
              </span>
              <span style={{ color: "#353535" }}>
                &nbsp;
                <span style={{ fontWeight: "bold" }}>
                  new Federal Act on Data Protection 2023
                </span>
                &nbsp;
              </span>
              <span style={{ fontSize: "15px", color: "black" }}>
                and general law intended to protect your privacy, as amended
                from time to time, and where relevant by the European Union
                General Data Protection Regulations (EU) 2016/679 (the
                GDPR),&nbsp;
              </span>
              <span style={{ fontSize: "15px", color: "black" }}>
                California Privacy Protection Act (the CalOPPA) and California
                Consumer Privacy Act (the CCPA).
              </span>
              <span style={{ color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "-0.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "-0.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "-0.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information in
                accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Condition.
              </span>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "-0.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "-0.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                Our Terms and Conditions (“<strong>Terms</strong>”) govern all
                use of our Service and together with the Privacy Policy
                constitutes your agreement with us (“<strong>Agreement</strong>
                ”).
              </span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "decimal",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      DEFINITIONS
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  “SERVICE”&nbsp;
                </span>
              </strong>
              <span style={{ fontSize: "15px", color: "black" }}>
                means the <span style={{ background: "white" }}>www.</span>
              </span>
              <span style={{ fontSize: "15px" }}>
                systemagency.com{" "}
                <span style={{ color: "black" }}>
                  website&nbsp;operated by&nbsp;STAR SYSTEM SA,
                  CH-660.1.148.009-6.
                </span>
              </span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  “PERSONAL DATA”
                </span>
              </strong>
              <span style={{ fontSize: "15px", color: "black" }}>
                &nbsp;means data about a living individual who can be identified
                from those data (or from those and other information either in
                our possession or likely to come into our possession).
              </span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  “USAGE DATA”
                </span>
              </strong>
              <span style={{ fontSize: "15px", color: "black" }}>
                &nbsp;is data collected automatically either generated by the
                use of Service or from Service infrastructure itself (for
                example, the duration of a page visit).
              </span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  “COOKIES”
                </span>
              </strong>
              <span style={{ fontSize: "15px", color: "black" }}>
                &nbsp;are small files stored on your device (computer or mobile
                device).
              </span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  “DATA CONTROLLER”
                </span>
              </strong>
              <span style={{ fontSize: "15px", color: "black" }}>
                &nbsp;means a natural or legal person who (either alone or
                jointly or in common with other persons) determines the purposes
                for which and the manner in which any personal data are, or are
                to be, processed. For the purpose of this Privacy Policy, we are
                a Data Controller of your data.
              </span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                marginLeft: "17.85pt",
                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  “DATA PROCESSORS (OR SERVICE PROVIDERS)”
                </span>
              </strong>
              <span style={{ fontSize: "15px", color: "black" }}>
                &nbsp;means any natural or legal person who processes the data
                on behalf of the Data Controller. We may use the services of
                various Service Providers in order to process your data more
                effectively.
              </span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                marginLeft: "17.85pt",
                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  “DATA SUBJECT”&nbsp;
                </span>
              </strong>
              <span style={{ fontSize: "15px", color: "black" }}>
                is any living individual who is the subject of Personal Data.
              </span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                marginLeft: "17.85pt",
                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
              </strong>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  “THE USER”&nbsp;
                </span>
              </strong>
              <span style={{ fontSize: "15px", color: "black" }}>
                is the individual using our Service. The User corresponds to the
                Data Subject, who is the subject of Personal Data.&nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
              </span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      INFORMATION COLLECTION AND USE&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <span style={{ fontSize: "15px", color: "black" }}>
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      TYPES OF DATA COLLECTED&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Personal Data&nbsp; &nbsp; &nbsp;&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                </span>
              </strong>
            </p>
            <ul style={{ listStyleType: "square" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you (“<strong>Personal Data</strong>”).
                  Personally, identifiable information may include, but is not
                  limited to:
                </span>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      First name and last name
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Age
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Instagram ID
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Email address
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Phone number
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Address, State, Province, Canton, City, ZIP/Postal code
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Body description/measure:
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Height
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Bust/Chest
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Waits
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Hips
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Shoes
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Hair Color
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Eye Color&nbsp;
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Photo/Video
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We may use your Personal Data to contact you with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to you.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp;You may opt out of receiving any, or all, of these
                  communications from us by contacting us at{" "}
                  <span style={{ background: "" }}>
                    alejandro@systemagency.com
                  </span>
                  .
                </span>
              </li>
            </ul>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                marginLeft: "14.2pt",
                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Usage Data
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </span>
              </strong>
            </p>
            <ul style={{ listStyleType: "square" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We may also collect information that your browser sends
                  whenever you visit our Service or when you access Service by
                  or through a mobile device (“<strong>Usage Data</strong>”).
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  This Usage Data may include information such as your
                  computer's Internet Protocol address (e.g., IP address),
                  browser type, browser version, the pages of our Service that
                  you visit, the time and date of your visit, the time spent on
                  those pages, unique device identifiers and other diagnostic
                  data.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  When you access Service with a mobile device, this Usage Data
                  may include information such as the type of mobile device you
                  use, your mobile device unique ID, the IP address of your
                  mobile device, your mobile operating system, the type of
                  mobile Internet browser you use, unique device identifiers and
                  other diagnostic data.
                </span>
              </li>
            </ul>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                marginLeft: "14.2pt",
                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Location Data&nbsp; &nbsp; &nbsp;&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                </span>
              </strong>
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We may use and store information about your location if you
                  give us permission to do so (“<strong>Location Data</strong>
                  ”). We use this data to provide features of our Service, to
                  improve and customize our Service.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  You can enable or disable location services when you use our
                  Service at any time by way of your device settings.
                </span>
              </li>
            </ul>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                marginLeft: "14.2pt",
                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Tracking Cookies Data
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                </span>
              </strong>
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We may use cookies and similar tracking technologies to track
                  the activity on our Service and we hold certain information.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  Cookies are files with a small amount of data which may
                  include an anonymous unique identifier. Cookies are sent to
                  your browser from a website and stored on your device. Other
                  tracking technologies are also used such as beacons, tags and
                  scripts to collect and track information and to improve and
                  analyze our Service.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  Cookies may also be used for other purposes on our Website.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  You can instruct your browser to refuse all cookies or to
                  indicate when a cookie is being sent. However, if you do not
                  accept cookies, you may not be able to use some portions of
                  our Service.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  If you disable the use of cookies on your web browser or
                  remove or reject specific cookies from our Website or linked
                  sites than you may not be able to gain access to all of the
                  content and facilities in those websites.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  Examples of Cookies we may use:
                </span>
              </li>
            </ul>
            <ol style={{ listStyleType: "circle", marginLeft: "0.75in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    Session Cookies:
                  </span>
                </strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp;We use Session Cookies to operate our Service.
                </span>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    Preference Cookies:
                  </span>
                </strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp;We use Preference Cookies to remember your preferences
                  and various settings.
                </span>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    Security Cookies:
                  </span>
                </strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp;We use Security Cookies for security purposes.
                </span>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    Advertising Cookies:
                  </span>
                </strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp;Advertising Cookies are used to serve you with
                  advertisements that may be relevant to you and your interests.
                </span>
              </li>
            </ol>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "1.25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Other Data.&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
              </strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                While using our Service, we may also collect the following
                information but not limited to: sex, age, date of birth, place
                of birth, passport details, citizenship, registration at place
                of residence and actual address, telephone number (work,
                mobile), details of documents on education, qualification,
                professional training, employment agreements, non-disclosure
                agreements, information on bonuses and compensation, information
                on marital status, family members, social security (or other
                taxpayer identification) number, office location and other
                data.&nbsp;
              </span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol
              className="decimal_type"
              style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}
            >
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    USE_OF_DATA
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ol
                  className="decimal_type"
                  style={{ listStyleType: "undefined" }}
                >
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      We&nbsp;may use the collected data for various purposes:
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to provide and maintain our Service;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to notify you about changes to our Service;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to allow you to participate in interactive features of
                          our Service when you choose to do so;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to provide customer support;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to gather analysis or valuable information so that we
                          can improve our Service;&nbsp;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to monitor the usage of our Service;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to detect, prevent and address technical issues;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to fulfill any other purpose for which you provide it;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to carry out our obligations and enforce our rights
                          arising from any contracts entered into between you
                          and us, including for billing and collection;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to provide you with notices about your account and/or
                          subscription, including expiration and renewal
                          notices, email-instructions, etc.;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          to provide you with news, special offers and general
                          information about other goods, services and events
                          which we offer that are similar to those that you have
                          already purchased or enquired about unless you have
                          opted not to receive such information;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          in any other way we may describe when you provide the
                          information;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          for any other purpose with your consent.&nbsp;
                        </span>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      RETENTION OF DATA&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <ol style={{ listStyleType: "circle", marginLeft: "0.25in" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We will retain your Personal Data only for as long as is
                  necessary for the purposes set out in this Privacy Policy. We
                  will retain and use your Personal Data to the extent necessary
                  to comply with our legal obligations (for example, if we are
                  required to retain your data to comply with applicable laws),
                  resolve disputes, and enforce our legal agreements and
                  policies.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We will also retain Usage Data for internal analysis purposes.
                  Usage Data is generally retained for a shorter period, except
                  when this data is used to strengthen the security or to
                  improve the functionality of our Service, or we are legally
                  obligated to retain this data for longer time periods.
                </span>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      TRANSFER OF DATA&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <ol style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  Your information, including Personal Data, may be transferred
                  to – and maintained on – computers located outside of your
                  state, province, canton, country or other governmental
                  jurisdiction where the data protection laws may differ from
                  those of your jurisdiction.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  If you are located outside Switzerland and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to Switzerland and process it there.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We will take all the steps reasonably necessary to ensure that
                  your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of your data
                  and other personal information.
                </span>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      DISCLOSURE OF DATA&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "circle" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <span style={{ fontSize: "15px", color: "black" }}>
                    We may disclose personal information that we collect, or you
                    provide:
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ul style={{ listStyleType: "square" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    Disclosure for Law Enforcement.&nbsp;
                  </span>
                </strong>
                <span style={{ fontSize: "15px", color: "black" }}>
                  Under certain circumstances, we may be required to disclose
                  your Personal Data if required to do so by law or in response
                  to valid requests by public authorities.
                </span>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    Business Transaction.&nbsp;
                  </span>
                </strong>
                <span style={{ fontSize: "", color: "black" }}>
                  If we or our subsidiaries are involved in a merger,
                  acquisition or asset sale, your Personal Data may be
                  transferred.
                </span>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    Other cases. We may disclose your information also:
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      to our subsidiaries and affiliates;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      to contractors, service providers, and other third parties
                      we use to support our business;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      to fulfill the purpose for which you provide it;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      for the purpose of including your company’s logo on our
                      website;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      for any other purpose disclosed by us when you provide the
                      information;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      with your consent in any other cases;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      if we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of the Company,
                      our customers, or others.
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      SECURITY OF DATA&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ul style={{ marginBottom: "0in", listStyleType: "disc" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <span style={{ fontSize: "15px", color: "black" }}>
                    The security of your data is important to us but remember
                    that no method of transmission over the Internet or method
                    of electronic storage is 100% secure. While we strive to use
                    commercially acceptable means to protect your Personal Data,
                    we cannot guarantee its absolute security.
                  </span>
                </li>
              </ul>
            </div>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    YOUR RIGHTS&nbsp; &nbsp;
                    <br />
                    &nbsp;&nbsp; &nbsp; &nbsp;
                  </span>
                </strong>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You have the following rights in relation to your Data:
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Right to access - the right to request (i) copies of
                          the information we hold about you at any time, or (ii)
                          that we modify, update or delete such information. If
                          we provide you with access to the information, we hold
                          about you, we will not charge you for this, unless
                          your request is "manifestly unfounded or excessive."
                          Where we are legally permitted to do so, we may refuse
                          your request. If we refuse your request, we will tell
                          you the reasons why.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Right to correct - the right to have your Data
                          rectified if it is inaccurate or incomplete.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Right to erase - the right to request that we delete
                          or remove your Data from our systems.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Right to restrict our use of your Data - the right to
                          "block" us from using your Data or limit the way in
                          which we can use it.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Right to data portability - the right to request that
                          we move, copy or transfer your Data.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Right to object - the right to object to our use of
                          your Data including where we use it for our legitimate
                          interests.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      &nbsp;To make inquiries, exercise any of your rights set
                      out above, or withdraw your consent to the processing of
                      your Data (where consent is our legal basis for processing
                      your Data), please contact us via this e-mail address:{" "}
                      <span style={{ background: "white" }}>
                        alejandro@systemagency.com.
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      If you are not satisfied with the way a complaint you make
                      in relation to your Data is handled by us, you may be able
                      to refer your complaint to the relevant data protection
                      authority.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      &nbsp;It is important that the Data we hold about you is
                      accurate and current. Please keep us informed if your Data
                      changes during the period for which we hold it.
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    YOUR DATA PROTECTION RIGHTS UNDER GENERAL DATA PROTECTION
                    REGULATION (GDPR)&nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ol style={{ listStyleType: "circle" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      If you are a resident of the European Union (EU) and
                      European Economic Area (EEA), you have certain data
                      protection rights, covered by GDPR. – See more at&nbsp;
                    </span>
                    <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                      <span style={{ fontSize: "", color: "black" }}>
                        https://eur-lex.europa.eu/eli/reg/2016/679/oj
                      </span>
                    </a>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      &nbsp;&nbsp;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      We aim to take reasonable steps to allow you to correct,
                      amend, delete, or limit the use of your Personal Data.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      If you wish to be informed what Personal Data, we hold
                      about you and if you want it to be removed from our
                      systems, please email us at{" "}
                      <span style={{ background: "white" }}>
                        alejandro@systemagency.com
                      </span>
                      .&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      In certain circumstances, you have the following data
                      protection rights:
                    </span>
                    <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          the right to access, update or to delete the
                          information we have on you;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          the right of rectification. You have the right to have
                          your information rectified if that information is
                          inaccurate or incomplete;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          the right to object. You have the right to object to
                          our processing of your Personal Data;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          the right of restriction. You have the right to
                          request that we restrict the processing of your
                          personal information;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          the right to data portability. You have the right to
                          be provided with a copy of your Personal Data in a
                          structured, machine-readable and commonly used format;
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          the right to withdraw consent.&nbsp;You also have the
                          right to withdraw your consent at any time where we
                          rely on your consent to process your personal
                          information;
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Please note that we may ask you to verify your identity
                      before responding to such requests. Please note, we may
                      not able to provide Service without some necessary data.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      You have the right to complain to a Data Protection
                      Authority about our collection and use of your Personal
                      Data. For more information, please contact your local data
                      protection authority in the European Economic Area (EEA).
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      YOUR DATA PROTECTION RIGHTS UNDER THE CALIFORNIA PRIVACY
                      PROTECTION ACT (CalOPPA)
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <ol className="decimal_type" style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  CalOPPA is the first state law in the nation to require
                  commercial websites and online services to post a privacy
                  policy. The law’s reach stretches well beyond California to
                  require a person or company in the United States (and
                  conceivable the world) that operates websites collecting
                  personally identifiable information from California consumers
                  to post a conspicuous privacy policy on its website stating
                  exactly the information being collected and those individuals
                  with whom it is being shared, and to comply with this policy.
                  – See more at:&nbsp;
                </span>
                <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
                  <span style={{ fontSize: "15px", color: "black" }}>
                    https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                  </span>
                </a>
                <span style={{ fontSize: "15px", color: "black" }}>
                  &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </span>
                <ol
                  className="decimal_type"
                  style={{ listStyleType: "undefined" }}
                >
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      According to CalOPPA we agree to the following:
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol className="decimal_type" style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  users can visit our site anonymously;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  our Privacy Policy link includes the word “Privacy”, and can
                  easily be found on the page specified above on the home page
                  of our website;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  users will be notified of any privacy policy changes on our
                  Privacy Policy Page;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  users are able to change their personal information by
                  emailing us at{" "}
                  <span style={{ background: "white" }}>
                    alejandro@systemagency.com
                  </span>
                  .&nbsp; &nbsp; &nbsp;&nbsp;
                </span>
              </li>
            </ol>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <span style={{ fontSize: "15px", color: "black" }}>
                    Our Policy on “Do Not Track” Signals:
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We honor Do Not Track signals and do not track, plant cookies,
                  or use advertising when a Do Not Track browser mechanism is in
                  place. Do Not Track is a preference you can set in your web
                  browser to inform websites that you do not want to be
                  tracked.&nbsp;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  You can enable or disable Do Not Track by visiting the
                  Preferences or Settings page of your web browser.
                </span>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      YOUR DATA PROTECTION RIGHTS UNDER THE CALIFORNIA CONSUMER
                      PRIVACY ACT (CCPA)
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <ol className="decimal_type" style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  If you are a California resident, you are entitled to learn
                  what data we collect about you, ask to delete your data and
                  not to sell (share) it. To exercise your data protection
                  rights, you can make certain requests and ask us:
                </span>
                <ol
                  className="decimal_type"
                  style={{ listStyleType: "undefined" }}
                >
                  <li>
                    <strong>
                      <span style={{ fontSize: "15px", color: "black" }}>
                        What personal information we have about you
                      </span>
                    </strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      . If you make this request, we will return to you:
                    </span>
                    <ol style={{ listStyleType: "circle", marginLeft: "20px" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          The categories of personal information we have
                          collected about you.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          The categories of sources from which we collect your
                          personal information.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          The business or commercial purpose for collecting or
                          selling your personal information.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          The categories of third parties with whom we share
                          personal information.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          The specific pieces of personal information we have
                          collected about you.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          A list of categories of personal information that we
                          have sold, along with the category of any other
                          company we sold it to. If we have not sold your
                          personal information, we will inform you of that fact.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          A list of categories of personal information that we
                          have disclosed for a business purpose, along with the
                          category of any other company we shared it with.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "black" }}>
                          Please note, you are entitled to ask us to provide you
                          with this information up to two times in a rolling
                          twelve-month period. When you make this request, the
                          information provided may be limited to the personal
                          information we collected about you in the previous
                          12&nbsp;months.
                        </span>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "45.0pt",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      To delete your personal information
                    </span>
                  </strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    . If you make this request, we will delete the personal
                    information we hold about you as of the date of your request
                    from our records and direct any service providers to do the
                    same. In some cases, deletion may be accomplished through
                    de-identification of the information. If you choose to
                    delete your personal information, you may not be able to use
                    certain functions that require your personal information to
                    operate.<strong>&nbsp;</strong>
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
              </strong>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      To stop selling your personal information
                    </span>
                  </strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    . If you submit a request to stop selling your personal
                    information, we will stop selling it. If you are a
                    California resident, to opt-out of the sale of your personal
                    information, send us an email with title “Do Not Sell My
                    Personal Information” to submit your request.
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                fontSize: "16px",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
              </strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".3in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
              </strong>
            </p>
            <ol style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  Please note, if you ask us to delete or stop selling your
                  data, it may impact your experience with us, and you may not
                  be able to participate in certain programs or membership
                  services which require the usage of your personal information
                  to function. But in no circumstances, we will discriminate
                  against you for exercising your rights.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  To exercise your California data protection rights described
                  above, please send your request(s) by one of the following
                  means:
                </span>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      By email:{" "}
                      <span style={{ background: "white" }}>
                        <a href="mailto:alejandro@systemagency.com">
                          alejandro@systemagency.com
                        </a>
                      </span>
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "76.5pt",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp;
              </span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "circle" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <span style={{ fontSize: "15px", color: "black" }}>
                    Your data protection rights, described above, are covered by
                    the CCPA, short for the California Consumer Privacy Act. To
                    find out more, visit the official California Legislative
                    Information website. The CCPA took effect on
                    01/01/2020.&nbsp;
                  </span>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      SERVICE PROVIDERS&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <ol style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We may employ third party companies and individuals to
                  facilitate our Service (“<strong>Service Providers</strong>”),
                  provide Service on our behalf, perform Service-related
                  services or assist us in analyzing how our Service is used.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  These third parties have access to your Personal Data only to
                  perform these tasks on our behalf and are obligated not to
                  disclose or use it for any other purpose.
                </span>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      &nbsp;LINKS TO OTHER SITES&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <ol style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  Our Service may contain links to other sites that are not
                  operated by us. If you click a third-party link, you will be
                  directed to that third party's site. We strongly advise you to
                  review the Privacy Policy of every site you visit.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third-party
                  sites or services.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  These thirds party sites will have their own rules about the
                  collection, use and disclosure of information.&nbsp;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We encourage you to review the privacy policies of the other
                  website you visit.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;
                  <br />
                  &nbsp;
                  <br />
                  &nbsp;
                </span>
              </li>
            </ol>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    ANALYTICS
                  </span>
                </strong>
                <ol style={{ listStyleType: "circle" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      We may use third-party Service Providers to monitor and
                      analyze the use of our Service.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".25in",
                fontSize: "16px",

                textAlign: "justify",
                background: "white",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol style={{ marginBottom: "0in", listStyleType: "circle" }}>
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Google Analytics.:
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "45.0pt",
                fontSize: "16px",

                textAlign: "justify",
                background: "white",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
              </strong>
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualize and personalize the ads of its
                  own advertising network.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy Terms web page:
                  https://policies.google.com/privacy?hl=en
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We also encourage you to review the Google's policy for
                  safeguarding your data:&nbsp;
                </span>
                <span style={{ color: "black" }}>
                  <a href="https://support.google.com/analytics/answer/6004245">
                    <span style={{ fontSize: "15px" }}>
                      https://support.google.com/analytics/answer/6004245
                    </span>
                  </a>
                </span>
                <span style={{ fontSize: "15px", color: "black" }}>.</span>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".25in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <strong>
                <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
              </strong>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    CHILDREN’S &nbsp;
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ol style={{ listStyleType: "circle" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Our Services are not intended for use by children under
                      the age of 13 (“<strong>Child</strong>” or “
                      <strong>Children</strong>”).&nbsp;
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      We do not knowingly collect personally identifiable
                      information from Children under 13. If you become aware
                      that a Child has provided us with Personal Data, please
                      contact us. If we become aware that we have collected
                      Personal Data from Children without verification of
                      parental consent, we take steps to remove that information
                      from our servers.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <ol style={{ listStyleType: "undefined", marginLeft: "-0.25in" }}>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    &nbsp;SECURITY
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </span>
                </strong>
                <ol style={{ listStyleType: "circle" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      We are continuously implementing and updating
                      administrative, technical, and physical security measures
                      to help protect your information against unauthorized
                      access, loss, destruction, or alteration. However, the
                      Internet is not a 100% secure environment so we can’t
                      guarantee the security of the transmission or storage of
                      your information.&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                      <br />
                      &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    CHANGES TO THIS PRIVACY POLICY&nbsp;
                  </span>
                </strong>
              </li>
            </ol>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <ol style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  “effective date” at the top of this Privacy Policy.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </span>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <div
              style={{
                margin: "0in",
                fontSize: "16px",
              }}
            >
              <ol
                style={{
                  marginBottom: "0in",
                  listStyleType: "undefined",
                  marginLeft: "-0.25in",
                }}
              >
                <li
                  style={{
                    margin: "0in",
                    fontSize: "16px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      &nbsp;CONTACT US&nbsp;
                    </span>
                  </strong>
                </li>
              </ol>
            </div>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "17.85pt",
                fontSize: "16px",

                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <strong>
                <u>
                  <span style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </strong>
            </p>
            <ol style={{ listStyleType: "circle" }}>
              <li>
                <span style={{ fontSize: "15px", color: "black" }}>
                  If you have any questions about this Privacy Policy, please
                  contact us:&nbsp;
                </span>
                <ol style={{ listStyleType: "circle" }}>
                  <li>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      By email:{" "}
                      <span style={{ background: "white" }}>
                        alejandro@systemagency.com
                      </span>
                      .
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                marginLeft: "17.85pt",
                textAlign: "justify",
                textIndent: "3.0pt",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              <span style={{ fontSize: "15px", color: "black" }}>&nbsp;</span>
            </p>
            <p
              style={{
                margin: "0in",
                fontSize: "16px",

                textAlign: "justify",
              }}
            >
              &nbsp;
            </p>
          </div>
          <div className="footer-zone" style={{}}>
            <OfficeButtons />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
