import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import LandingVideo from './landingVideo';
import TimeLine from './timeline';
import Carousel from './carousel';
import Config from '../../config';
import axios from 'axios';

export default function FeaturePopup({
  item,
  addItem,
  itemIndex,
  setAddItem,
  setItem,
}) {
  const [selectedMediaType, setMediaType] = useState('');
  const [title, setTitle] = useState('');

  const handleMediaTypeChange = (e) => {
    if (e.target.value === 'landing') {
      setTitle('LANDING PAGE VIDEO');
      setMediaType('landing');
    } else if (e.target.value === 'carousel') {
      setTitle('NEW CAROUSEL');
      setMediaType('carousel');
    } else if (e.target.value === 'landscape') {
      setTitle('LANDSCAPE MEDIA');
      setMediaType('landscape');
    } else if (e.target.value === 'portait') {
      setTitle('PORTRAIT MEDIA');
      setMediaType('portait');
    } else {
      setTitle('');
    }
    setMediaType(e.target.value);
  };

  // Delete home
  const deleteHome = (id) => {
    let token = localStorage.getItem('token');
    let data = {
      id_home: id,
    };
    axios
      .delete(`${Config.URL}home`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      })
      .then(() => {
        toast.success('Deleted Successfully!');
        window.location.reload(true);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  const _closeModal = () => {
    setItem(null);
    setAddItem(false);
    setMediaType('');
  };

  return (
    <div
      id="upload-feature"
      className="modal fade"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <form action="" style={{ textAlign: 'left' }}>
              {item === null && (
                <select
                  name="mediaType"
                  id="mediaType"
                  className="w-full uppercase bg-transparent border-opacity-25"
                  style={{
                    border: '1px solid rgba(167, 167, 167, 0.63)',
                    fontSize: 12,
                    color: '#a7a7a7',
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                  value={selectedMediaType}
                  onChange={handleMediaTypeChange}
                >
                  <option
                    value="..."
                    className="uppercase"
                    style={{ fontSize: 12, color: '#a7a7a7' }}
                  >
                    Select Media Type*
                  </option>
                  <option
                    value="carousel"
                    style={{ fontSize: 12, color: '#a7a7a7' }}
                  >
                    NEW CAROUSEL
                  </option>
                  <option
                    value="landing"
                    style={{ fontSize: 12, color: '#a7a7a7' }}
                  >
                    LANDING PAGE VIDEO
                  </option>
                  <option
                    value="landscape"
                    style={{ fontSize: 12, color: '#a7a7a7' }}
                  >
                    LANDSCAPE MEDIA
                  </option>
                  <option
                    value="portait"
                    style={{ fontSize: 12, color: '#a7a7a7' }}
                  >
                    PORTRAIT MEDIA
                  </option>
                </select>
              )}
              <h2 style={{ textTransform: 'uppercase', textAlign: 'center' }}>
                {item ? item?.sub_component : title}
              </h2>

              {/* <LandingVideo /> */}
              {(selectedMediaType === 'landing' ||
                item?.sub_component === 'landing_video') && <LandingVideo />}
              {(selectedMediaType === 'carousel' ||
                item?.sub_component === 'carousel') && (
                <Carousel
                  home={item}
                  addItem={addItem}
                  closeModal={_closeModal}
                  deleteHome={deleteHome}
                  itemIndex={itemIndex}
                />
              )}
              {(selectedMediaType === 'portait' ||
                item?.sub_component === 'portait') && (
                <TimeLine
                  type={selectedMediaType}
                  home={item}
                  deleteHome={deleteHome}
                  selectedMedia={setMediaType}
                />
              )}
              {(selectedMediaType === 'landscape' ||
                item?.sub_component === 'landscape') && (
                <TimeLine
                  type={selectedMediaType}
                  home={item}
                  deleteHome={deleteHome}
                  selectedMedia={setMediaType}
                />
              )}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
