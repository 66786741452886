import React, { useState, useEffect } from "react";
import { FaBullseye, FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import ReactS3Client from "react-aws-s3-typescript";
import reactImageSize from "react-image-size";
import "../../css/Timeline.css"

export default function Carousel({ work, deleteTalent }) {
  const [files, setFiles] = useState(null);
  const [creditsList, setCreditsList] = useState([{ title: "", text: "" }]);
  const [uploading, setUploading] = useState(false);
  const [talentName, setTalentName] = useState("");
  const [clientName, setClientName] = useState("");
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [custom, setCustom] = useState([
    {
      value:false
    }
  ]);

  useEffect(() => {
    if (work !== null) {
      setTalentName(work?.talentName);
      setClientName(work?.clientName);
      setCreditsList(work?.credits);
      let cust=[]
      work?.credits?.map(cred=>{
         cust.push({
          value:true
         })
      })
      setCustom(cust)
    }
  }, [work]);
// for custom conditional rendering
const [test,settest]=useState(0)
const handleCustom=(e,name,index)=>{
  e.preventDefault()
   if(name==="custom")
   {
    let cus=custom;
    cus[index]["value"]=true
    setCustom(cus)
    settest(test+1)
   }
   else{
    let cus=custom;
    cus[index]["value"]=false
    setCustom(cus)
    settest(test+1)
   }
}
// handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    var list = [...creditsList];
    list[index][name] = value;
    setCreditsList(list);
  };

  // handle input Add
  const handleInputAdd = () => {
    if (creditsList.length < 10) {
      setCreditsList([...creditsList, { title: "", text: "" }]);
      setCustom([...custom,{value:false}])
    }
  };

  // handle Input Remove
  const handleInputRemove = (index) => {
    creditsList.splice(index, 1);
    setCreditsList([...creditsList]);
    custom.splice(index,1);
    setCustom([...custom])
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    if (file.size > 7340032) {
      toast.error("Max File Size 7MB Allowed!");
    } else {
      setFiles(file);
    }
  };

  const uploadFiles = async () => {
    let file = files;
    let type = file.type;
    let name = file.name;
    let filename =
      name.replace(/\.[^/.]+$/, "") +
      "-" +
      Math.floor(Math.random() * 10000000 + 1);
    let imgheight = "";
    let imgwidth = "";
    try {
      const storageRef = ref(storage, filename);
      let resp = [];
      await uploadBytes(storageRef, file).then((snapshot) => {});
      await getDownloadURL(storageRef).then((url) => {
        resp.push(url);
      });
      const { width, height } = await reactImageSize(resp[0]);
      imgheight = height;
      imgwidth = width;
      let item = {
        route: resp[0],
        type: type,
        height: imgheight,
        width: imgwidth,
      };
      uploadTalent(item);
    } catch (exception) {
      setUploading(false);
      toast.error(exception);
    }
  };

  const uploadTalent = (filesUrl) => {
    let token = localStorage.getItem("token");
    let data = {
      talent_name: talentName,
      client_name: clientName,
      credits: creditsList,
      resources: filesUrl,
      component: "carousel",
    };

    axios
      .post(`${config.URL}work`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast.success("Uploaded Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        // toast.success(error);
        setUploading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  // Update talent
  const updateTalent = (e) => {
    e.preventDefault();
    setUploading(true);
    let token = localStorage.getItem("token");
    let data = {
      talent_name: talentName,
      client_name: clientName,
      credits: creditsList,
      id_work: work?.id,
    };
    axios
      .post(`${config.URL}work/update`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast.success("Updated Successfully!");
        window.location.reload(true);
      })
      .catch((error) => {
        // toast.success(error);
        setUploading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files && creditsList.length && talentName && clientName) {
      setUploading(true);
      uploadFiles();
      // uploadTalent();
    } else {
      setUploading(false);
      toast.error("All Fields Required!");
    }
  };

  return (
    <div>
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 50,
        }}
        value={talentName}
        type="text"
        name="talentName"
        placeholder="Talent Name*"
        onChange={(e) => setTalentName(e.target.value)}
      />
      <input
        className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
        style={{
          fontSize: 11,
          borderColor: "rgba(167, 167, 167, 0.63)",
          marginTop: 50,
        }}
        value={clientName}
        type="text"
        name="clientName"
        placeholder="Client Name*"
        onChange={(e) => setClientName(e.target.value)}
      />
      <label style={{ marginTop: 10, marginLeft: 5, color: "#a7a7a7" }}>
        CREDITS*
      </label>
       {creditsList.map((item, index) => (
        <>
          <div style={{display:"flex", justifyContent:"center", alignItems:"centre",columnGap:"20px",padding:"10px"}}>
              <span onClick={(e)=>handleCustom(e,"custom",index)} className={custom[index]["value"]===false?"custoo":"custo"}>Custom</span>
              <span onClick={(e)=>handleCustom(e,"pre",index)} className={custom[index]["value"]===false?"pree":"pre"}>Predefined</span>
      </div>
        <div className="row">
        {custom[index]["value"]===false ? <div className="col-sm-4">
            <select style={{width:"130px", height:"30px", border:"none", marginTop:"15px"}} name="title" onChange={(event) => handleInputChange(event, index)}
             value={item.title}
            >
              <option value="photographer">Photographer</option>
              <option value="styling">Styling</option>
              <option value="makeup">Makeup</option>
              <option value="hair">Hair</option>
              <option value="castingdir">Casting Dir</option>
              <option value="creativedir">Creative Dir</option>
              <option value="editor">Editor</option>
              <option value="videography">Videography</option>
              <option value="setdesign">Set Design</option>
              <option value="production">Production</option>
              <option value="creativeasst">Creative Asst</option>
              <option value="makeupasst">Makeup Asst</option>
              <option value="hairasst">Hair Asst</option>
              <option value="photoasst">Photo Asst</option>
              <option value="stylingasst">Styling Asst</option>
              <option value="artdir">Art Dir</option>
              <option value="lightingasst">Lighting Asst</option>
              <option value="digitalasst">Digital Asst</option>
              <option value="nails">Nails</option>
              <option value="direction">Direction</option>
              <option value="postprod">Post Production</option>
              <option value="retouch">Retouch</option>
            </select>
          </div>:<div className="col-sm-4">
             <input
              className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
              style={{
                fontSize: 11,
                borderColor: "rgba(167, 167, 167, 0.63)",
                marginTop: 10,
              }}
              type="text"
              name="title"
              placeholder="Photographer"
              onChange={(event) => handleInputChange(event, index)}
              value={item.title}
            />
            </div>}
          <div className="col-sm-6">
            <input
              className="input-login focus:outline-none border-b border-gray-700 py-2 w-full mt-3 mb-2 placeholder-gray-700 pl-4 helvetica-neue-thin"
              style={{
                fontSize: 11,
                borderColor: "rgba(167, 167, 167, 0.63)",
                marginTop: 10,
              }}
              type="text"
              name="text"
              placeholder="Zoë Ghertner"
              onChange={(event) => handleInputChange(event, index)}
              value={item.text}
            />
          </div>
          <div
            className="col-sm-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {creditsList.length !== 1 && (
              <div
                style={{
                  fontSize: 17,
                  marginTop: 12,
                  color: "#a7a7a7",
                  cursor: "pointer",
                  display: "inline-block",
                }}
                onClick={() => handleInputRemove(index)}
              >
                <FaTrash />
              </div>
            )}
            {creditsList.length !== 10 && creditsList.length - 1 === index && (
              <div
                style={{
                  fontSize: 17,
                  marginTop: 12,
                  color: "#a7a7a7",
                  cursor: "pointer",
                  display: "inline-block",
                }}
                onClick={handleInputAdd}
              >
                <FaPlus />
              </div>
            )}
          </div>
        </div>
        </>
      ))}
      {work === null && (
        <div className="row" style={{ padding: "0 15px" }}>
          <label
            style={{
              marginTop: 10,
              marginLeft: 5,
              color: "#a7a7a7",
            }}
          >
            IMAGES*
          </label>
          <div
            style={{
              padding: "4px 2px",
              background: "#eaecef",
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full placeholder-gray-700 helvetica-neue-thin"
              style={{
                margin: 0,
                padding: 0,
                fontSize: 12,
                textTransform: "lowercase",
                width: "100%",
              }}
            />
          </div>
          <div
            style={{
              textAlign: "right",
              color: "#a7a7a7",
              fontSize: 12,
              marginTop: 5,
              fontStyle: "italic",
            }}
          >
            Max File Size Limit 7MB*
          </div>
        </div>
      )}
      <button
        // href='#submit-confirm'
        // data-dismiss='modal'
        // data-toggle='modal'
        disabled={uploading}
        onClick={work === null ? handleSubmit : updateTalent}
        style={{ fontSize: "1em", height: 40 }}
      >
        {uploading
          ? `Work Uploading Please wait.... ${uploadingProgress}%`
          : "Submit"}
      </button>
      {work !== null && (
        <button
          // data-dismiss="modal"
          onClick={(e) => {
            e.preventDefault();
            deleteTalent(work?.id);
          }}
          style={{ fontSize: "1em", height: 40 }}
        >
          Delete
        </button>
      )}
      <button data-dismiss="modal" style={{ fontSize: "1em", height: 40 }}>
        Close
      </button>
    </div>
  );
}
