import React from 'react'
import { useState } from 'react'
import Navigation from '../components/Navigation'
import "../css/office.scss"
import axios from "axios";
import config from '../config';
import { toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

function AddOffice() {
 const [office,setoffice]=useState({
    title:"",
    description:""
 })
 const [id,setid]=useState()
 const {slug}=useParams();
 console.log(slug)
 const [isActive, setIsActive] = useState(false);
 const token = localStorage.getItem('token');

 useEffect(()=>{
        if(slug)
        {
            const endPoint="office"
            axios.get(`${config.URL}${endPoint}`).then(res=>{
                     let filtered=res?.data?.item?.rows.filter(x=>{
                        return x.id_office===slug
                     })
                    setoffice({
                        title:filtered?.[0]?.title,
                        description:filtered?.[0]?.description
                    })
                    setid(filtered?.[0]?.id_office)
            }).catch(err=>{
             if (err?.response?.data?.message) {
               toast.error(err?.response?.data?.message);
               return;
             }
             toast.error(err.response);
            })
        }
 },[slug])

 const handleCLick=()=>{
    setIsActive(true);
    const data={
        title:office?.title,
        description:office?.description,
        id_office:id
    }
    console.log(data)
    const headers = {
        Authorization: `Bearer ${token}`,
      };
      data['token'] = token;
    const endPoint="office"
    if(slug)
    {
        axios.put(`${config.URL}${endPoint}`,data,{
            headers
         }).then(res=>{
            setIsActive(false);
            toast.success(res.message);
            window.location = '/office';
         }).catch(err=>{
            if (err?.response?.data?.message) {
                setIsActive(false);
                toast.error(err?.response?.data?.message);
                return;
              }
              setIsActive(false);
              toast.error(err.response);
         })
    }
    else{
        axios.post(`${config.URL}${endPoint}`,office,{
            headers
         }).then(res=>{
            setIsActive(false);
            toast.success(res.message);
            window.location = '/office';
         }).catch(err=>{
            if (err?.response?.data?.message) {
                setIsActive(false);
                toast.error(err?.response?.data?.message);
                return;
              }
              setIsActive(false);
              toast.error(err.response);
         })
    }
 }
  return (
    <LoadingOverlay
        active={isActive}
        spinner
      >
    <div>
        <Navigation/>
        <div className='addMain'>
            <div>
                <h1>{slug?"Edit":"Add"} Office</h1>
                <div className='input__box'>
                    <label>Title</label>
                    <input value={office?.title} onChange={e=>setoffice({...office,title:e.target.value})}></input>
                </div>
                <div className='input__box'>
                    <label>Email</label>
                    <input value={office?.description} onChange={e=>setoffice({...office,description:e.target.value})}></input>
                </div>
                <div className='offcieSubmit'>
                    <button onClick={handleCLick}>Submit</button>
                </div>
            </div>
        </div>
    </div>
    </LoadingOverlay>
  )
}

export default AddOffice